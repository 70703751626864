import 'react-app-polyfill/stable';
import React from "react"
import ReactDOM from "react-dom"
import App from './App';
import { detectPlatform } from './helperfunctions/common';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

elementScrollIntoViewPolyfill();
const app = document.getElementById('root')

ReactDOM.render(<App/>, app);

//Initialize function
var init = function () {
    // TODO:: Do your initialization job
    // console.log('init() called');

    document.addEventListener('visibilitychange', function() {
        if(document.hidden){
            // Something you want to do when hide or exit.
        } else {
            // Something you want to do when resume.
        }
    });
 
    // add eventListener for keydown
    // document.addEventListener('keydown', function(e) {
    //     if (document.getElementById("nav-bar")) {
    //         document.getElementById("nav-bar").style.top = "0";
    //         document.getElementById("nav-bar").style.transitionDuration = ".4s" ;
    //     }

    // 	switch(e.keyCode){
    //         case 37: //LEFT arrow
    //             // alert("Left pressed");
    //             SpatialNavigation.move('left');
    //             break;
    //         case 38: //UP arrow
    //             // alert("Up pressed");
    //             SpatialNavigation.move('up');
    //             break;
    //         case 39: //RIGHT arrow
    //             // alert("Right pressed");
    //             SpatialNavigation.move('right');
    //             break;
    //         case 40: //DOWN arrow
    //             SpatialNavigation.move('down');
    //             // alert("Down pressed");
    //             break;
    //         case 13: //OK button
    //             // alert("OK pressed");
    //             console.log('Aakash event',document.activeElement.click());
    //             e.preventDefault();
    //             break;
    //         case 13: //RETURN button
    //             // tizen.application.getCurrentApplication().exit();
    //             // alert("Return pressed");
    //             break;
    //         default:
    //             console.log('Key code : ' + e.keyCode);
    //             break;
    // 	}
    // });
};
// window.onload can work without <body onload="">

function inputEvent(e) {
    // console.log("From Index.js key event",e.keyCode);
    if(e.keyCode == '13') {
        window.last_focus = e.srcElement;
        // console.log("got main event ",typeof e.keyCode,e.keyCode)
        document.activeElement.click();
    }
    // e.preventDefault();
}

window.onload = ()  => {
    document.addEventListener('keydown', inputEvent);
};

window.onunload = () => {
    document.removeEventListener('keydown', inputEvent);
}