import React from 'react';
import ReactDOM from 'react-dom'
import FacebookLogin from 'react-facebook-login'
import ErrorDisplay from "./ErrorDisplay";
import WebsiteLogin from './WebsiteLogin';
import { connect } from "react-redux"
import { fbUserActions, generateWebsiteLoginToken } from "../actions/userActions"
import { withRouter } from "react-router-dom";
import configuration from '../constants/config';
import * as analytics  from '../constants/analytics';
import Loader from '../components/Loader';
import { AUTH_ON_SCREEN, AUTH_WEBSITE, PLATFORM_HTML_SIMULATOR } from '../constants/common';
import { WEBSITE_LOGIN } from '../constants/api';
import TvFocusable from '../components/TvFocusable';
import { checkCorrectPlatform, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { MoveFocusInside } from 'react-focus-lock';
import { getGdprParamsForApi } from '../helperfunctions/feedHelper';
import { checkIfSingleOption } from "../helperfunctions/common"
import FbDeviceLogin from './FbDeviceLogin';


const FbIconImage = () => <img src={require('../images/icons/fb-white.png')} className="fb-icon"/>

class LoginScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: false,
            errorHeading: '', 
            errorMsg: '',
            showWebsiteLogin: false
        }
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    componentDidMount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.add('overflow-scroll-auto');
        }
        if (this.props.openWebsiteLogin && this.props.userConcent) {
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds))
        }

        if(!this.props.logged_in)
            analytics.setPageViewFixedSections('Registration Screen');

        if(this.props.openFacebookTab && this.props.userConcent && !isSmartTv()) {
            setTimeout(() => {
                // open facebook fage
                if(document.querySelector('.logInScreen .btn-fbIcon')) {
                    document.querySelector('.logInScreen .btn-fbIcon').click();
                }
            },1000)
        }

        //Directly do OAuth if direct login is true and No other login type is present, instead of Oauth
        //Not to do OAuth if Api is already get called.
        if (!(this.props.openWebsiteLogin && this.props.userConcent) && checkIfSingleOption(this.getScreenType(), this.props.feeds) && this.props.directLogin && !this.props.logged_in) {
            this.onWebsiteAuthClick();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user_error && (this.props.user_error || (this.props.user.error && !this.props.logged_in))) {
            this.setState({error: true, errorHeading: "Request error", errorMsg: this.props.user.error})
        }
        if (prevProps.getWebsiteLoginLoading && !this.props.getWebsiteLoginLoading && this.props.websiteLoginData && this.props.websiteLoginData.success) {
            this.setState({showWebsiteLogin: true})
        }
        if (!prevProps.openWebsiteLogin && this.props.openWebsiteLogin && this.props.userConcent) {
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds))
        }
    }

    componentWillUnmount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-scroll-auto');
        }
    }

    dismissAction = (e) => {
        if(e){
          e.preventDefault();
        }
        this.setState({error: false})
    }
    responseFacebook = (response) =>{
        if (response.accessToken && !(Object.keys(response).length === 0 && response.constructor === Object && !this.props.logged_in)){
            const gdprParams = getGdprParamsForApi(this.props.feeds);
            this.props.fbUserActions(response.email, response.accessToken, response.name, this.props.app_id, gdprParams)
        }
    }
    handleSubscriptionRestore(event) {
        event.preventDefault();
        if (!configuration.is_simulator || !checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))
            this.props.onClick(event);
    }

    handleFbSelect = (e) => {
        e.preventDefault();
        this.props.onAPIDataReady(e)
    }

    renderLogin() {
        if (isSmartTv()) {
            return <FbDeviceLogin 
                onGdprClick={this.handleFbSelect}
                isUserConcent={this.props.userConcent}
                callback={this.responseFacebook}
                isGdpr={this.props.feeds.gdpr}
                openFbTab={this.props.openFacebookTab}
                cancelFbLogin={this.props.cancelFbLogin}
            />
        }
        if(this.props.openFacebookTab || this.props.userConcent) {
            return (
                <FacebookLogin
                    appId={configuration.fb_app_id}
                    fields="name,email"
                    callback={this.responseFacebook}
                    cssClass="btn-fbIcon"
                    textButton="Log In with Facebook"
                    icon={<FbIconImage/>}
                    disableMobileRedirect={true} // to work in mobile devices
                />
            )
        } else {
            return <button className="btn-fbIcon" onClick={(e) => this.handleFbSelect(e)}><FbIconImage/> Log In with Facebook</button>
        }
    }

    onWebsiteAuthClick = (e) => {
        e && e.preventDefault();
        if (this.props.feeds.gdpr)
            this.props.onAPIDataReady(null, true)
        else
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds));
    }

    clickEnter = (e) => {
        // console.log('Click enter',e);
        // document.activeElement.click();
        e.srcElement.childNodes[0].click();    
    }
    
    /**
     * Function to get login screen type. (firstSave or registrationWall)
     */
    getScreenType = () => {
        let screen = "firstSave"
        if (this.props.screen === "registerWall"){
          screen = "registrationWall"
        }
        return screen;
    }

    stopWebsiteLoading = () => {
        this.setState({
            showWebsiteLogin: false
        })
    }

    render() {
        const screen = this.getScreenType();
        const {subscriberHeader, restore} = this.props.feeds.loginUIMetadata[screen].strings;
        const {bgButton, buttonLabel} = this.props.feeds.loginUIMetadata.colors;
        if((document.getElementsByClassName("user-access")[0] !== undefined) && (document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0] !== undefined))
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "block";
        if(this.state.error){
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        }
        if (this.props.fbLoginLoading || this.props.getWebsiteLoginLoading || this.props.logged_in) {
            return (<Loader />)
        }
        if (this.state.showWebsiteLogin && this.props.websiteLoginData && this.props.websiteLoginData.success) {
            return (<WebsiteLogin 
                data={this.props.websiteLoginData}
                feeds={this.props.feeds}
                stopLoading={this.stopWebsiteLoading}
            />)
        }

        let margin_tv = null;
        if(isSmartTv()) {
            // margin_tv = 0;
        }

        return (
            <div className="logInScreen">
                <p style={{color: this.props.feeds.loginUIMetadata.colors.headerText || "#FFD626FF"}}>
                {this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}
                </p>
                {this.props.feeds.loginUIMetadata[screen].authType === AUTH_ON_SCREEN && this.props.feeds.loginUIMetadata[screen].em &&
                    <a href="#" onClick={this.props.onClick} className="btn-email continue-btn focusable" tabIndex={getTabIndex()} style={{color: this.props.feeds.loginUIMetadata.colors.buttonLabel, backgroundColor: this.props.feeds.loginUIMetadata.colors.bgButton}}>
                        <span className="mail-icon"></span> {this.props.feeds.loginUIMetadata[screen].strings.emailButton}</a>
                }
                <br/>
                {this.props.feeds.loginUIMetadata[screen].authType === AUTH_WEBSITE &&
                    // <TvFocusable 
                    //     classList = {['block-style']}
                    //     clickEnter = { this.clickEnter }
                    // >
                        <a id="web_login_id" href="#" onClick={this.onWebsiteAuthClick} className="btn-email continue-btn focusable" tabIndex={getTabIndex()} style={{color: this.props.feeds.loginUIMetadata.colors.buttonLabel, backgroundColor: this.props.feeds.loginUIMetadata.colors.bgButton,margin: margin_tv}}>
                            <span className="website-icon"></span> {this.props.feeds.loginUIMetadata[screen].strings.websiteButton}</a>
                    // </TvFocusable>
                }
                <br/>
                {/* ---------facebook register/login------ */}
                {configuration.fb_app_id && this.props.feeds.loginUIMetadata[screen].fb && 
                    (this.renderLogin())
                }
                {configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]) && subscriberHeader && 
                    (<div className="subscriberHeader">
                        <p>{subscriberHeader}</p>
                            <a className="focusable" tabIndex={getTabIndex()} onClick={(e) => this.handleSubscriptionRestore(e)} style={{color: buttonLabel, backgroundColor: bgButton}} >{restore}</a>
                    </div>)
                }
            </div>
            )
    }

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    logged_in: state.user.logged_in,
    user_error: state.user.error,
    fbLoginLoading: state.user.fbLoginLoading,
    getWebsiteLoginLoading: state.user.getWebsiteLoginLoading,
    websiteLoginData: state.user.websiteLoginData
})

const mapDispatchToProps = {
    fbUserActions: fbUserActions,
    generateWebsiteLoginToken
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
