import Cookies from 'universal-cookie';
import { AUTH_WEBSITE, DEFAULT_LOGIN_UI_METADATA, getSaveFeedSectionIdentifier, PLATFORM_LG, PLATFORM_SAMSUNG, VIDEO_ERROR, PLATFORM_WEB } from '../constants/common';
import store from './../store.js';
import config from '../constants/config';
import { checkIfSectionLevelContent } from './feedHelper';

/**
 * Function to get new url of image, in which you can pass size and that size url been generated
 * @param {*} url 
 * @param {*} size - It can be any one of these {300, 384, 600, 768, 900, 1152, 1536, 2048, 2250}
 */
export function getImageUrlBaseOnSize(url, size){
    var newUrl = url.split(".")
    newUrl[newUrl.length - 2] = newUrl[newUrl.length - 2] + `-${size}`
    newUrl = newUrl.join(".")
    return newUrl
}

export function checkIsLoginFromCookies() {
    const cookies = new Cookies();
    let user_cookie;

    try {
        if(checkCorrectPlatform([PLATFORM_LG])) {
            user_cookie = localStorage.getItem('AppUser');
            if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
        } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            user_cookie = window.tizen.keymanager.getData({ 'name': 'AppUser' });
            if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
        } else {
            user_cookie = cookies.get('AppUser');
        }
    } catch(err) {
        return false;
    }

    if(user_cookie)
        return true;
    return false;
}

/**
 * Function to check when click on deepLink, do we need to, do some action or not.
 * @param {*} actionUrl deeplink url
 * @param {*} feeds 
 */
export function checkIfToDoNothing(actionUrl, feeds) {
    const isLogin = checkIsLoginFromCookies();
    if (actionUrl.includes('/existingsub/'))
        return true
    //In case of save, do nothing, if sections doesn't have save section
    if (actionUrl.includes("save") && !getSaveFeedSectionIdentifier(feeds))
        return true;
    //If user is logged In, do nothing on login deeplink click
    if (isLogin && actionUrl.includes("/register/")) 
        return true;
    return false;
}

export function detectPlatform() {
    const agent = window.navigator.userAgent;
    let platform = '';

    if(agent.includes('Tizen') || agent.includes('Samsung')) {
        platform = 'Samsung';
    } else if(agent.includes('Web0S')) {
        platform = 'LG';
    } else if(agent.includes('AFTT') || agent.includes('AFTM')) {
        platform = 'Amazon Fire Stick';
    } else if(agent.includes('Philips')) {
        platform = 'Philips';
    } else if(agent.includes('Roku')) {
        platform = 'Roku TV';
    } else if(agent.includes('Apple TV') || agent.includes('AppleTV')) {
        platform = 'Apple TV';
    } else if(agent.includes('Sony')) {
        platform = 'Sony';
    } else if(agent.includes('Macintosh')) {
        platform = 'Macintosh';
    } else {
        platform = 'unknown';
    }

    // console.log(`You are on: ${platform} platform`);
    return platform;
}

export function isSmartTv() {
    // const agent = window.navigator.userAgent;
    // return agent.includes('SmartTV');
    if (checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG]))
        return true;
    return false;
}

/**
 * Function get tabIndex value for elements. For CTV we need to assign that. For CTV its value is "-1".
 * @param {string} value It can be "-1", "0" or +ve values. By default it's value is "0"
 */
export function getTabIndex(value="-1") {
    if (isSmartTv())
        return value;
    return "default"; //Default value for other platforms.
}

export function checkIfSafari() {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;
    return isSafari;
}

export function showVirtualKeyboard() {
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]))
        return true;
    return false;
}

export function checkIfOverlayVideoIsPresent() {
    if (document.getElementById('thumb-video') || document.getElementById('thumb-video_html5_api') || 
    document.getElementById('fake-video_html5_api') || document.getElementById('fake-video')) {
        return true;
    }
    return false;
}

export function checkIfEmptyMazIdFeedsInContents(contents) {
    //We have only three maz id feeds types.
    if (!contents) return false;
    const newState = store.getState();
    const isLoggedIn = newState.user.logged_in;
    const savedItemsCount = newState.items.items.content.length;
    const historyItemsCount = newState.items.history.contents.length;
    const progressItemsCount = newState.items.progress.contents.length;
    const totalCount = savedItemsCount + historyItemsCount + progressItemsCount;
    if (contents.length > 3) return false;
    const allContents = contents.filter(content => {
      if (!isLoggedIn && content.mazIDSectionType && !totalCount) {
        return false;
      }
      return true;
    })
    if (!allContents.length) return true;
    return false;
}

/**
 * This function check if feed contains proper login-ui-metadata, If not then add hardcoded metadata. It happens when MAZ ID is off from admin.
 * @param {*} feeds 
 */
export function checkLoginUIMetaData(feeds) {
    const newFeed = {...feeds};
    const oldLoginMetadata = {
        ...newFeed.loginUIMetadata
    }
    const firstSave = 'firstSave';
    const registerScreen = "registrationWall"
    if (!newFeed.loginUIMetadata[firstSave] || !newFeed.loginUIMetadata[registerScreen]) {
        newFeed.loginUIMetadata = {
            ...DEFAULT_LOGIN_UI_METADATA,
            ...oldLoginMetadata
        }
    }
    return newFeed;
}

/**
     * Function to check if the login page contains only single login type (OAuth only), If yes return true
     */
export function checkIfSingleOption(screen, feeds) {
    // const screen = this.getScreenType();
    const loginUI = feeds.loginUIMetadata[screen];
    const isLoginTypeWebsite = loginUI.authType === AUTH_WEBSITE;
    if (!(config.fb_app_id && loginUI.fb) && isLoginTypeWebsite) {
        return true;
    }
    return false;
}

export function onVideoError(videoType) {
    if (!checkIfSafari() && videoType === "application/x-mpegURL") {
        document.querySelector('.vjs-modal-dialog-content').innerHTML=VIDEO_ERROR.NON_SAFARI_M3U8_MSG;
    } else {
        document.querySelector('.vjs-modal-dialog-content').innerHTML=VIDEO_ERROR.MSG;
    }

}

/**
 * 
 * @param {*} supportedPlatformList List for supported platforms
 */
export function checkCorrectPlatform(supportedPlatformList=[]) {
    const currentPlatform = config.platform;
    if (supportedPlatformList.includes(currentPlatform))
        return true;
    return false;
}

export function toggleNavbarOnModalChanges(modal) {
    if (isSmartTv() && document.getElementById('nav-with-modal-id')) {
        if (Object.keys(modal).length) {
          document.querySelectorAll('#nav-with-modal-id > div').forEach(elm => {
            elm.style.display = 'none';
          })
        } else {
          document.querySelectorAll('#nav-with-modal-id > div').forEach(elm => {
            //If payment page do not change the display property
            if (!elm.classList.contains("payment-page")) {
              elm.style.display = 'block';
            }
          })
        }
        if (checkIfSectionLevelContent()) {
          if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'block';
        }
    }
}

/**
 * Function will append virtual option to normal swiper params
 * @param {*} params 
 * @param {*} slides 
 * @param {*} onRenderExternal 
 * @param {Object} virtualOptions 
 * @param {Number} virtualOptions.addSlidesBefore
 * @param {Number} virtualOptions.addSlidesAfter
 */
export function appendVirtualSwiperParams(params, slides, onRenderExternal, virtualOptions={}) {
    const newVirtualOptions = {
        addSlidesBefore: virtualOptions.addSlidesBefore || 10,
        addSlidesAfter: virtualOptions.addSlidesAfter || 10
    }
    return {
        ...params,
        virtual: {
            slides: slides,
            renderExternal(data) {
                onRenderExternal(data)
            },
            renderExternalUpdate: true,
            ...newVirtualOptions
        }
    }
}

/**
 * Function to add style left to all items inside swiper, this is must for Virtual slides.
 */
export function setLeftStyleToSlideItems(contentIdentifier, virtualOffset) {
    const parent = document.querySelector(`.content-${contentIdentifier}`)
    if (parent) {
        parent.querySelectorAll('.swiper-slide.detail-swiper').forEach(ele => {
            ele.style.left = `${virtualOffset}px`
        })
    }
}

/*
 *  Function returns the current platform string
 */
export function getCurrentPlatform() {
    if(checkCorrectPlatform([PLATFORM_WEB])) {
        return 'web_as_output';
    } else if(checkCorrectPlatform(PLATFORM_SAMSUNG)) {
        return 'samsung';
    } else if(checkCorrectPlatform(PLATFORM_LG)) {
        return 'lg';
    }
    return null;
}


export function getMacros(props, videoItem, player) {
    let macros = {
        cb: new Date().getTime(), // random number to prevent caching
        ua: window.navigator.userAgent || null, // user agent
        content_title: videoItem.title || '',
        coppa: 0,
        w: player.currentWidth() || null, // player width
        h: player.currentHeight() || null, // player height
        dur: videoItem.duration || null, // video duration
        ap: 1, // player set to autoplay or not
        placement: 1, // placement of video
        mid: videoItem.identifier || null, // media id,
        cid: videoItem.identifier || null, // content id,
        vid: videoItem.identifier || null, // video id from item feed
        content_id: videoItem.identifier || null,
        v_url: videoItem.contentUrl || null, // encoded video url
        vt: encodeURI(videoItem.title || null), // encoded video title
        content_title: encodeURI(videoItem.title || null),// encoded content item title
        gdpr: (props.user_concent) ? 1 : 0,
        // lat: (props.user_coords) ? props.user_coords.latitude : null,
        // lon: (props.user_coords) ? props.user_coords.longitude : null,
        app_name: config.title || null,
        desc: videoItem.summary || null, // video description
        app_store_url: null, // app store url in ctv case
        brand_name: null, // samsung or lg
        device_make: null, // samsung or lg
        device_model: null,
        ip: null,
        did: null,
        app_bundle: null,
        us_privacy: null
    };

    macros.ip = props.user_ip || null;

    if(props.feeds.ccpa) {
        macros.us_privacy = '1N--';
    } else {
        macros.us_privacy = '1---';
    }

    if(checkCorrectPlatform([PLATFORM_WEB])) {
        macros.did = null; // since web has not device id, due to security reasons
        macros.device_make = "Web";
        macros.app_bundle = config.app_id || null;
        macros.app_store_url = `${window.location.origin}/apps/${config.app_id}`;
    } else if(checkCorrectPlatform(PLATFORM_SAMSUNG) && window.tizen) {
        macros.brand_name = "Samsung";
        macros.device_make = "Samsung";
        try {
            macros.device_model = props.device_model;
            macros.did = props.device_uuid;
            macros.app_bundle = window.tizen.application.getCurrentApplication().appInfo.id;
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    } else if(checkCorrectPlatform(PLATFORM_LG) && window.webOS) {
        macros.brand_name = "LG";
        macros.device_make = "LG";
        try {
            macros.device_model = props.device_model;
            macros.did = props.device_uuid;
            macros.app_bundle = window.webOS.fetchAppId();
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    }
    return macros;
}

export function focusVideoItemWhichWerePlayed(contentIdentifier, progressItem) {
    let contentDiv = document.getElementsByClassName("content-" + contentIdentifier)[0];
    let focusItem = document.getElementsByClassName("item-" + progressItem)[0]
    if(contentDiv.getElementsByClassName("swiper-slide slideHover")[0]) {
        contentDiv.getElementsByClassName("swiper-slide slideHover")[0].classList.remove("slideHover")
    }
    focusItem && focusItem.classList.add("slideHover");
}