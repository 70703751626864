import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getSearchResult, clearSearch } from '../actions/searchActions'
import Background from '../components/Background'
import ThumbnailSearch from '../components/ThumbnailSearch'
import { environment } from "../constants/initializer"
import ReactGA from 'react-ga';
import configuration from '../constants/config';
import * as analytics from '../constants/analytics';
import Keyboard from 'react-simple-keyboard';
import { getTabIndex, showVirtualKeyboard } from '../helperfunctions/common';

const SearchScreen = (props) =>
  <div className="search-box">
    <input className="focusable" tabIndex={getTabIndex()} type="text" onChange={props.update} id="search" value={props.search_value} placeholder="Search..." />
  </div>

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null
    this.state = { displayUserLogin: false, search_value: "", input_focus: '', layoutName: "default" }
    this.update = this.update.bind(this)
    if (this.props.isSearchCleared) {
      this.clearSearch();
    }
  }

  componentWillMount() {
    if(this.props.runningOnMobile) {
      document.body.className = "video-grid"
    } else {
      document.body.className = "grid"
    }
    this.setState({search_value: this.props.search_string || ""})
  }

  componentDidMount() {
    if (window.location.pathname.includes('search/')) {
      this.props.history.replace(`/apps/${configuration.app_id}/search`);
    }
    const navbar = document.getElementsByClassName("nav-view")[0];
    navbar && navbar.parentNode.classList.remove('fix-nav');
    analytics.setPageViewFixedSections('Search')
    var navbarLinks = document.getElementsByClassName("headerLinks")
    for (var i = 0; i < navbarLinks.length; i++) {
      navbarLinks[i].classList.remove("active")
    }
    // document.getElementsByClassName("navbar-search")[0] && document.getElementsByClassName("navbar-search")[0].classList.add("active")

    if(configuration.is_simulator) {
      const navbar = document.getElementsByClassName("nav-view")[0];
      navbar && navbar.parentNode.classList.remove('fix-nav');
      var navbarLinks = document.getElementsByClassName("headerLinks")
      const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
      for(var i=0; i<navbarLinks.length; i++){
        navbarLinks[i].classList.remove("active")
        if(ottTabBarInactiveColor) {
          navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
        }
      }
      if (document.getElementsByClassName("navbar-search")[0]) {
        document.getElementsByClassName("navbar-search")[0].classList.add("active")
        if(ottTabBarActiveColor) {
          document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        }
      }
    }
  }

  componentDidUpdate() {
    if (Object.keys(this.props.modal_progress).length > 0) {
      if (document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0]) {
        window.scrollTo(0, document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0].getBoundingClientRect().top + document.body.scrollTop - window.innerHeight / 2)
      }
      var elem = document.getElementsByClassName("searchContent hovered")[0]
      if (elem) {
        elem.classList.remove("hovered")
      }
      analytics.setPageViewFixedSections('Search')
      if(configuration.is_simulator) {
        const navbar = document.getElementsByClassName("nav-view")[0];
        navbar && navbar.parentNode.classList.remove('fix-nav');
        var navbarLinks = document.getElementsByClassName("headerLinks")
        const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
        for(var i=0; i<navbarLinks.length; i++){
          navbarLinks[i].classList.remove("active")
          if(ottTabBarInactiveColor) {
            navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
          }
        }
        // if (document.getElementsByClassName("navbar-search")[0]) {
        //   document.getElementsByClassName("navbar-search")[0].classList.add("active")
        //   if(ottTabBarActiveColor) {
        //     document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        //   }
        // }
      }
    }
  }

  update(event) {
    //analytics.setEvent('Search', 'Search Query', '')
    var event_target = event.target.value;
    clearTimeout(this.timer);
    if (!event_target) {
      this.clearSearch()
    }
    else {
      this.timer = setTimeout(function () {
        this.props.getSearchResult(this.props.app_id, this.state.search_value)
      }.bind(this), 300)
    }
    this.setState({ search_value: event_target })
  }

  clearSearch = () => {
    this.props.clearSearch()
  }

  keyboardKeyPressed = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      const layoutName = this.state.layoutName;

      this.setState({
        layoutName: layoutName === "default" ? "shift" : "default"
      });
    }
  }

  onKeyboardChange = (input) => {
    // console.log("INPUT", input, this.keyboard.utilities.getCaretPosition());
    // this.keyboard.replaceInput(e.target.value);
    const event_target = input;

    if (!event_target) {
      this.clearSearch()
    }
    else {
      this.timer = setTimeout(function () {
        this.props.getSearchResult(this.props.app_id, this.state.search_value)
      }.bind(this), 300)
    }
    this.setState({ search_value: input });
  }

  renderVirtualKeyboard() {
    if(!showVirtualKeyboard()) return null;

    this.layout = {
      'default': [
        '1 2 3 4 5 6 7 8 9 0',
        'q w e r t y u i o p',
        'a s d f g h j k l -',
        '{shift} z x c v b n m _',
        '.com @ {bksp} {space}'
      ],
      'shift': [
        '~ ! @ # $ % ^ & * ( ) _ +',
        'Q W E R T Y U I O P { } |',
        'A S D F G H J K L : "',
        '{shift} Z X C V B N M < > ?',
        '.com @ {bksp} {space}'
      ]
    };
    
    return (
      <div style={{ width: '50%', margin: '0px auto' }}>
        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layout={this.layout}
          layoutName={this.state.layoutName}
          enableKeyNavigation={true}
          useMouseEvents={true}
          buttonTheme={[
            {
              class: 'hg-black focusable',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            }
          ]}
          buttonAttributes={[
            {
              attribute: 'tabindex',
              value: '-1',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            }
          ]}
          onKeyPress={btn => this.keyboardKeyPressed(btn)}
          onChange={this.onKeyboardChange}
        />
      </div>
    );
  }

  render() {
    let contents = this.props.items.content;
    return (
      <div className="search-section">
          <Background background={this.props.background} />
          {configuration.is_simulator && <SearchScreen update={this.update} search_value={this.state.search_value} />}
          { this.props.isSearchPending &&
            <div id="searchLoader" style={{display: 'block', backgroundColor: 'transparent', zIndex: 100}} className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
            }
          { this.renderVirtualKeyboard() }
          { (this.props.items.content.length > 0 && this.props.search_string.length > 0 )
            ? <div className="search-row">
              {contents.map(function(content, i) {
                return <ThumbnailSearch items={this.props.itemsReducer} locked={content.parentMenu.locked} feeds={this.props.feeds} key={i} content={content} head={1} primary={this.props.feeds.primaryColor} secondary={this.props.feeds.secondaryColor} all_contents={this.props.items.content} />
              }, this)}
            </div>
            : (!this.props.isSearchPending 
              ? <div className="no-search-items"><span>Your search results will appear here.</span></div>
              : null
            )
          }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  items: state.search.search,
  search_string: state.search.search.search_string,
  modal_progress: state.modal.progress,
  isSearchCleared: state.search.isSearchCleared,
  isSearchPending: state.search.isSearchPending
})

const mapDispatchToProps = {
  getSearchResult: getSearchResult,
  clearSearch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
