import React from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import OverlayVideo from "../components/OverlayVideo"
import Subscribe from '../containers/Subscribe'

import OverlayImage from "../components/OverlayImage"
import { closeModal, openModal } from "../actions/modalActions"
import { saveItem } from "../actions/itemActions"
import Cookies from 'universal-cookie';
import ErrorDisplay from "../components/ErrorDisplay"
import FakeLive from "../components/FakeLive"
import LiveSetup from "../components/LiveSetup"
import ContentSectionVideo from "../components/ContentSectionVideo"
import ConfirmPayment from "../components/ConfirmPayment";
import { clearPendingPurchase } from "../actions/subscriptionAction";
import Loader from "../components/Loader";
import { backButtonObj, checkIfSectionLevelContent } from "../helperfunctions"

import configuration from '../constants/config';
import isAvailable from '../helperfunctions/is_available';
import { DEEPLINK, PLATFORM_LG, PLATFORM_SAMSUNG } from '../constants/common';
import { Focusable } from 'react-js-spatial-navigation';
import LoginFlow from '../components/LoginFlow';
import TvFocusable from './TvFocusable';
import { checkLoginUIMetaData, isSmartTv, checkCorrectPlatform, getTabIndex, toggleNavbarOnModalChanges } from '../helperfunctions/common';
import backMobile from "../images/icons/back-mobile.png";
import { parse } from 'query-string';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { checkIfFocusInsideBanner } from '../helperfunctions/ctvHelper';

function FindSection(props, section_id) {
  var feed = props;
  var currentLocation = section_id.split("/")
  var contents = props.sections
  for (var i = 0; i < currentLocation.length; i++) {
    for (var j = 0; j < contents.length; j++) {
      if (contents[j].identifier == currentLocation[i]) {
        feed = contents[j]
        contents = contents[j].contents
        if (!Array.isArray(contents)) {
          break;
        }
      }
    }
  }
  if (feed.contents && (feed.contents[0].type == "fake" || feed.contents[0].type == "live")) {
    feed = feed.contents[0]
  }
  return feed;
}

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.selectEmail = this.selectEmail.bind(this);
    this.selectBack = this.selectBack.bind(this);
    this.dismissAction = this.dismissAction.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.updateEmailSelected = this.updateEmailSelected.bind(this);
    this.state = { 
      emailSelected: false,
      iframe_render: false
    };
  }

  updateIframeInfo = (type, url, open_iframe = true) => {
    // this.iframe_url = url;
    this.setState({ iframe_render: open_iframe },() => {
      if (open_iframe) {
        // document.addEventListener('keyup', this.handleScrollingWithKeyboard);
        if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'none';
      } else {
        // document.removeEventListener('keyup', this.handleScrollingWithKeyboard);
        if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'block';
      }  
    });
  }

  // handleScrollingWithKeyboard = (e) => {
  //   if (!document.getElementById('myframe')) return;

  //   const locales = document.getElementById('myframe').contentWindow;

  //   let _scroll_y = locales.scrollY;

  //   if (e.keyCode == '38') { // up
  //     locales.scrollTo(0, _scroll_y - 20);
  //   } else if (e.keyCode == '40') { // down
  //     locales.scrollTo(0, _scroll_y + 20);
  //   } else if (e.keyCode == '10009' || e.keyCode == '461') {
  //     this.updateIframeInfo("", "", false);
  //   }
  // }
  retainFocusOnBackPress = () => {
    if(window.last_focus) {
      // window.last_focus.focus();
      // JsSpatialNavigation.pause();
      try {
        let r = window.last_focus.getAttribute('data-row');
        let c = window.last_focus.getAttribute('data-col');
        if(!r || !c || !document.querySelectorAll('.swiper-wrapper').length) return;
        JsSpatialNavigation.focus(document.querySelectorAll('.swiper-wrapper')[r].querySelectorAll('.focusable')[c]);
      } catch(err) {
        console.log('error on retain focus',err)
      }
      // JsSpatialNavigation.resume();
    };
  }

  handleRemoteClick = (e) => {
    const _self = this;
    // console.log("click in modal", e.keyCode);

    let locales, _scroll_y;
    if(this.state.iframe_render) {
      locales = document.getElementById('myframe') && document.getElementById('myframe').contentWindow;
      _scroll_y = locales && locales.scrollY;
    }

    if(parseInt(e.keyCode) >= 37 && parseInt(e.keyCode) <= 40) {
      if (!(window.location.pathname.includes('settings') || checkIfFocusInsideBanner())) {
        document.activeElement.scrollIntoViewIfNeeded(false);
        // setTimeout(() => {
        //   document.activeElement.scrollIntoView({behavior: 'smooth',block: 'center'});
        // },25)
      }
    }
  
    if(e.keyCode == '38') {
      locales && locales.scrollTo(0, _scroll_y - 20);
    } else if(e.keyCode == '40') {
      locales && locales.scrollTo(0, _scroll_y + 20);
    } else if (e.keyCode == '10009' || e.keyCode == '461' || e.keyCode == '8') {
      if(_self.state.iframe_render) {
        _self.updateIframeInfo("", "", false);
      } else if (document.getElementById("gdpr_back_button")) {
        document.getElementById("gdpr_back_button").click();
      } else if (document.getElementById("fb_back_button_ctv")) {
        document.getElementById("fb_back_button_ctv").click();
      } else if (document.getElementById("website_back_button_ctv")) {
        document.getElementById("website_back_button_ctv").click();
      } else if (document.getElementById("iframe1_back_button_ctv")) {
        document.getElementById("iframe1_back_button_ctv").click();
      } else if (document.getElementById("overlay_video_back_btn")) {
        // console.log("overlay_video_back_btn clicked");
        if (checkIfSectionLevelContent()) {
          this.openExitAppModal();
        } else {
          document.getElementById("overlay_video_back_btn").click();
          this.retainFocusOnBackPress();
        }
      } else if (document.getElementById('modal_back_btn')) {
        // console.log("modal_back_btn clicked");
        document.getElementById("modal_back_btn").click();
        this.retainFocusOnBackPress();
      } else if(document.getElementById('error_display_btn')) {
        // console.log("error button clicked")
        document.getElementById("error_display_btn").click();
      } else if (document.getElementById("main_back_id")) {
        // console.log("main_back_id clicked");
        document.getElementById("main_back_id").click();
        this.retainFocusOnBackPress();
      } else if (checkIfSectionLevelContent()) {
        if (_self.state.emailSelected || window.emailSelected) {
          _self.setState({ emailSelected: false });
          window.emailSelected = false;
          return;
        }
        if (window.dontOpenExit) {
          window.dontOpenExit=false;
          return; 
        }
        _self.openExitAppModal()
      }
      e.preventDefault();
    }
  }

  openExitAppModal = () => {
    if(this.props.modal.type === 'exit_app') return;
    if (Object.keys(this.props.modal)) {
      this.last_modal_data = this.props.modal;
    } else {
      this.last_modal_data = null;
    }
    this.props.openModal({ type: 'exit_app' });
  }

  exitCtvApp = (_bool) => {
    if (_bool) {
      if (checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.application.getCurrentApplication().exit();
      } else if (checkCorrectPlatform([PLATFORM_LG])) {
        // window.webOS.platformBack();
        window.close();
      }
    } else {
      if (this.last_modal_data) {
        this.props.openModal(this.last_modal_data);
      } else {
        this.props.closeModal();
      }
    }
  }

  componentDidMount() {
    if (isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
    }
  }

  componentWillUnmount() {
    if (isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);
    }
  }

  dismissAction(ev) {
    this.showBodyScroll();
    this.props.closeModal();
    this.goBackToPrevious();
    if (checkIfSectionLevelContent()) {
      // if section level live/simlive
      this.continuePlayingAfterLogin()
    }
    if (ev)
      ev.preventDefault()
  }

  selectEmail(ev) {
    this.setState({ emailSelected: true })
    if (ev)
      ev.preventDefault()
  }

  goBackToPrevious(continuePlaying) {
    if (this.props.modal.goBack === "search") {
      // for search section, need to go back to search instead of feed
      this.props.history.replace(`/apps/${configuration.app_id}/search`);
      document.body.classList.remove("noNav")
    } else if (this.props.modal.goBack) {
      let url = window.location.pathname;
      const backUrlObj = backButtonObj(this.props.feeds, url.split('/').slice(3).join('/'));
      // Go back to parent feed
      this.props.history.replace(backUrlObj.backPath);
      if (continuePlaying && !isSmartTv()) {
        // continue playing video and image
        this.props.history.replace(url);
      }
    }
  }

  selectBack(ev) {
    this.showBodyScroll();
    this.setState({ emailSelected: false })
    this.goBackToPrevious()
    if (ev)
      ev.preventDefault()
  }

  closeLogin(ev) {
    const currEmail = this.state.emailSelected;
    this.showBodyScroll();
    this.setState({ emailSelected: false })
    this.goBackToPrevious()
    if (!isSmartTv() || !currEmail) {
      this.props.closeModal()
    }
    if (ev)
      ev.preventDefault()
  }

  componentDidUpdate(prevProps) {
    const cookies = new Cookies();
    this.checkOverlayAndHideShowBodyScroll();
    // waiting for all 3 api completion- login, subscription, meter
    if (this.props.modal.type === "login" && this.props.logged_in && !this.props.isUserLoading && !this.props.isMeteringLoading) {
      // this.showBodyScroll()
      if(checkCorrectPlatform([PLATFORM_LG])) {
        localStorage.setItem('AppUser',JSON.stringify(this.props.user));
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.saveData('AppUser', JSON.stringify(this.props.user), null, () => {});
      } else {
        cookies.set('AppUser', this.props.user, { path: '/' });
      }
      if (this.props.modal.saved_item) {
        const { saved_item, viewMetering, locked } = this.props.modal;
        const isLocked = locked && !isAvailable(saved_item.parent_id, saved_item.special, saved_item.registerWall);
        if (!isLocked || (isLocked && viewMetering && this.canSaveLockedItem(saved_item.identifier))) {
          this.props.saveItem(this.props.user.auth_token, saved_item.identifier, this.props.user.user_id, saved_item)
        }
      }
      this.setState({ emailSelected: false })
      this.props.closeModal()
      this.continuePlayingAfterLogin()
    }

    // When user is purchasing subscription and loading its subscription details.
    if (prevProps.isUserLoading && !this.props.isUserLoading && this.props.isPurchasePending) {
      if (!this.props.userError) {
        this.props.openModal({ type: "payment" })
      }
      else {
        this.props.clearPendingPurchase();
        this.props.openModal({ type: "subscribe", message: "Unable to load current subscriptions." })
      }
    }
    if (isSmartTv()) {
      this.changeStyleIfModalDataChanges();
    }
  }

  canSaveLockedItem(identifier) {
    let freeViews = (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) ? this.props.feeds.ctaMetadata.metering.freeViews : 0;
    if (freeViews <= 0) return false;
    let rest = freeViews - this.props.metering_views_seen.length;
    if (!this.props.metering_views_seen.includes(identifier) && rest <= 0) {
      return false;
    }
    return true;
  }

  continuePlayingAfterLogin() {
    // Continue fake and live video after login
    let section_id = window.location.pathname.replace("/apps/" + this.props.app_id, "");
    if (section_id !== "") {
      let section = FindSection(this.props.feeds, section_id);
      if (section.type === "fake") {
        this.props.openModal({ type: "fake", feeds: this.props.feeds, section: section })
      } else if (section.type === "live") {
        this.props.openModal({ type: "live", feeds: this.props.feeds, section: section })
      } else if ((section.type === "vid") || (section.type === "image")) {
        // send back 
        if (!checkIfSectionLevelContent()) {
          this.goBackToPrevious(true)
        }
      } else if (section_id.includes("/search")) {
        this.goBackToPrevious()
      }
    }
  }

  checkOverlayAndHideShowBodyScroll = () => {
    if (document.querySelector('body #overlay')) {
      this.hideBodyScroll();
    } else {
      this.showBodyScroll();
    }
  }

  componentWillReceiveProps() {
    window.onpopstate = this.backBottonClick();
  }

  backBottonClick() {
    if (this.props.history.action === "POP" && this.props.modal && this.props.modal.type === "video") {
      //On browser back button close modal
      this.props.closeModal();

      // In case of autoplay next video, window.location.pathname happen to be wrong. Which result in app crash
      // Fix for https://github.com/MagAppZine/MagAppZine-MagControl/issues/7539
      const loc = this.props.history.location;
      if(loc && loc.pathname && window.location.pathname !== loc.pathname) {
        this.props.history.replace(loc.pathname);
      }
    }
  }

  clickEnter = (e) => {
    // console.log('Click enter', e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();
  }

  /**
   * Function for back button
   * @param {*} onClickAction Which function to call when clicked on back button
   */
  backButtonHtml = (onClickAction) => {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }

    return (
    <span className={"icon-hollow_arrow backBtnSpan " + (this.props.runningOnMobile ? "backBtnSpan-mobile" : "")}>
      <a id="modal_back_btn" href="" onClick={(e) => {
          e.preventDefault();
          onClickAction();
        }} 
        className={(this.props.runningOnMobile ? "backBtn-mobile" : "backBtn")}
        style={{zIndex: 100, ...style}}>
          {this.props.runningOnMobile && <img src={backMobile} alt="Back"/>}
      </a>
    </span>)
  }

  hideBodyScroll = () => {
    document.body.classList.add("overflow-hidden");
  }

  showBodyScroll = () => {
    document.body.classList.remove("overflow-hidden");
  }

  changeStyleIfModalDataChanges = () => {
    toggleNavbarOnModalChanges(this.props.modal);
  }

  updateEmailSelected = (emailSelected) => {
    this.setState({ emailSelected });
  }

  render() {
    if (isSmartTv()) {
      this.changeStyleIfModalDataChanges();
    }

    if (this.props.isUserLoading)
      return <Loader />
    if (Object.keys(this.props.modal).length === 0) {
      return (
        <div className="mediaOverlay" />
      )
    } else if (this.props.modal.type === "video") {
      return (
        <div className="mediaOverlay showVideo">
          <OverlayVideo modal={this.props.modal} section={this.props.modal.content} app_id={this.props.app_id}
                        all_contents={this.props.modal.all_contents} feeds={this.props.feeds} metering_details={this.props.metering_details} parent_id= {this.props.modal.head} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "image") {
      return (
        <div className="mediaOverlay showGallery">
          <OverlayImage modal={this.props.modal} section={this.props.modal.content} app_id={this.props.app_id}
                        all_contents={this.props.modal.all_contents} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "fake") {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <FakeLive key={this.props.modal.section.identifier} feeds={this.props.modal.feeds} section={this.props.modal.section} app_id={this.props.app_id} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "live") {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <LiveSetup key={this.props.modal.section.identifier} feeds={this.props.modal.feeds} section={this.props.modal.section} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "svideo") {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <ContentSectionVideo feeds={this.props.modal.feeds} section={this.props.modal.section} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "login" && !this.state.emailSelected) {
      const headerLogo = this.props.feeds.loginUIMetadata && this.props.feeds.loginUIMetadata.registrationWall && this.props.feeds.loginUIMetadata.registrationWall.headerImage || (this.props.feeds.logo && this.props.feeds.logo.url);
      const allProps = {
        feeds: checkLoginUIMetaData(this.props.feeds),
        app_id: this.props.app_id,
        background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
        screen: this.props.modal.screen,
        titleMsg: this.props.modal.text || null,
        directLogin: this.props.modal.directLogin
      }
      return (
        <div id="overlay" className={"user-access overflow-scroll-auto " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
              this.props.clearPendingPurchase();
              this.closeLogin();
            })}
            {/* Lot of things to do on back button, that's why calling LoginFlow independently */}
            <LoginFlow
              emailSelected={false}
              updateEmailSelected={this.updateEmailSelected}
              headerLogo={headerLogo}
              selectEmail={this.selectEmail}
              allProps={allProps}
              modalLogin={true}
              runningOnMobile={this.props.runningOnMobile}
              updateIframeInfo={this.updateIframeInfo}
              iframe_render={this.state.iframe_render}
            />
          </div>
        </div>
      )
    } else if ((this.props.modal.type === "login" && (this.state.emailSelected || !this.props.feeds.loginUIMetadata.fb)) ||
      (this.props.modal.type === "userAccessPage")
    ) {
      const headerLogo = this.props.feeds.loginUIMetadata && this.props.feeds.loginUIMetadata.registrationWall && this.props.feeds.loginUIMetadata.registrationWall.headerImage || (this.props.feeds.logo && this.props.feeds.logo.url);
      const allProps = {
        feeds: checkLoginUIMetaData(this.props.feeds),
        app_id: this.props.app_id,
        background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
        screen: this.props.modal.screen,
        titleMsg: this.props.modal.text || null,
        directLogin: this.props.modal.directLogin
      }
      return (
        <div id="overlay" className={"user-access overflow-scroll-auto " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
              this.props.clearPendingPurchase();
              this.closeLogin();
            })}
            {/* Lot of things to do on back button, that's why calling LoginFlow independently */}
            <LoginFlow
              emailSelected={this.state.emailSelected}
              updateEmailSelected={this.updateEmailSelected}
              headerLogo={headerLogo}
              selectBack={this.selectBack}
              allProps={allProps}
              modalLogin={true}
              runningOnMobile={this.props.runningOnMobile}
              updateIframeInfo={this.updateIframeInfo}
              iframe_render={this.state.iframe_render}
            />
          </div>
        </div>
      )
    } else if (this.props.modal.type === "subscribe") {
      return (
        <div id="overlay" className="user-access">
          <ErrorDisplay dismiss={() => {
            this.dismissAction();
            this.props.clearPendingPurchase();
          }} errorMsg={this.props.modal.message} errorHeading={this.props.modal.heading} />
        </div>
      )
    } else if (this.props.modal.type === "subscriptions") {
      return (
        <div id="overlay" className={"user-access subscribe-page " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(this.dismissAction)}
            <Subscribe
              feeds={this.props.modal.feeds}
              subscriptions={this.props.modal.feeds.subscriptions}
              background={"#808080"}
              item={this.props.modal.content}
              parent_id={this.props.modal.parent_id}
              isOpenedFromModal={true}
            />
          </div>
        </div>
      )
    } else if (this.props.modal.type === "purchase") {
      return null
    } else if (this.props.modal.type === "payment") {
      return (
        <div id="overlay" className="user-access payment font-black">
          <div className="">
            {this.backButtonHtml(() => {
              this.props.clearPendingPurchase()
              this.closeLogin();
            })}
            <ConfirmPayment
              feeds={this.props.modal.feeds}
              onCancel={(e) => {
                this.props.clearPendingPurchase()
                this.closeLogin(e);
              }}
            />
          </div>
        </div>
      )
    } else if (this.props.modal.type === "error") {
      return (
        <div id="overlay" className="user-access">
          <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.props.modal.message} errorHeading={this.props.modal.heading} />
        </div>
      )
    } else if (this.props.modal.type === 'exit_app') {
      return (
        <div id="overlay" className="user-access" style={{ background: 'black' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'inherit', flexDirection: 'column' }}>
            <div>
              Are you sure you want to exit ?
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '13em', marginTop: '4em' }}>
              <button onClick={e => this.exitCtvApp(false)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#99bdff', padding: '1em 4em', borderRadius: '1em', fontSize: '1em' }}>No</button>
              <button onClick={e => this.exitCtvApp(true)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#f5f5f5', padding: '1em 4em', borderRadius: '1em', marginTop: '2em', fontSize: '1em' }}>Yes</button>
            </div>
          </div>
        </div>
      );
    } else {
      return (null)
    }
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
  logged_in: state.user.logged_in,
  user: state.user.user,
  isUserLoading: state.user.isLoading,
  isMeteringLoading: state.user.meteringLoading,
  metering_views_seen: state.user.metering_views_seen,
  userError: state.user.error,
  isPurchasePending: state.subscription.purchase.isPending
})

const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal,
  saveItem: saveItem,
  clearPendingPurchase,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));
