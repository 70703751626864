import React from 'react';
import Swiper from 'react-id-swiper';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { JUMBO_THEME } from '../constants/common';
import { getFontStyleFromFeed, getSwiperParamsByTheme } from "../helperfunctions";
import { appendVirtualSwiperParams, checkIfEmptyMazIdFeedsInContents, focusVideoItemWhichWerePlayed, isSmartTv, setLeftStyleToSlideItems } from '../helperfunctions/common';

class JumboContent extends React.Component {
  constructor(props){
    super(props);
    this.swiper = null
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      }
    }
    this.swiperParams = null;
    this.generateSwiperParam();
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // this.swiper.slideTo(this.props.modal_progress.index)
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item)
    }
    !isSmartTv() && setLeftStyleToSlideItems(this.props.content.identifier, this.state.virtualData.offset);
  }

  onRenderExternal = (virtualData) => {
    this.setState({
      virtualData
    })
  }

  generateSwiperParam = () => {
    const _self = this;
    let params = getSwiperParamsByTheme(_self, this.props.content, JUMBO_THEME);
    if (!isSmartTv()) {
      this.swiperParams = appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal)
    } else {
      this.swiperParams = params;
    }
  }

  render(){
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let jumboClassName;
    // let params = getSwiperParamsByTheme(this, this.props.content, JUMBO_THEME)
    if(this.props.content.thumbOrientation === "portrait"){
      jumboClassName = "slider-box jumbo-view portrait-view content-" + this.props.content.identifier
    } else {
      jumboClassName = "slider-box jumbo-view content-" + this.props.content.identifier
    }
    
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    return (
      <div className={jumboClassName}>
        {this.props.content.locked && <Cta layout={"jumbo"} time={Date.now()} content={this.props.content} feeds={this.props.feeds}/>}
        <h2 style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>
        <Swiper {...this.swiperParams}>
          {
            isSmartTv()
            ? (this.props.contents) 
            : (this.state.virtualData.slides)
          }
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(JumboContent);
