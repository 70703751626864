import React from 'react'
import { connect } from "react-redux"
import { saveItem, getSavedItems, deleteItem } from "../actions/itemActions"
import { openModal } from "../actions/modalActions"
import isAvailable from '../helperfunctions/is_available'
import * as analytics  from '../constants/analytics';
import { getParentFeed } from '../helperfunctions'
import { getTabIndex } from '../helperfunctions/common'



function isItemSaved(item_id, item_hash, content_type) {
    var flag = 0;
    for(var i=0; i< item_hash.length; i++){
        if(item_id === item_hash[i].identifier){
            flag = 1;
            break;
        }
    }
    if(flag === 1){
        return true;
    } else{
        return false;
    }
}

function createBigIcon(url) {
    return (url.split(".png")[0] + "@2x" + ".png")
}

function isItemLocked(locked, content) {
  return (locked && !isAvailable(content.parent_id, content.special, content.registerWall));
}

function isViewMetering(feeds) {
  if (feeds && feeds.ctaMetadata && feeds.ctaMetadata.metering) return true;
  return false;
}

class SaveIcon extends React.Component {
    constructor(props){
        super(props);
        this.saveItem = this.saveItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
    }
    saveItem(ev){
      let { locked, feeds, content, metering_views_seen } = this.props;
      if (content.mazContentType && content.lineage) {
        const parentFeed = getParentFeed(feeds, `${content.lineage}/${content.identifier}`);
        locked = parentFeed.locked;
        content = {
          ...content,
          locked: parentFeed.locked,
          access: parentFeed.access,
          special: parentFeed.special,
          displayTitle: parentFeed.displayTitle || null,
          registerWall: parentFeed.registerWall,
          parent_id: parentFeed.identifier,
          showAd: parentFeed.showVideoAd
        }
      }
      const isLocked = isItemLocked(locked, content);
      const viewMetering = isViewMetering(feeds);
      if(!this.props.logged_in){
        this.props.openModal({type: "login", saved_item: this.props.content, locked: locked, viewMetering: viewMetering})
      } else {
        let viewRemaining = 0;
        if(viewMetering) {
          viewRemaining = feeds.ctaMetadata.metering.freeViews - metering_views_seen.length;
        }
        if(isLocked && (!viewMetering || (!metering_views_seen.includes(content.identifier) && viewRemaining <= 0)) ) {
          // if locked with time metering or views has expired
          this.props.openModal({type: "error", message: "You must unlock this content to save it."})
        } else {
          analytics.setEvent('Save', 'Save', this.props.content.title)
          let paths = window.location.pathname.split("/").splice(3);
          paths.push(this.props.content.parent_id);
          let content = {...this.props.content, lineage: paths.join("/")}
          this.props.saveItem(this.props.user.auth_token, content.identifier, this.props.user.user_id, content)
        }
      }
      ev.preventDefault()
    }
    removeItem(ev){
        analytics.setEvent('Save', 'Archive from Saved/Queue', this.props.content.title)
        this.props.deleteItem(this.props.user.auth_token, this.props.content.identifier, this.props.user.user_id, this.props.content)
        ev.preventDefault()
    }
  render(){
    if(this.props.no_color){
      var style = {
        color: "#ffffff"
      }
    }
    if (this.props.no_color) {
      if(!(this.props.feeds.savedIconUrl && this.props.feeds.savedFillIconUrl)){
        if (!isItemSaved(this.props.content.identifier, this.props.item_content, this.props.type)) {
          return (
            <a href="#" onClick={this.saveItem}><span className="icon-star focusable" tabIndex={getTabIndex()} style={style}></span></a>
          )
        } else {
          return (
            <a href="#" onClick={this.removeItem}><span className="icon-starfill focusable"  tabIndex={getTabIndex()} style={style}></span></a>
          )
        }
      } else {
        if (!isItemSaved(this.props.content.identifier, this.props.item_content, this.props.type)) {
          return (
            <a href="#" onClick={this.saveItem}><img style={{marginLeft: '0px'}} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedIconUrl)}/></a>
          )
        } else {
          return (
            <a href="#" onClick={this.removeItem}><img style={{marginLeft: '0px'}} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedFillIconUrl)}/></a>
          )
        }
      }
    } else {
      if(!(this.props.feeds.savedColorIconUrl && this.props.feeds.savedColorFillIconUrl)){
        if (!isItemSaved(this.props.content.identifier, this.props.item_content, this.props.type)) {
          return (
            <a href="#" onClick={this.saveItem}><span className="icon-star focusable"  tabIndex={getTabIndex()} style={style}></span></a>
          )
        } else {
          return (
            <a href="#" onClick={this.removeItem}><span className="icon-starfill focusable"  tabIndex={getTabIndex()} style={style}></span></a>
          )
        }
      } else {
        if (!isItemSaved(this.props.content.identifier, this.props.item_content, this.props.type)) {
          return (
            <a href="#" onClick={this.saveItem}><img style={{marginLeft: '0px'}} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedColorIconUrl)}/></a>
          )
        } else {
          return (
            <a href="#" onClick={this.removeItem}><img style={{marginLeft: '0px'}} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedColorFillIconUrl)}/></a>
          )
        }
      }
    }
  }
}

const mapStateToProps = (state) => ({
    item_content: state.items.items.content,
    user_id: state.items.user_id,
    logged_in: state.user.logged_in,
    user: state.user.user,
    metering_views_seen: state.user.metering_views_seen,
    subscription: state.user.subscription,
})

const mapDispatchToProps = {
    getSavedItems: getSavedItems,
    saveItem: saveItem,
    deleteItem: deleteItem,
    openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveIcon)
