import React from 'react';
import {BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { userActions, logoutUserActions, removeSimulatedSubPurchase, logoutMazTv } from "../actions/userActions"
import Background from '../components/Background'
import { clearSavedItems } from "../actions/itemActions"
import Cookies from 'universal-cookie';
import { httpsUrl, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG } from "../constants/common"
import { openModal } from "../actions/modalActions"
import config from "../constants/config"
import { clearErrorMessage } from "../actions/subscriptionAction";
import * as analytics  from '../constants/analytics';
import { Focusable } from 'react-js-spatial-navigation';
import TvFocusable from '../components/TvFocusable';
import configuration from '../constants/config';
import { checkCorrectPlatform, getTabIndex, isSmartTv } from '../helperfunctions/common';


const divStyle = {
  height: '100%'
}

const cookies = new Cookies();

class Settings extends React.Component {
  constructor(){
    super();
    this.logout = this.logout.bind(this);
    this.removeSimulatedPurchase = this.removeSimulatedPurchase.bind(this);
    this.state = {
      currentView: ''
    }
    this.errTimer = null;
  }
  componentDidMount(){
    analytics.setPageViewFixedSections('Settings'); 
    if(configuration.is_simulator) {
      const navbar = document.getElementsByClassName("nav-view")[0];
      navbar && navbar.parentNode.classList.remove('fix-nav');
      var navbarLinks = document.getElementsByClassName("headerLinks")
      const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
      for(var i=0; i<navbarLinks.length; i++){
        navbarLinks[i].classList.remove("active")
        if(ottTabBarInactiveColor) {
          navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
        }
      }
      if(document.getElementsByClassName("navbar-settings")[0]) {
        document.getElementsByClassName("navbar-settings")[0].classList.add("active")
        if(ottTabBarActiveColor) {
          document.getElementsByClassName("navbar-settings")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        }
      }
    }
    if(this.props.logo && !this.props.runningOnMobile){
      var myImage = document.getElementById('settings-logoHolder');
      myImage.addEventListener("load", function(){
        var i = new Image();
        i.src = this.src
        var aspect = i.width/i.height
        if(aspect < 4.0){
          myImage.parentNode.classList.add('smallImg')
        }
      }, {once: true})
    }
  }
  logout(e){
    if(e){
      e.preventDefault();
    }
    if (this.props.mazTVLogin) {
      this.props.logoutMazTv();
    }
    this.props.logoutUserActions();
    this.props.clearSavedItems();
    // remove cookie
    try {
      if(checkCorrectPlatform([PLATFORM_LG])) {
        localStorage.removeItem('AppUser');
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.removeData({'name': 'AppUser'});
      } else {
        cookies.remove('AppUser', { path: '/' });
      }
    } catch(err) {
      console.log("Logout issues");
    }
  }

  removeSimulatedPurchase(e){
    if(e){
      e.preventDefault();
    }
    this.props.removeSimulatedSubPurchase()
    this.props.openModal({type: "subscribe", message: "The simulated purchase has been cancelled."})
  }

  navigateToRoute(route) {
    this.setState({ currentView: route })
    this.props.parentCallback(route);
  }

  onSubscriptionClick = (e) => {
    e.preventDefault();
    this.props.history.push("/apps/" + this.props.app_id + "/subscription");
  }

  logIn = () => {
    this.props.openModal({type: "login", directLogin: true})
  }

  clickEnter = (e) => {
    // console.log('Click enter',e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();
  }
  
  onCCPAClick = () => {

    var message = "";
    if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMessage){
      message = this.props.feeds.ccpa.ccpaMessage;
    }else if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMailId){
      message = "Please write to " + this.props.feeds.ccpa.ccpaMailId + " to request that we do not sell your personal data."
    }
    this.props.openModal({type: "error", message : message})
  }

  render(){
    document.body.className = "settings"
    var purchase_option =  "";
     purchase_option = <li>
        <a className="btn-LogIn focusable" tabIndex={getTabIndex()} href="#" onClick={this.onSubscriptionClick}>Subscription</a>
     </li>;
     if ((config.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) && this.props.subscription) {
      purchase_option = <li><a className="btn-LogIn focusable" tabIndex={getTabIndex()} href="#" onClick={this.removeSimulatedPurchase}>Cancel Purchase</a></li>;
    }

    var ccpa = "";
    if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMessage){
      ccpa = <li><a className="btn-LogIn focusable" tabIndex={getTabIndex()} href="#" onClick={this.onCCPAClick}>CCPA</a></li>;
    }else if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMailId){
      ccpa = <li><a className="btn-LogIn focusable" tabIndex={getTabIndex()} href="#" onClick={this.onCCPAClick}>Do not sell my personal data</a></li>;
    }
    
    return (
      <div style={divStyle} className={this.props.runningOnMobile ? "mobile-view" : ""}>
          <Background />
          <div style={divStyle}>
              <div className="logIn-container">
                {this.props.logo && !this.props.runningOnMobile && <div id="settings-logoHolder" className="settings-logoHolder"><img src={httpsUrl(this.props.logo.url)} /></div>}
                <div className="SettingScreen">
                  <ul>
                    {(this.props.logged_in)
                      ? <li><a className="btn-LogIn focusable" tabIndex={getTabIndex()} href="#" onClick={this.logout}>Log Out</a></li>
                      : <li><a className="btn-LogIn focusable" tabIndex={getTabIndex()} onClick={this.logIn}>Log In</a></li>
                    }
                    {this.props.has_subs && purchase_option}
                    {this.props.feeds.ccpa && ccpa}
                  </ul>
                </div>
              </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  logged_in: state.user.logged_in,
  subscription: state.user.subscription,
  allSubscription: state.subscription,
  mazTVLogin: state.user.mazTVLogin
})

const mapDispatchToProps = {
  openModal: openModal,
  removeSimulatedSubPurchase: removeSimulatedSubPurchase,
  userActions: userActions,
  logoutUserActions: logoutUserActions,
  clearSavedItems: clearSavedItems,
  clearErrorMessage,
  logoutMazTv
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings))
