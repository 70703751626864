import React from 'react'
import { Link } from 'react-router-dom'
import StandardTitle from "./StandardTitle";
import { connect } from "react-redux"
import { openModal } from "../actions/modalActions"
import { correctPath, httpsUrl } from "../constants/common"
import isAvailable from '../helperfunctions/is_available'
import * as analytics  from '../constants/analytics';
import configuration from '../constants/config'
import { getTabIndex } from '../helperfunctions/common';


function GetImageUrl(url){
  var newUrl = url.split(".")
  newUrl[newUrl.length - 2] = newUrl[newUrl.length - 2] + "-384"
  newUrl = newUrl.join(".")
  return newUrl
}

class ThumbnailSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hover: false,
      sourceClass: '',
    }
  }

  onMouseEventHandler(hover, sourceClass) {
    var elem = document.getElementsByClassName("searchContent hovered")[0]
    if(elem){
      elem.classList.remove("hovered")
    }
    if(hover){
      this.setState({
        hover: hover,
        sourceClass: sourceClass,
      });
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      })
    }
  }

  openModal(e){
    let content = this.props.content;
    const modalParams = {head: "search", identifier: this.props.content.identifier, content: content,
    all_contents: this.props.all_contents, pathname: window.location.pathname, firstpath: "/apps/"+ configuration.app_id +"/search/" + this.props.content.identifier};
    if (this.props.content.type === "vid") {
      this.props.openModal({...modalParams, type: "video"});
    } else if(this.props.content.type === "image"){
      this.props.openModal({...modalParams, type: "image"});
    }
    e.preventDefault()
  }

  render(){
    if(this.props.remember[this.props.content.identifier]){
      var played = this.props.remember[this.props.content.identifier]["p"]
      var total = this.props.remember[this.props.content.identifier]["t"]
      var style = {
        width: played*100/total + "%",
        backgroundColor: this.props.secondary ? this.props.secondary.replace('#FF','#') : "#fff"
      }
    }

    if(this.state.hover){
      return(
        <div className={"searchContent item-" + this.props.content.identifier}>
          <a tabIndex={getTabIndex()} href="" onClick={(event) => this.openModal(event)} className="thumbLink thumbHover focusable">
            <div style={{backgroundColor: (this.props.secondary ? this.props.secondary.replace('#FF','#') : "#fff")}} className="img-video" onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)} onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}>
              {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
              <video className={"video-thumb video-content-" + this.props.content.identifier} preload="10" autoPlay muted loop >
                <source src={httpsUrl(this.props.content.contentUrl)} type="video/mp4"></source>
              </video>
              {
                this.props.content.cover && 
                <img className={"img-thumb image-content-" + this.props.content.identifier} src={GetImageUrl(httpsUrl(this.props.content.cover.url))}/>
              }
            </div>
          </a>
          <StandardTitle no_color={true} locked={this.props.locked && !isAvailable(this.props.content.identifier, this.props.content.special)} secondary={this.props.secondary} content={this.props.content} type={"search"} feeds={this.props.feeds} />
        </div>
      )
    } else {
      return (
        <div className={"searchContent item-" + this.props.content.identifier}>
          <a tabIndex={getTabIndex()} href="" onClick={(event) => this.openModal(event)} className="thumbLink focusable"
            onFocus={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)}
            onBlur={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}          
          >
            <div style={{backgroundColor: this.props.primary ? this.props.primary.replace('#FF','#') : "#fff"}} className="img-video"
              onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)}
              onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}
            >
              {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
              {
                this.props.content.cover && 
                <img className={"img-thumb image-content-" + this.props.content.identifier} src={GetImageUrl(httpsUrl(this.props.content.cover.url))}/>
              }
            </div>
          </a>
          <StandardTitle no_color={true} locked={this.props.locked && !isAvailable(this.props.content.identifier,this.props.content.special)} secondary={this.props.secondary} content={this.props.content} type={"search"} feeds={this.props.feeds} />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  modal: state.modal.modal,
  subscription: state.user.subscription
})

const mapDispatchToProps = {
  openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThumbnailSearch);
