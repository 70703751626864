import React from 'react';
import LoaderPulse from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Logo from './Logo';
import QRCode from "qrcode.react";
const FbIconImage = () => <img src={require('../images/icons/facebook-store.png')} className="fb-icon"/>

function websiteLoginLoader(props) {
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    return (<div className="loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <div className="loader-details">
            <div>1. On your phone or computer, go to:</div>
            <b>{props.loaderText}</b>
            <div>2. Create an account or login on that device.</div>
        </div>
    </div>)
}

function subscriptioPaymentLoader(props) {
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    return (
    <div className="payment-details-ctv loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <div className="loader-details">
            <b className="text-center">{props.loaderText}</b>
            <div className="text-center">OR</div>
            <div className="text-center">
                <QRCode value={props.loaderText}/>
            </div>
            <div>On your phone or computer, open the link or Scan the QR code</div>
            <div>To make the payment on that device.</div>
        </div>
    </div>
    )
}

function fbLoadinScreen(props) {
    return (<div className="fb-wait-loader loader-scrn">
        <div className="fb-loader-details">
            <span><FbIconImage /></span>
            <div className="fb-code">{props.loaderText}</div>
            <div className="fb-desc">Visit <b>facebook.com/device</b> on your smartphone or computer and enter the above code</div>
        </div>
    </div>)
}

function Loader(props) {
    if (props.loadingType == "websiteScreen") {
        return (websiteLoginLoader(props));
    }
    if (props.loadingType == "paymentLoader") {
        return (subscriptioPaymentLoader(props))
    }
    if (props.loadingType == "fbLoader") {
        return (fbLoadinScreen(props))
    }
    return (
        <div className="payment-loader">
            <LoaderPulse
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
            />
            <br />
            <div className="loader-text">{props.loaderText}</div>
        </div>
    )
}

export default Loader;