import React from 'react';
import { connect } from "react-redux"

import { closeModal,openModal } from '../actions/modalActions'
import { DEEPLINK } from '../constants/common';
import { backButtonObj } from '../helperfunctions';


function BackButton(props, section_id){
  return backButtonObj(props, section_id).pathLocation;
}

function ParentSection(feeds,section_id){
  var sections = feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents;
        }
      }
    }
  }
  return content
}

class Video extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount(){
    const {identifier, showVideoAd, locked, access, special, displayTitle, registerWall, inlineMenu} = ParentSection(this.props.feeds,this.props.section_id);
    let section = {
      ...this.props.section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      inlineMenu: inlineMenu || ""
    }
    let all_contents = this.props.all_contents.map((item) => {
      return {
        ...item,
        locked: locked,
        access: access,
        special: special,
        displayTitle: displayTitle || null,
        registerWall: registerWall,
        parent_id: identifier,
        inlineMenu: inlineMenu || ""
      }
    })
    this.props.openModal({head: identifier, type: "video", identifier: section.identifier,
      content: section, show_ad: showVideoAd, all_contents: all_contents,
      callbackParent: (route) => this.props.callbackParent(route),
      deepLink: DEEPLINK,
      pathname: "/apps/" + this.props.app_id + "/" + BackButton(this.props.feeds, this.props.section_id),
      firstpath: window.location.pathname
    })
  }

  componentWillUnmount(){
    this.props.closeModal()
  }

  render(){
    return null;
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
