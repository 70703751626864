import React from 'react';
import { Focusable } from 'react-js-spatial-navigation';
import { detectPlatform } from '../helperfunctions/common';

let platform = detectPlatform();

const getFocusable = (props) => {
    const classList = (props.classList) ? props.classList.join(' ') : '';

    return (
        <Focusable
            className= { classList } 
            onClickEnter = {(e) => {
                // window.last_focus = e.srcElement;
                if(props.clickEnter) {
                    props.clickEnter(e);
                }
            } }
            onFocus = {props.onFocus ? props.onFocus : ''}
            onUnfocus = {props.onUnfocus ? props.onUnfocus : ''}
        >
            { props.children }
        </Focusable>
    );
};

const TvFocusable = (props) => {

    switch(platform) {
        case 'Samsung':
            return getFocusable(props);
        
        case 'LG':
            return getFocusable(props);

        case 'Macintosh':
            return getFocusable(props);

        default:
            return (
                <React.Fragment>
                    { props.children }
                </React.Fragment>
            );
    }
}

export default TvFocusable;