import React from 'react';
import SaveIcon from "../components/SaveIcon"
import store from "../store"
import Cta from "../components/Cta";
import { getFontStyleFromFeed } from "../helperfunctions";

class DetailTitle extends React.Component {

  render(){
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    if(this.props.type === "menu"){
      return(
        <div>
          {this.props.content.locked && <Cta layout={"detail"} time={Date.now()} detail={true} store={store} content={this.props.content} feeds={this.props.feeds} />}
          <div className="summaryHolder">
            <h4 style={fontStyle.primaryFont}>{this.props.summary.title}</h4>
            <p style={fontStyle.secondaryFont}>{this.props.summary.subtitle}</p>
            <p className="summaryDescription"  style={fontStyle.secondaryFont}>{this.props.summary.description}</p>
          </div>
        </div>
      )
    } else {
      return(
        <div>
          {this.props.parent_content.locked && <Cta layout={"detail"} time={Date.now()} detail={true} store={store} content={this.props.parent_content} feeds={this.props.feeds} />}
          <div className="summaryHolder">
            <h4 style={fontStyle.primaryFont}>{this.props.summary.title}</h4>
            <p style={fontStyle.secondaryFont}>{this.props.summary.subtitle}</p>
            <p className="summaryDescription" style={fontStyle.secondaryFont}>{this.props.summary.description}</p>
          </div>
          {(this.props.feeds.hasSavedSection || this.props.haveSavedMazIDFeed) && <div className="saveIcon">
            <SaveIcon 
              locked={this.props.parent_content.locked} 
              store={store} 
              content={this.props.content} 
              type={"content"} 
              secondary={this.props.secondary} 
              feeds={this.props.feeds} 
              />
          </div>}
        </div>
      )
    }
  }
}

export default DetailTitle;
