import { connect } from "react-redux"
import React from 'react';


import store from './../store.js';

function isAvailable(props,special=false, register_wall=false, content=null){
    // locked content is normally locked sub
    // if it is special then it will locked by only iap or registerwall
    var newState = store.getState();
    let iap_lock = special
    let sub_lock = true
    let responses = []

    if (content){
      iap_lock = content.access.iap || false
      special = content.special
      register_wall =  (content.registerWall && register_wall)
    }
    if(special){
      sub_lock = false
    }

    if (!register_wall && !iap_lock && !sub_lock){
      return true
    }
    if(register_wall){
      responses.push(newState.user.logged_in)
    }
    if (iap_lock){
      responses.push(newState.user.items_purchased.includes(props))
    }
    if (sub_lock) {
      responses.push(newState.user.subscription || newState.user.items_purchased.includes(props))
    }
    let response = responses.filter(function(value) {return value})
    return response[0] || false
}

store.subscribe(isAvailable)



export default isAvailable;

/**
 * Function will check in all subscription that user is subscribed or not.
 * @param {*} allSubscription
 */
export function isUserSubscribed(allSubscription) {
  let isSubscribed = false;
  let sub_platform = "web_as_output";
  allSubscription.forEach(subs => {
    if (subs.type == 'subscription') {
      const currDate = new Date();
      //Adding 1 minute in currDate, So don't show wrong data in subscription page. when subscription get cancelled.
      currDate.setMinutes(currDate.getMinutes() + 1);
      const subEndDate = new Date(subs.end_date);
      if (subEndDate > currDate) {
        isSubscribed = true;
        sub_platform = subs.platform
      }
    }
  });
  return [isSubscribed,sub_platform];
}
