import React from 'react';
import Swiper from 'react-id-swiper';

import downArrow from "../images/icons/down-arrow.png";
import upArrow from "../images/icons/up-arrow.png";
import flatArrow from "../images/icons/flat.png";
import { getFontStyleFromFeed } from "../helperfunctions";
import { httpsUrl, INLINE_MENU_IDLE_TIMEOUT, INLINE_MENU } from "../constants/common";
import { getImageUrlBaseOnSize, getTabIndex, isSmartTv } from '../helperfunctions/common';
import config from '../constants/config';
import { getWebBannerSwiperParams, getWebSwiperParamsByTheme } from '../helperfunctions/webSwiperParams';
import FocusLock, { MoveFocusInside } from 'react-focus-lock';
import { FocusableSection } from 'react-js-spatial-navigation'

class InlineMenu extends React.Component {
    constructor(props) {
      super(props);
      this.curr_focus = 0;
      this.timerCtv=null;
      this.state = {
        showInlineMenu: false,
        isOpening: false,
        isClosing: false,
        swiper: null
      }
  }

    componentDidMount() {
      document.body.addEventListener("mousemove", this.onBodyMouseMoveHideShowMenu, true);
      document.body.addEventListener("click", this.onBodyMouseMoveHideShowMenu, true);
      this.onBodyMouseMoveHideShowMenu();
      document.addEventListener('keyup', this.handleRemoteClick);
    }

    componentDidUpdate(prevProps, prevState) {
      // slide to focused slide
      if(this.state.showInlineMenu && prevState.showInlineMenu !== this.state.showInlineMenu) {
        const swiperEl = document.querySelector('#video-inlineMenu .swiper-container');
        if(!isSmartTv() && !isNaN(this.props.playing) && swiperEl && swiperEl.swiper) {
            swiperEl.swiper.slideTo(this.props.playing)
        }
      }
    }

    handleRemoteClick = (e) => {
      if(document.getElementById("next-video") && document.getElementById("next-video").style.display === 'inline')
        return;

      if(e.keyCode == '38') {
        this.onBodyMouseMoveHideShowMenu();
        this.setState({
          showInlineMenu: true,
          isOpening: true,
          isClosing: false
        })
        this.timerCtv && clearTimeout(this.timerCtv);
        this.timerCtv = setTimeout(() => {
          this.setState({
            showInlineMenu: true,
            isOpening: false,
            isClosing: false
          },() => {
            this.focusPlayingElement(this.props.playing);
            // if(document.getElementById('menu_vid_0')) {
            //   document.getElementById('menu_vid_0').focus();
            // }
          })
        },500)
      } else if(e.keyCode == '40') {
        this.onBodyMouseMoveHideShowMenu();
        this.setState({
          showInlineMenu: false,
          isOpening: false,
          isClosing: true
        })  
        this.timerCtv && clearTimeout(this.timerCtv);
        this.timerCtv = setTimeout(() => {
          this.setState({
            showInlineMenu: false,
            isOpening: false,
            isClosing: false
          })
        },500)
      } else if(e.keyCode == '37' || e.keyCode == '39') {
        this.onBodyMouseMoveHideShowMenu();
      }
    }

    focusPlayingElement = (index) => {
      if (!(index >= 0)) index = 0;
      if (isSmartTv) {
        const elementId = `menu_vid_${index}`
        if(document.getElementById(elementId)) {
          document.getElementById(elementId).focus();
        }
      }
    }

    componentWillUnmount() {
      document.body.removeEventListener("mousemove", this.onBodyMouseMoveHideShowMenu, true);
      document.body.removeEventListener("click", this.onBodyMouseMoveHideShowMenu, true);
      if (this.hideMenuTimeout) {
        clearTimeout(this.hideMenuTimeout);
      }
      document.removeEventListener('keyup', this.handleRemoteClick);
    }

    onBodyMouseMoveHideShowMenu = () => {
      if (document.getElementById("video-inlineMenu")) {
        if (this.hideMenuTimeout) {
          clearTimeout(this.hideMenuTimeout);
        }
        this.showMenu();
        this.hideMenu();
      }
    }
  
    hideMenu = () => {
      this.hideMenuTimeout = setTimeout(() => {
        if (document.getElementById("video-inlineMenu")){
          document.getElementById("video-inlineMenu").style.display = "none" ;
          if(!isSmartTv && this.state.showInlineMenu) {
            this.handleInlineMenu();
          }
        }
      }, INLINE_MENU_IDLE_TIMEOUT)

      // if(document.getElementById('thumb-video_html5_api')) {
      //   document.getElementById('thumb-video_html5_api').focus();
      // }
    }
  
    showMenu = () => {
      if (document.getElementById("video-inlineMenu") && !(
        document.getElementById("next-video") && document.getElementById("next-video").style.display === "inline"
      )) {
        document.getElementById("video-inlineMenu").style.display = "inherit";
      }
    }

    handleInlineMenu() {
      const isOpened = this.state.showInlineMenu;
      this.setState({
        showInlineMenu: true,
        isOpening: !isOpened,
        isClosing: isOpened
      })
      this.timerCtv = setTimeout(() => {
        this.setState({
          showInlineMenu: !isOpened,
          isOpening: false,
          isClosing: false
        })
      },500)
    }

    playVideo(index) {
      if(this.props.playing == index) {
        this.handleInlineMenu();
        return;
      }
      this.props.next(index);
    }

    renderMenu() {
      let params;
      if (!config.is_simulator) {
        params = getWebSwiperParamsByTheme(this, null, INLINE_MENU)
      } else {
        params = {
            mousewheelControl: true,
            slidesPerView: 'auto',
            spaceBetween: 30,
            mousewheelForceToAxis: true,
            mousewheelInvert: true,
            preloadImages: false,
            lazyLoading: true,
            freeMode: true,
            onInit: (swiper) => {
                this.swiper = swiper
            },
            mousewheel: {
                forceToAxis: true,
                invert: true,
            }
        }
      }

        // if(isSmartTv()) {
        //   delete params.mousewheel;
        //   params.keyboard = {
        //     enabled: true,
        //     onlyInViewport: true
        //   };
        // }

        const primary = this.props.feeds.primaryColor || "#FFFFFF";
        const fontStyle = getFontStyleFromFeed(this.props.feeds);
        return (
            <Swiper {...params}>
                {this.props.all_contents.map((video, index) => {
                  if (!video) return null;
                  const {cover, identifier, title, type} = video;
                  if(type !== "vid") return null;
                  return (
                    <div key={identifier} className="video-inlineMenuItem img-video">
                      {this.props.inlineMenu === "full"
                        ? (
                        <span id={`menu_vid_${index}`} tabIndex={getTabIndex()} className={this.props.playing === index ? "selected focusable" : "focusable"} 
                          style={{backgroundColor: primary.replace('#FF','#')}}
                          onClick={() => this.playVideo(index) }
                        >
                          <img alt="play"
                            style={{visibility:"hidden"}}
                            src={cover && httpsUrl(cover.url) && getImageUrlBaseOnSize(httpsUrl(cover.url), 384)} 
                            onLoad={(e) => {e.target.style.visibility='visible'}}
                          />
                        </span>)
                        : 
                        (
                          <span id={`menu_vid_${index}`} tabIndex={getTabIndex()} className={"limited " + (this.props.playing === index ? "selected focusable" : "focusable")} 
                            style={{backgroundColor: primary.replace('#FF','#')}}
                          >
                            <img alt="play" 
                              style={{visibility:"hidden"}}
                              src={cover && httpsUrl(cover.url) && getImageUrlBaseOnSize(httpsUrl(cover.url), 384)} 
                              onLoad={(e) => {e.target.style.visibility='visible'}}/>
                          </span>)
                      }
                      <div style={{...fontStyle.primaryFont}} className="title">
                        <p>{title}</p>
                      </div>
                    </div>
                  )
                })}
            </Swiper>
        )
    }

    render() {
        if (!this.props.inlineMenu || this.props.inlineMenu === "") return null;
        const fontStyle = getFontStyleFromFeed(this.props.feeds);
        let parentClasses = "video-inlineMenu";
        if(!this.state.showInlineMenu) {
          parentClasses = parentClasses + " closedMenu";
        }
        if(this.state.isClosing) {
          parentClasses = parentClasses + " closingNow";
        }
    
        return (
          <MoveFocusInside>
            <div id="video-inlineMenu" style={fontStyle.primaryFont} className={parentClasses}>
                <div className="button-toggle">
                  { (this.state.isClosing || this.state.isOpening)
                    ? <img alt="Menu" src={this.state.isClosing ? downArrow : upArrow}/>
                    : <img alt="Menu" src={this.state.showInlineMenu ? downArrow : upArrow} onClick={() => this.handleInlineMenu()}/>
                  }
                </div>
                {this.state.showInlineMenu
                    ? <div className={"menu"}>
                        {this.renderMenu()}
                        </div>
                    : <div className="menu not-visible" style={{ width: 'max-content'}}></div>
                }
            </div>
          </MoveFocusInside>
        )
    }
}

export default InlineMenu;