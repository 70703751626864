import React from 'react';
import { connect } from "react-redux"
import { openModal, closeModal } from "../actions/modalActions"

class SectionVideoSetup extends React.Component {
  componentDidMount(){
    if(this.props.section.type === "fake"){
      this.props.openModal({type: "fake", feeds: this.props.feeds, section: this.props.section})
    } else if(this.props.section.type === "live"){
      this.props.openModal({type: "live", feeds: this.props.feeds, section: this.props.section})
    } else if(this.props.section.type === "svideo"){
      this.props.openModal({type: "svideo", feeds: this.props.feeds, section: this.props.section})
    }
  }

  componentWillUnmount(){
    this.props.closeModal()
    if(this.props.modal.type !== "live" && window.hls){
      window.hls.destroy();
    }
  }

  render(){
    return (
      null
    )
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
})

const mapDispatchToProps = {
  openModal: openModal,
  closeModal: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionVideoSetup);