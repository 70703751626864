export default function reducer(
  state={remember: {}, app_id: 0, fetched_from_server: false, fetched_after_login: false}, action) {

  switch (action.type) {
    case "LOCAL_REMEMBER_SPOT": {
      var remSpot = state.remember
      for(var i=0; i<Object.keys(action.payload).length; i++) {
        remSpot[Object.keys(action.payload)[i]] = action.payload[Object.keys(action.payload)[i]]
      }
      return {
        ...state, remember: remSpot, app_id: action.app_id
      }
    }
    case "SAVE_REMEMBER_SPOT_BATCH": {
      var remSpot = state.remember
      var keys = Object.keys(state.remember)
      for(var i=0; i<keys.length; i++){
        remSpot[keys[i]]["saved"] = true
      }
      return {
        ...state, remember: remSpot
      }
    }
    case "UPDATE_REMEMBER_SPOT_FLAG": {
      return {
        ...state, fetched_from_server: false
      }
    }
    case "GET_REMEMBER_SPOT_SYNC": {
      var data = action.payload
      var keys = Object.keys(data)
      if(keys.length > 0){
        for(var i=0; i<keys.length; i++){
          data[keys[i]]["saved"] = true
        }
      }
      return {
        ...state, remember: data, fetched_from_server: true, fetched_after_login: true
      }
    }
    case "REMEMBER_SPOT_ERROR": {
      return {
        ...state,
        error: action.payload
      }
    }
  }

  return state
}
