import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import search from "../images/icons/search-white.png";
import gear from "../images/icons/gear.png";
import menu from "../images/icons/menu.png";
import { getSearchResult, clearSearch } from '../actions/searchActions';
import configuration from '../constants/config';
import TvFocusable from './TvFocusable';
import { findDefaultSection } from '../helperfunctions';
import { AUTH_WEBSITE } from '../constants/common';
import { checkIfSingleOption } from '../helperfunctions/common';
import { openModal } from "../actions/modalActions"


class WebNavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           openInput: false,
           showSectionMenu: false,
           search_value: ""
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isSearchPending !== this.props.isSearchPending && this.props.isSearchPending) {
            if(!window.location.pathname.includes('search/') ) {
                // switch to search section if not already
                this.props.history.replace(`/apps/${configuration.app_id}/search`);
                if(this.state.showSectionMenu) {
                    // close mobile menu if open
                    this.handleMenuClick();
                }
            }
        }
        if(prevProps.currentView !== this.props.currentView && 
            !this.props.currentView.includes("/search") && 
            this.state.openInput
        ) {
            // close search if switching to another section
            this.closeSearch()
        }

        if(prevProps.modal !== this.props.modal && this.state.showSectionMenu &&
            this.props.modal && (
                this.props.modal.type === "video" ||
                this.props.modal.type === "image" ||
                this.props.modal.type === "fake" ||
                this.props.modal.type === "live" ||
                this.props.modal.type === "svideo" 
            )
        ) {
            // Close mobile menu in case of content play
            this.handleMenuClick();
        }
    }

    updateSearch(event) {
        var event_target = event.target.value;
        clearTimeout(this.timer);
        if (!event_target) {
            this.props.clearSearch()
        }
        else {
          this.timer = setTimeout(function() {
              this.props.getSearchResult(this.props.app_id, this.state.search_value)
          }.bind(this), 300)
        }
        this.setState({search_value: event_target})
    }

    handleSearchInput() {
        this.setState({openInput: true})
    }

    navigateToRoute(route) {
        this.handleMenuClick();
        this.props.navigateToRoute(route)
    }

    handleMenuClick() {
        this.setState({showSectionMenu: !this.state.showSectionMenu})
    }

    closeSearch() {
        this.setState({search_value: "", openInput: false})
        this.props.clearSearch();
    }

    clickEnter = (e) => {
        // console.log('Click enter',e);
        // document.activeElement.click();
        e.srcElement.childNodes[0].click();
    }

    addHttpPrefixIfNotPresent(url) {
        const httpPrefix = 'http://';
        const httpsP = 'https://';
        if (url.substr(0, httpPrefix.length).toLowerCase() !== httpPrefix && url.substr(0, httpsP.length).toLowerCase() !== httpsP) {
            url = httpPrefix + url;
            return url;
        }
        return url;
    }

    onSettingClick = (e) => {
        this.handleMenuClick();
        let settingurl = this.props.feeds.settingsUrlData && this.props.feeds.settingsUrlData.url;
        let settingUrlType = this.props.feeds.settingsUrlData && this.props.feeds.settingsUrlData.type;
        settingurl = settingurl && this.addHttpPrefixIfNotPresent(settingurl);
        e && e.preventDefault();
        if (settingUrlType === "login" && checkIfSingleOption('firstSave', this.props.feeds)) {
            this.props.openModal({type: "login", directLogin: true})
        }
        else if (settingurl && settingUrlType === "url") {
            window.location.assign(settingurl);
        } else {
            this.props.history.push("/apps/" + this.props.app_id + "/settings")
            this.props.navigateToRoute("settings")
        }
    }

    openDefault = () => {
        const sectionDetails = findDefaultSection(this.props.feeds);
        const pathLocation = sectionDetails[1] && sectionDetails[1][0];
        if (pathLocation)
            this.props.history.replace(`/apps/${this.props.app_id}/${pathLocation}`);
    }

    renderLogo(logo) {
        return (
            <>
                {(logo && logo.url)
                ? <img
                    style={{visibility:"hidden"}}
                    alt="Logo"
                    src={logo && logo.url}
                    onLoad={(e) => {e.target.style.visibility='visible'}}
                    onClick={this.openDefault}
                    className="nav-logo"
                />
                : <span onClick={this.openDefault} className="missing-nav-logo">{configuration.title || ""}</span>
                }
            </>
        )
    }

    renderSectionLinks(sections, isMobile) {
        return (
            <>
                {sections && sections.map((section) => {
                    if(section.type === "saved" && isMobile) return null; // No save section for mobile
                    return <NavLink key={section.identifier} to={"/apps/" + this.props.app_id + "/" + section.identifier} className={"headerLinks navbar-" + section.identifier} onClick={() => this.navigateToRoute(section.identifier)}>{section.title}</NavLink>
                })}
            </>
        )
    }

    renderSearch() {
        return (
            <div className={"web-search-box " + (!this.state.openInput ? "closed-search-box" : "")}>
                {!this.state.openInput
                    ? <img className="navbar-search" alt="Search" src={search} onClick={() => this.handleSearchInput()}/>
                    : <>
                        <input type="text" onChange={(e) => this.updateSearch(e)} id="search" value={this.state.search_value} placeholder="Search..." />
                        <span className="close-search-bt" onClick={() => this.closeSearch()}>&times;</span>
                    </>
                }
            </div>
        )
    }

    renderSettings() {
        return (
            <NavLink to={"/apps/" + this.props.app_id + "/settings"} className="headerLinks navbar-settings" onClick={this.onSettingClick}><img alt="Settings" src={gear}/></NavLink>
        )
    }

    renderSettingsName() {
        return (
            <NavLink to={"/apps/" + this.props.app_id + "/settings"} className="headerLinks navbar-settings" onClick={this.onSettingClick}>Settings</NavLink>
        )
    }

    render() {
        const { sections, logo } = this.props.feeds;
        return (
            <>
                {(this.props.runningOnMobile)
                    ? (
                        <div id="web-nav-bar-mobile-wrapper">
                            <div id="web-nav-bar-mobile">
                                {this.renderLogo(logo)}
                                <div className="right-links">
                                    {this.renderSearch()}
                                    <img alt="Menu" src={menu} onClick={() => this.handleMenuClick()}/>
                                </div>
                            </div>
                            {this.state.showSectionMenu && <div className="section-menu-mobile">
                                {this.renderSectionLinks(sections, true)}
                                {this.renderSettingsName()}
                            </div>}
                        </div>
                    )
                    : (
                        <div id="web-nav-bar">
                            <div className="left-links">
                                {this.renderLogo(logo)}
                                {this.renderSectionLinks(sections)}
                            </div>
                            <div className="right-links">
                                {this.renderSearch()}
                                {this.renderSettings()}
                            </div>
                        </div>
                    )

                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
  isSearchPending: state.search.isSearchPending
})

const mapDispatchToProps = {
    getSearchResult: getSearchResult,
    clearSearch,
    openModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebNavBar));
