import { DETAIL_THEME, GRID_THEME, INLINE_MENU, JUMBO_THEME, SPOTLIGHT_THEME, VIDEO_GRID_THEME_THEME } from "../constants/common"

function commonParams(_self) {
    return {
        mousewheelControl: false,
        observer: true,
        spaceBetween: 20,
        mousewheelForceToAxis: true,
        mousewheelInvert: true,
        preloadImages: false,
        slidesOffsetAfter: 30,
        lazyLoading: true,
        freeMode: true,
        onInit: (swiper) => {
            _self.swiper = swiper
        },
        mousewheel: {
            forceToAxis: true,
            invert: true,
        }
    }
}
function commonPortrait(_self) {
    return {
        ...commonParams(_self),
        slidesPerView: 8.1,
        breakpoints: {
          1920: {
            slidesPerView: 7.3
          },
          1366: {
            spaceBetween: 15
          }
        }
      }
}

function commonLandscape(_self) {
    return {
        ...commonParams(_self),
        slidesPerView: 6.5,
        breakpoints: {
            1920: {
                slidesPerView: 6.1,
            },
            1366: {
                spaceBetween: 15
            }
        }
      }
}
export function getWebSwiperParamsByTheme(_self, section, themeType, nextSlideCB) {
    if (themeType === GRID_THEME) {
        return getWebGridThemeParams(_self, section)
    }
    if (themeType === SPOTLIGHT_THEME) {
        //Passing props in case of spotlight content, instead of section.content
        const props = section;
        let gridClassName = getSpotlightGridClassNames(props);
        let params;
        if (props.section && props.section.regularLayout == 'fullscreen') {
            params = getWebFullscreenParams(_self, props)
        } else {
            params = getWebSpotlightParams(_self)
        }
        return { gridClassName: gridClassName, params: params }
    }

    if (themeType === JUMBO_THEME) {
      return getWebJumboThemeParams(_self, section)
    }
    if (themeType === DETAIL_THEME) {
      return getWebDetailThemeParams(_self, section, nextSlideCB)
    }
    if (themeType === INLINE_MENU) {
      return getWebInlineParam(_self)
    }
    if (themeType === VIDEO_GRID_THEME_THEME) {
      return getVideoGridThemeParams(_self)
    }
}

export function getWebBannerSwiperParams(initialSlide) {
    return {
        initialSlide: initialSlide,
        slidesPerView: 1.8,
        loop: true,
        spaceBetween: 20,
        autoplay: {
          delay: 2000
        },
        loopedSlides: 3,
        mousewheelControl: true,
        mousewheelInvert: true,
        centeredSlides: true,
        mousewheelForceToAxis: true,
        breakpoints: {
          768: {
            spaceBetween: 15
          }
        },
        mousewheel: {
         forceToAxis: true,
         invert: true
       }
      }
}

export function getMobileBannerSwiperParams(initialSlide) {
  return {
    initialSlide: initialSlide,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 2000
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    mousewheelControl: true,
    mousewheelInvert: true,
    centeredSlides: true,
    mousewheelForceToAxis: true,
    mousewheel: {
      forceToAxis: true,
      invert: true
    }
  }
}


function getVideoGridThemeParams(_self) {
  return {
    ...commonParams(_self),
    slidesPerView: 'auto',
  }
}

function getWebGridThemeParams(_self, section) {
    if (section && section.thumbOrientation === "portrait") {
      return {
        ...commonPortrait(_self)
      }
    } else {
      return {
        ...commonLandscape(_self)
      }
    }
}

function getSpotlightGridClassNames(props) {
    let gridClassName;
    if (props.content && props.section && props.section.thumbOrientation === "portrait") {
        if(props.nosaveicon){
            gridClassName = "spotlight nothumbBox slider-box grid-view portrait-view content-" + props.content.identifier
        } else {
            gridClassName = "spotlight slider-box grid-view portrait-view content-" + props.content.identifier
        }
    } else {
        if(props.nosaveicon){
            gridClassName = "spotlight nothumbBox slider-box grid-view content-" + props.content.identifier
        } else {
            gridClassName = "spotlight slider-box grid-view content-" + props.content.identifier
        }
    }
    return gridClassName;
}

export const getWebSpotlightParams = (self) => {
    return {
        ...commonLandscape(self)
    }
}

function getWebFullscreenParams(self, props) {
  if (props.content && props.section && props.section.thumbOrientation === "portrait") {
      if(props.index == 0) {
          return {
              ...commonPortrait(self),
              slidesPerView: 8.8,
              breakpoints: {
                  1920: {
                      slidesPerView: 8.8,
                  },
                  1366: {
                      spaceBetween: 15
                  }
              }

          }
      } else {
          return {
              ...commonPortrait(self)
          }
      }
  } else {
      if(props.index == 0) {
          return {
              ...commonLandscape(self),
              slidesPerView: 8.2,
              breakpoints: {
                  1920: {
                      slidesPerView: 8.2,
                  },
                  1366: {
                      spaceBetween: 15
                  }
              }

          }
      } else {
          return {
              ...commonLandscape(self)
          }
      }
  }
}

function getWebJumboThemeParams(self, section) {
  if (section && section.thumbOrientation === "portrait") {
    return {
      ...commonParams(self),
      slidesPerView: 6.4,
      breakpoints: {
        1920: {
          spaceBetween: 15
        }
      },
    }
  } else {
    return {
      ...commonParams(self),
      slidesPerView: 4.1,
      breakpoints: {
        1920: {
          spaceBetween: 18
        }
      },
    }
  }
}

function getWebDetailThemeParams(_self, content, nextSlideCB) {
  const commonParams = {
    direction: 'vertical',
    resistance: false,
    keyboard: true,
    slidesPerView: 'auto',
    preloadImages: false,
    freeModeMomentum: true,
    touchReleaseOnEdges: true,
    spaceBetween: 24,
    observer: true,
    lazyLoadingOnTransitionStart: true,
    slidesOffsetAfter: 300,
    mousewheelControl: false,
    freeMode: true,
    on:{
      init: () => {
        _self.swiper =  document.querySelector('.swiper-container-vertical').swiper
      },
      slideChange: () => {
        nextSlideCB();
      }
    },
    mousewheel: {
      forceToAxis: true,
      invert: true,
    },
    height: window.innerHeight,

  }
  if(content.thumbOrientation === "portrait"){
    return {
      ...commonParams,
      breakpoints: {
        1600: {
          slidesOffsetAfter: 150,
        },
        1400: {
          spaceBetween:16,
          slidesOffsetAfter: 120,
        },
        1366: {
          slidesOffsetAfter: 50,
        },
        1280: {
          slidesOffsetAfter: 0,
        }
      }
    }
  } else {
    return {
      ...commonParams,
      breakpoints: {
        1600: {
          slidesOffsetAfter: 200,
        },
        1400: {
          spaceBetween:16,
          slidesOffsetAfter: 190,
        },
        1366: {
          slidesOffsetAfter: 100,
        },
        1280: {
          slidesOffsetAfter: 50,
        }
      }
    }
  }
}

function getWebInlineParam(_self) {
  return {
    mousewheelControl: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheelForceToAxis: true,
    mousewheelInvert: true,
    preloadImages: false,
    lazyLoading: true,
    freeMode: true,
    onInit: (swiper) => {
      _self.swiper = swiper
    },
    mousewheel: {
        forceToAxis: true,
        invert: true,
    }
  }
}