import ReactGA from 'react-ga';
import configuration from './config';
import * as common  from './common';
import { isSmartTv } from '../helperfunctions/common';

function getDimesion1ForAnalytics() {
	const platform = configuration.platform;
	if (platform === common.PLATFORM_WEB)
		return 'Web';
	if (platform === common.PLATFORM_LG)
		return 'lg_tv';
	if (platform === common.PLATFORM_SAMSUNG)
		return 'samasung_tv';
	if (platform === common.PLATFORM_HTML_SIMULATOR)
		return 'simulator';
}

export function initializeGoogleAnalytics() {
	if (isSmartTv()) {
		window.ga('create', configuration.ga_tracking_code, 'auto');
		window.ga('set', 'checkProtocolTask', null); 
		window.ga('set', 'checkStorageTask', null); 
		window.ga('set', 'historyImportTask', null); 
	} else {
		ReactGA.initialize(configuration.ga_tracking_code);
	}
}

export function setActiveUsersData(){
	if (isSmartTv()) {
		window.ga("create", configuration.ga_active_user_tracking, {name: "user_tracker"});
		window.ga('user_tracker.set', 'checkProtocolTask', null); 
		window.ga('user_tracker.set', 'checkStorageTask', null); 
		window.ga('user_tracker.set', 'historyImportTask', null); 
		window.ga("user_tracker.set", {dimension1: getDimesion1ForAnalytics()});
		window.ga("user_tracker.send", {
			hitType: "event",
			eventCategory: "MAZ Internal",
			eventAction: "App Launched",
			eventLabel: "App Launched"
		})
	} else {
		ReactGA.initialize([
			  {
				trackingId: configuration.ga_active_user_tracking,
				gaOptions: { name: 'user_tracker' },
			  }
		]);
		ReactGA.set({ dimension1: getDimesion1ForAnalytics() }, ['user_tracker']);
		ReactGA.event({
		  category: "MAZ Internal",
		   action: "App Launched",
		 label: "App Launched"
		}, ['user_tracker']);
	}

}

export function setPageviewContent(feeds, path) {
	initializeGoogleAnalytics();
    ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id }); 	
	var responseArray = getTitleArrayForContent(feeds, path);
	var screenName;
	if(responseArray[1]){
		screenName = 'Locked | '+ responseArray[0].join(' | ');
	}else{
		screenName = responseArray[0].join(' | ');
	}
	ReactGA.pageview(screenName);
}


export function setPageViewSections(feeds, path) {
	initializeGoogleAnalytics();
    ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id }); 
    var responseArray = getTitleArrayForMenus(feeds, path);
    var screenName;
	if(responseArray[1]){
		screenName = 'Locked | '+ responseArray[0].join(' | ');
	}else{
		screenName = responseArray[0].join(' | ');
	}
	ReactGA.pageview(screenName);
}

export function setPageViewFixedSections(fixedSectionName){
	initializeGoogleAnalytics();
    ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id }); 
	ReactGA.pageview(fixedSectionName);
}


export function setEvent(eventCategory, eventAction, eventLabel) {
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id }); 
	ReactGA.event({
 	 category: eventCategory,
  	 action: eventAction,
     label: eventLabel
	});
}

export function setEventForContent(feeds, path, eventCategory, eventAction, videoTitle){
	var responseArray = getTitleArrayForMenus(feeds, path);
    var screenName = responseArray[0].join(' | ');
	if(responseArray[1]){
		eventAction = eventAction + ' (locked)';
	}else{
		eventAction = eventAction + ' (unlocked)';
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id });
    if(responseArray[2]){
    	ReactGA.event({
 	 		category: eventCategory,
  	 		action: eventAction,
     		label: screenName + ' | ' + videoTitle
		});
    }else{
    	ReactGA.event({
 	 		category: eventCategory,
  	 		action: eventAction,
     		label: screenName
		});
    }
}


export function setEventForSimlive(feeds, path, eventCategory, eventAction, videoTitle, videoPlayCount){
	var responseArray = getTitleArrayForMenus(feeds, path);
    var screenName = videoPlayCount + ' | '+responseArray[0].join(' | ') + ' | ' + videoTitle;
	if(responseArray[1]){
		eventAction = eventAction + ' (locked)';
	}else{
		eventAction = eventAction + ' (unlocked)';
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
    ReactGA.set({ dimension2: configuration.app_id }); 
	ReactGA.event({
 	 category: eventCategory,
  	 action: eventAction,
     label: screenName
	});
}


export function getTitleArrayForContent(feeds, path){
	var identifierArray = path.split('/');
	var titleHierarchy = [];
	var sections = feeds.sections;
	var locked;;
 	for(var i=3; i<identifierArray.length; i++){
 		for(var j=0 ; j < sections.length; j++ ){
 			if(identifierArray[i] === sections[j].identifier ){
 				titleHierarchy.push(sections[j].title)
 				if(i === identifierArray.length-2 && sections[j].locked){
 					locked = true;
				}
				if(i === identifierArray.length-1 && sections[j].locked){
					locked = true;
				}
 				
 				if(sections[j].contents) {
					sections = sections[j].contents;
				}
				break;
 			}
 		}
  	}
  	return [titleHierarchy, locked];
}

export function getTitleArrayForMenus(feeds, path){
	var identifierArray = path.split('/');
	var titleHierarchy = [];
	var sections = feeds.sections;
	var locked;
	var mazId=false;

	for(var i=2; i<identifierArray.length; i++){
 		for(var j=0 ; j < sections.length; j++ ){
 			if(identifierArray[i] === sections[j].identifier ){
 				titleHierarchy.push(sections[j].title)
 				if(i === identifierArray.length-2 && sections[j].locked){
 					locked = true;
				}
				if(i === identifierArray.length-1 && sections[j].locked){
					locked = true;
			   	}
				if(i===identifierArray.length-2 && sections[j].mazIDSectionType){
 					mazId = true;
 				}
				if(sections[j].contents) {
					sections = sections[j].contents;
				}
				break;
 			}
 		}
  	}
  	return [titleHierarchy, locked, mazId];
}

