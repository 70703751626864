import React from 'react';
import { getTabIndex } from '../helperfunctions/common';

class ErrorDisplay extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        if(document.getElementsByClassName("user-access")[0] && document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0])
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "none";
        return (
            <div className="invalidScreen">
                <div className="invalidContent">
                    <h3>{this.props.errorHeading}</h3>
                    <p>{this.props.errorMsg}</p>
                    <a id="error_display_btn" tabIndex={getTabIndex()} href="#" onClick={(e) => {e.preventDefault(); this.props.dismiss();}} className="btn-dismiss focusable">Dismiss</a>
                </div>
            </div>
        )
    }
}

export default ErrorDisplay;