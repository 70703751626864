import React from 'react';
import Logo from '../components/Logo'
import Banner from '../components/Banner'
import LoginScreen from '../components/LoginScreen'
import UserAccess from '../components/UserAccess'
import SavedVideos from '../components/SavedVideos'
import { connect } from "react-redux"
import { userActions } from "../actions/userActions"
import { saveItem } from "../actions/itemActions"
import Background from '../components/Background'
import { saveRememberSpotBatch, getAllRememberSpot } from "../actions/rememberSpotActions"
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router'
import LoginFlow from '../components/LoginFlow';
import { checkCorrectPlatform, isSmartTv } from '../helperfunctions/common'
import { PLATFORM_LG, PLATFORM_SAMSUNG } from '../constants/common';

const divStyle = {
    height: '100%'
}

const cookies = new Cookies();

class AccountLogin extends React.Component {
    constructor(props) {
        super(props);
        this.selectEmail = this.selectEmail.bind(this);
        this.selectBack = this.selectBack.bind(this);
        this.state = {
            emailSelected: false, 
            email: '', 
            password: '', 
            show_banners: false, 
            logo: {},
            iframe_render: false
        };
    }

    componentWillMount(){
      this.setState({logo: this.props.section.logo || this.props.feeds.logo})
      var bannerId = this.props.section.bannerId
      if(bannerId !== undefined && this.props.feeds.bannerGroups !== undefined && this.props.feeds.bannerGroups[bannerId] !== undefined){
          this.setState({show_banners: true})
          this.setState({banners: this.props.feeds.bannerGroups[bannerId]})
      }
      if (isSmartTv()) {
        document.addEventListener('keyup', this.handleRemoteClick);
      }
    }

    handleRemoteClick = (e) => {
        const _self = this;
        let locales, _scroll_y;
        if(this.state.iframe_render) {
            locales = document.getElementById('myframe') && document.getElementById('myframe').contentWindow;
            _scroll_y = locales && locales.scrollY;
        }
        if(e.keyCode == '38') {
            locales && locales.scrollTo(0, _scroll_y - 20);
        } else if(e.keyCode == '40') {
            locales && locales.scrollTo(0, _scroll_y + 20);
        }
        if (e.keyCode == '10009' || e.keyCode == '461' || e.keyCode == '8') {
            if(_self.state.iframe_render) {
                _self.updateIframe("", "", false);
            }
            if (_self.state.emailSelected) {
                _self.setState({emailSelected: false});
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
      if (this.props.logged_in){
          // set cookies
        //   console.log("set cookie")
          if(checkCorrectPlatform([PLATFORM_LG])) {
            localStorage.setItem('AppUser', JSON.stringify(this.props.user));
          } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.saveData('AppUser', JSON.stringify(this.props.user), null, () => console.log("Token saved!"));
          } else {
            cookies.set('AppUser', this.props.user, { path: '/' });
          }
      }
    }

    componentWillUnmount() {
        if (isSmartTv()) {
            document.removeEventListener('keyup', this.handleRemoteClick);
        }
    }

    selectEmail(ev){
        if (isSmartTv()) {
            // It is handle in modal.js
            window.emailSelected = true;
        }
        this.setState({emailSelected: true})
        ev && ev.preventDefault()
    }
    selectBack(){
        this.setState({emailSelected: false})
    }
    updateIframe = (type, url, open_iframe = true) => {
        if (open_iframe) {window.dontOpenExit = true;}
        this.setState({
            iframe_render: open_iframe
        },() => {
            if (open_iframe) {
              if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'none';
            } else {
              if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'block';
            }  
          })
    }

    // logo can be move inside user access to avoid js display none for logo
    render(){
        const firstSaveLogo = this.props.feeds.loginUIMetadata && this.props.feeds.loginUIMetadata.firstSave && this.props.feeds.loginUIMetadata.firstSave.headerImage || (this.props.feeds.logo && this.props.feeds.logo.url);
        const registerWallLogo = this.props.feeds.loginUIMetadata && this.props.feeds.loginUIMetadata.registrationWall && this.props.feeds.loginUIMetadata.registrationWall.headerImage || (this.props.feeds.logo && this.props.feeds.logo.url);
        const headerLogo = this.props.isSavedSection ? firstSaveLogo : registerWallLogo;
        if (this.props.logged_in){
          if ((this.props.feeds.hasSavedSection || this.props.haveSavedMazIDFeed)){
                return (
                    <div style={divStyle}>
                        <Background background={this.props.background} />
                        { (this.state.logo && this.props.section.regularLayout !== "detail") && <Logo logo={this.state.logo} />}
                        <SavedVideos section={this.props.section} 
                            user={this.props.user} 
                            feeds={this.props.feeds}
                        />
                    </div>
                )}else{
                  return <Redirect to={"/apps/" + this.props.app_id+  "/settings"} />;
                }

        } else {
            return (
                <LoginFlow
                    emailSelected={this.state.emailSelected}
                    headerLogo={headerLogo}
                    divStyle={divStyle}
                    allProps={this.props}
                    selectBack={this.selectBack}
                    selectEmail={this.selectEmail}
                    runningOnMobile={this.props.runningOnMobile}
                    updateIframeInfo={this.updateIframe}
                    iframe_render={this.state.iframe_render}
                />
            )
        }
    }
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    logged_in: state.user.logged_in,
    remember: state.remember.remember,
    remember_fetched: state.remember.fetched_from_server,
    haveSavedMazIDFeed: state.items.haveSavedMazIDFeed
})

const mapDispatchToProps = {
    userActions: userActions,
    saveItem: saveItem,
    rememberSpotBatch: saveRememberSpotBatch,
    getAllRememberSpot: getAllRememberSpot,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLogin))
