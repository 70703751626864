import axios from "axios";
import * as APIS from "../constants/api";
import { getMazCoreEnvUrl } from '../constants/initializer';
import {GET_USER_HISTORY_PENDING, GET_USER_HISTORY_PROGRESS_PENDING, GET_USER_HISTORY_PROGRESS_REJECT, 
    GET_USER_HISTORY_PROGRESS_RESOLVE, GET_USER_HISTORY_REJECT, GET_USER_HISTORY_RESOLVE, HAVE_MAZ_ID_SAVE_FEED} from '../constants/actionTypes';
import config from "../constants/config"

export function getSavedItems(auth_token, items_number, offset, user_id) {
    var url = getMazCoreEnvUrl() + `/user_sync/api/v1/app_users/${user_id}/app_user_items?auth_token=` + auth_token + '&n=' + items_number + '&offset='+ offset + '&user_id='+ user_id +'&tv=true&platform=tvos'
    return function(dispatch) {
        axios.get(url)
            .then((response) => {
                dispatch({type: "RECEIVE_All_SAVED_ITEMS", payload: response.data, user_id: user_id, loader: false})
            })
    }
}

export function saveItem(auth_token, item_id, user_id, item) {
    var url = getMazCoreEnvUrl() + `/user_sync/api/v1/app_users/${user_id}/items/${item_id}/app_user_items`
    var items_number = 100
    var offset = 0
    var req_data = 'auth_token=' + auth_token + '&user_id='+ user_id +'&tv=true&platform=tvos'
    return function(dispatch) {
        dispatch({type: "RECEIVE_SAVED_ITEMS", payload: item, user_id: user_id, loader: true})
        axios.post(url, encodeURI(req_data), {headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}})
            .then((response) => {
                var url = getMazCoreEnvUrl() + `/user_sync/api/v1/app_users/${user_id}/app_user_items?auth_token=` + auth_token + '&n=' + items_number + '&offset='+ offset + '&user_id='+ user_id +'&tv=true&platform=tvos'
                axios.get(url)
                    .then((response) => {
                        dispatch({type: "RECEIVE_All_SAVED_ITEMS", payload: response.data, user_id: user_id, loader: false})
                    }).catch(err => {
                        dispatch({type: "SAVED_ITEMS_FAILED", loader: false})
                    })
            }).catch(err => {
                dispatch({type: "SAVED_ITEMS_FAILED", loader: false})
            })
    }
}

export function deleteItem(auth_token, item_id, user_id, item) {
    var url = getMazCoreEnvUrl() + `/user_sync/api/v1/app_users/${user_id}/app_user_items/delete?`+'auth_token=' + auth_token + '&user_id='+ user_id + '&item_ids=' + [item_id] +'&tv=true&platform=tvos'
    var req_data = ''
    return function(dispatch) {
        dispatch({type: "RECEIVE_DELETE_ITEMS", success: true, user_id: user_id, payload: item})
        axios.post(url, encodeURI(req_data), {headers:{'Content-Type': 'application/x-www-form-urlencoded'}})
            .then((response) => {
                //
            })
    }
}

export function clearSavedItems() {
    return function(dispatch){
        dispatch({type: "CLEAR_SAVED_ITEMS", success: false, user_id: 0, payload: {content: []}, loader: true})
    }
}

export function getUSerFeedHistoryOrProgress(apiParams) {
    return function(dispatch, getState) {
      // const state = getState();
        apiParams.filterType == 'history' ? 
            dispatch({type: GET_USER_HISTORY_PENDING}) :
            dispatch({type: GET_USER_HISTORY_PROGRESS_PENDING});
    
        axios.get(`${APIS.USER_INFO_API}${apiParams.user_id}/item_views/history_progress`, {
            params: {
                auth_token: apiParams.auth_token,
                platform: config.platform,
                n: 100,
                user_id: apiParams.user_id,
                filter_type: apiParams.filterType,
                tv: true
            },
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            if(data.data.success) {
                apiParams.filterType == 'history' ? 
                    dispatch({type: GET_USER_HISTORY_RESOLVE, payload: data.data}) :
                    dispatch({type: GET_USER_HISTORY_PROGRESS_RESOLVE, payload: data.data});
            }
        })
        .catch(err => {
            apiParams.filterType == 'history' ? 
                dispatch({type: GET_USER_HISTORY_REJECT, payload: err}) :
                dispatch({type: GET_USER_HISTORY_PROGRESS_REJECT, payload: err});
        })
    }
}

export function haveSavedMAZFeed() {
    return (dispatch) => {
        dispatch({type: HAVE_MAZ_ID_SAVE_FEED})
    }
}