import React from 'react';
import { connect } from "react-redux"

import { userActions, getUserConcent } from "../actions/userActions"
import LoginScreen from '../components/LoginScreen'
import UserAccess from '../components/UserAccess'
import Background from '../components/Background'
import Logo from '../components/Logo'
import DataAndPrivacy from './DataAndPrivacy';
import { getGdprParamsForApi } from '../helperfunctions/feedHelper';
import { checkIfSingleOption, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { closeModal } from '../actions/modalActions';

class LoginFlow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginRegistrationFlag: false,
            loginData: {},
            openFacebookTab: false,
            isWebsiteLogin: false,
            openWebsiteLogin: false,
            iframe_render: false
        }
        this.iframe_url = "";
    }

    componentWillUnmount() {
        if(this.props.updateEmailSelected) {
            this.props.updateEmailSelected(false);
        }
    }

    onAPIDataReady(loginData, fbData, isWebsiteLogin) {
        this.setState({loginRegistrationFlag: true, loginData: loginData, fbData: fbData, isWebsiteLogin: isWebsiteLogin, openWebsiteLogin: false},
            () => {
                //If user already gave concent and login type is website, then do continue action.
                if (this.props.userConcent && isWebsiteLogin) {
                    this.onContinueDataPrivacy();
                }
            })
    }

    cancelDataPrivacy = () => {
        if (isSmartTv()) {
            const { allProps } = this.props;
            const {screen, directLogin, feeds} = allProps;
            if (checkIfSingleOption(this.getScreenType(screen), feeds) && directLogin) {
                this.props.closeModal();
            } else {
                this.setState({loginRegistrationFlag: false});
            }
        }
        // console.log("cancelDataPrivacy")
    }

    getScreenType = (loginscreen=null) => {
        let screen = "firstSave"
        if (loginscreen === "registerWall"){
          screen = "registrationWall"
        }
        return screen;
    }

    onContinueDataPrivacy() {
        let changeState = {loginRegistrationFlag: false}
        if(this.state.loginData) {
            const {email, password, app_id, button_clicked} = this.state.loginData;
            this.props.userActions(email, password, app_id, button_clicked, getGdprParamsForApi(this.props.allProps.feeds));
        } else if(this.state.fbData) {
            changeState.openFacebookTab = true
        }
        else {
            changeState.openWebsiteLogin = true
        }
        this.setState({...this.state, ...changeState});
        this.props.getUserConcent();    
    }

    checkUserConcent(feeds) {
        if(this.props.userConcent) return true;// concent taken already
        if(!feeds.gdpr || Object.keys(feeds.gdpr) < 1) return true; // gdpr not in feed
        return false;
    }

    handleTermsPolicyClick = (type, url) => {
        this.iframe_url = url;
        this.props.updateIframeInfo && this.props.updateIframeInfo(type,url);
    }

    renderTermsAndPrivacyPolicy = (feeds) => {
        if (isSmartTv()) {
            return (
                <div className="termsOfUse">
                    <ul className="mob_info">
                    <li><a onClick={() => this.handleTermsPolicyClick('terms',feeds.loginUIMetadata.urls.terms)} className="text-white focusable" tabIndex={getTabIndex()}>Terms of Use</a></li>
                    <li className="termsVertical">|</li>
                    <li><a onClick={() => this.handleTermsPolicyClick('policy',feeds.loginUIMetadata.urls.policy)} className="text-white focusable" tabIndex={getTabIndex()}>Privacy Policy</a></li></ul>
                </div>
            )
        } else {
            return (
                <div className="termsOfUse">
                    <ul className="mob_info">
                    <li><a href={feeds.loginUIMetadata.urls.terms} target = "_blank" className="text-white focusable" tabIndex={getTabIndex()}>Terms of Use</a></li>
                    <li className="termsVertical">|</li>
                    <li><a href={feeds.loginUIMetadata.urls.policy} target = "_blank" className="text-white focusable" tabIndex={getTabIndex()}>Privacy Policy</a></li></ul>
                </div>
            )
        }
    }

    cancelFbLogin = () => {
        if (isSmartTv) {
            this.setState({openFacebookTab: false})
        }
    }

    renderLoginOptions() {
        const { allProps, headerLogo, selectEmail, modalLogin} = this.props;
        const { background, app_id, feeds, isSavedSection, screen, titleMsg, directLogin } = allProps;
        return (
            <div className={"userLoginCntnt" + (!modalLogin ? " queueLogin" : "")}>
                {background && <Background background={background} />}
                <Logo logo={headerLogo} />
                <LoginScreen 
                    openFacebookTab={this.state.openFacebookTab}
                    onClick={selectEmail}
                    app_id={app_id}
                    feeds={feeds}
                    isSavedSection={isSavedSection}
                    screen={screen || null}
                    titleMsg={titleMsg || null}
                    userConcent={this.checkUserConcent(feeds)}
                    onAPIDataReady={(fbData, isWebsiteLogin) => this.onAPIDataReady(null, fbData, isWebsiteLogin)}
                    openWebsiteLogin={this.state.openWebsiteLogin}
                    directLogin={directLogin}
                    cancelFbLogin = {this.cancelFbLogin}
                />
            </div>
        )
    }

    renderUserAccess() {
        const { allProps, headerLogo, modalLogin, selectBack} = this.props;
        const { background, app_id, feeds, screen, titleMsg, logged_in } = allProps;
        const {email, password} = this.state.loginData || {};
        return (
            <div className={"userLoginCntnt" + (!modalLogin ? " queueLogin" : "")}>
                {background && <Background background={background} />}
                <Logo logo={headerLogo} />
                <UserAccess 
                    email={email}
                    password={password}
                    logged_in={logged_in}
                    selectBack={selectBack} 
                    app_id={app_id}
                    feeds={feeds}
                    modalLogin={modalLogin} 
                    userConcent={this.checkUserConcent(feeds)}
                    screen={screen || null}
                    titleMsg={titleMsg || null}
                    onAPIDataReady={(loginData) => this.onAPIDataReady(loginData)} 
                />
            </div>
        )
    }

    renderIframe = () => {
        return(
            <iframe id="myframe" src={this.iframe_url} width="100%" height="100%" allow>
            </iframe>
        );
    }

    render() {
        const { emailSelected, allProps, divStyle, modalLogin, iframe_render} = this.props;
        const { feeds } = allProps;
        if(iframe_render) {
            return this.renderIframe();
        }
        if(this.state.loginRegistrationFlag && !this.props.userConcent && feeds.gdpr) {
            return <DataAndPrivacy 
                onContinue={() => this.onContinueDataPrivacy()} 
                onCancel={() => this.cancelDataPrivacy()} 
                feeds={feeds} 
                runningOnMobile={this.props.runningOnMobile} 
                updateIframeInfo={this.handleTermsPolicyClick}
            />
        } else if(emailSelected) {
            if(modalLogin) {
                return this.renderUserAccess();
            } else {
                return (
                    <div className="user-access overflow-scroll-auto" style={divStyle}>
                        {this.renderUserAccess()}
                    </div>
                )
            }
        } else {
            if(modalLogin) {
                return (
                    <>
                        {this.renderLoginOptions()}
                        {this.renderTermsAndPrivacyPolicy(feeds)}
                    </>
                )
            } else {
                return (
                    <div className="user-access overflow-scroll-auto" style={divStyle}>
                        <>
                            {this.renderLoginOptions()}
                            {this.renderTermsAndPrivacyPolicy(feeds)}
                        </>
                    </div>
                )
            }
            
        }
    }
}

const mapStateToProps = (state) => ({
    userConcent: state.user.userConcent
})

const mapDispatchToProps = {
    userActions: userActions,
    getUserConcent: getUserConcent,
    closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlow)