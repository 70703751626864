import React from 'react';
import Swiper from 'react-id-swiper';
import { deepLinkUrl, httpsUrl, getSaveFeedSectionIdentifier, PLATFORM_HTML_SIMULATOR } from '../constants/common'
import { connect } from "react-redux"
import { openModal } from "../actions/modalActions"
import { Redirect } from 'react-router'
import * as analytics  from '../constants/analytics';
import configuration from '../constants/config'
import { checkCorrectPlatform, checkIfToDoNothing } from '../helperfunctions/common';
import { Focusable } from 'react-js-spatial-navigation';
import TvFocusable from '../components/TvFocusable';
import { getBannerSwiperParams } from '../helperfunctions';
import { isSmartTv } from '../helperfunctions/common';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

/**
 * check, if to show banner
 */
function checkBannerSegmentation(bannerId, feeds, isLoggedIn, isSubscribed) {
  bannerId = bannerId.toString();
  const bannersSeg = feeds.bannerGroupSegmentation;
  if (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) return true;
  if (!bannersSeg) return false;
  // Check if banner id present in all section
  if (bannersSeg.all && bannersSeg.all.web_as_output && bannersSeg.all.web_as_output.includes(bannerId)) 
    return true;
  // Check if banner id present in Logged in section
  if (isLoggedIn && bannersSeg.logged_in && bannersSeg.logged_in.web_as_output && bannersSeg.logged_in.web_as_output.includes(bannerId)) 
    return true;
  // Check if banner id present in Logged out section
  if (!isLoggedIn && bannersSeg.logged_out && bannersSeg.logged_out.web_as_output && bannersSeg.logged_out.web_as_output.includes(bannerId)) 
    return true;
  // Check if banner id present in Subscription section
  if (isSubscribed && bannersSeg.sub && bannersSeg.sub.web_as_output && bannersSeg.sub.web_as_output.includes(bannerId))
    return true;
  // Check if banner id present in Non Subscription section
  if (!isSubscribed && bannersSeg.non_sub && bannersSeg.non_sub.web_as_output && bannersSeg.non_sub.web_as_output.includes(bannerId))
    return true;
  return false;
}
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = null;
  }

  state = {
   redirect: false
  }

  componentDidMount(){
    //analytics.setEvent('Banners', 'Banner View', banner.name)
    const bannerContainer = document.querySelector('.banner > .swiper-container');
    this.swiper = bannerContainer && document.querySelector('.banner > .swiper-container').swiper;
    if (isSmartTv()) {
			document.addEventListener('keyup',this.handleRemoteClick);
		}
  }

  componentWillUnmount() {
    document.removeEventListener('keyup',this.handleRemoteClick);
  }

  onLinkClick(event,overlay,actionUrl,path, name) {
    analytics.setEvent('Banners', 'Banner Action', name)
    if (checkIfToDoNothing(actionUrl, this.props.feeds)) {
      event.preventDefault();
      return null;
    }
    if (actionUrl.includes("/registerwall/")) {
      this.props.openModal({type: "login", screen: "registerWall"})
    } else if (actionUrl.includes("/register/")) {
      this.props.openModal({type: "login", screen: "firstSave"})
    } else {
      this.setState({
        redirect: true,
        redirectPath: path
      })
    }
    event.preventDefault()
  }

  sendAnalytics(name){
    analytics.setEvent('Banners', 'Banner Action', name)
  }

  clickEnter = (e) => {
    e.srcElement.childNodes[0].click();
  }

  handleRemoteClick = (e) => {
		let bannerParent = document.querySelector('.banner');

		if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
			if (bannerParent && bannerParent.contains(document.activeElement)) {
				const nearestContainer = document.activeElement.closest(".swiper-container");
				let currIndex;
				nearestContainer.querySelectorAll(".swiper-wrapper > div").forEach((ele, index) => {
					if (ele.contains(document.activeElement)) {
						currIndex = index;
            nearestContainer.swiper && nearestContainer.swiper.slideTo(currIndex)
					}
				})
			}
    }
	}

  /**
   * In case of SmartTv, when banner slide change, move the focus to active slide. If current focus in banner element.
   */
  onSlideChange = () => {
    let bannerParent = document.querySelector('.banner');
    if (isSmartTv() && this.swiper && bannerParent.contains(document.activeElement)) {
      let activeSlide = this.swiper.activeIndex;
      const activeParent = document.querySelectorAll('.banner .swiper-wrapper > div')[activeSlide];
      if (!activeParent.contains(document.activeElement)) {
        let focusableEle = activeParent.querySelector('.focusable');
        JsSpatialNavigation.focus(focusableEle, false);
      }
      elementScrollIntoView(document.activeElement, {behavior: 'smooth', block: 'center', inline: 'center'});
      this.swiper && this.swiper.autoplay.start();
    }
  }

  render() {
    const onSlideChangeCB = this.onSlideChange;
    const self = this;
    if (this.state.redirect){
      return <Redirect to={this.state.redirectPath}/>;
    }

    if (!checkBannerSegmentation(this.props.bannerId, this.props.feeds, this.props.isLoggedIn, this.props.isSubscribed)) {
      return null;
    }
    var allSlides = []
    for(var i=0;i<this.props.banners.length;i++){
      allSlides.push(this.props.banners[i])
    }
    if(!this.props.runningOnMobile && allSlides.length < 3) {
      // In case of web & simulator less number of banners would leave empty space. To avoid that increasing the numbers
      allSlides = allSlides.concat(allSlides);
    }
    const initialSlide = Math.floor(allSlides.length / 2)
    const params = getBannerSwiperParams(initialSlide, this.props.runningOnMobile);
    const saveId = getSaveFeedSectionIdentifier(this.props.feeds);
    let swiperParams = params;
    let class_name = 'focusable';

    if (isSmartTv()) {
      swiperParams = {
        ...swiperParams,
        on: {
          slideChangeTransitionEnd: () => {
            onSlideChangeCB();
          }
        }
      }

      class_name += ' d-block';
    }
    return (
      <div className="slider-box banner">
        <Swiper {...swiperParams}>
          {allSlides.map(function(banner, i) {
            if(banner.actionUrl){
              if(self.props.runningOnMobile && (
                banner.actionUrl.includes("save") || ( saveId !== "" && banner.actionUrl.includes(saveId))
              )) {
                // Not rendering save banner in case of mobile
                return null;
              }
              var deeplink = deepLinkUrl(banner.actionUrl, self.props.app_id, self.props.feeds)
              return (
                <div key={i}>
                  {/* <TvFocusable
                    clickEnter = { this.clickEnter }
                  > */}
                    <a id={`banr_${i}`} href="#" className={ class_name } tabIndex="-1" onClick={(event,overlay,actionUrl,path) => self.onLinkClick(event,deeplink[1], banner.actionUrl, deeplink[0], banner.name)}>
                      <img className="img-banner" src={httpsUrl(banner.url)}/>
                    </a>
                  {/* </TvFocusable> */}
                </div>
              )
            } else {
              //analytics.setEvent('Banners', 'Banner Action', banner.name)
              return (
                // <TvFocusable
                //   clickEnter = { this.clickEnter }
                // >
                  <div key={i}>
                    <a  id={`banr_${i}`} href="#" className={ class_name } tabIndex="-1" onClick={() => self.sendAnalytics(banner.name)}>
                      <img className="img-banner" src={httpsUrl(banner.url)}/>
                    </a>
                  </div>
                // </TvFocusable>
              )
            }
          }, this)}
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.logged_in,
    isSubscribed: state.user.subscription
  }
}

const mapDispatchToProps = {
  openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
