export default function reducer(
  state = {
    feeds: {
      text: 1
    }, app_id: 0, background: ''}, action) {

  switch (action.type) {
    case "RECEIVE_FEEDS": {
      var sections = action.payload.sections
      if(sections){
        var defaultSection = sections[0]
        for(var i=0; i<sections.length; i++){
          if(sections[i].isDefault){
            defaultSection = sections[i];
            break;
          }
        }
        return {
          ...state, feeds: action.payload, app_id: action.app_id, background: defaultSection.background,
        }
      } else {
        return {
          ...state, feeds: action.payload, app_id: action.app_id, background: sections,
        }
      }
    }
  }

  return state
}