import React, { Component } from 'react';
import './css/tvx-fonts.css';
import './css/tvx-default.css';
import './css/swiper.min.css';
import './css/dev.css';
import './css/tvx-large.css';
import './css/desktop.css';
import './css/style.css';
import './css/responsive.css';
import './css/webStyle.css';
import './css/webResponsiveStyle.css';
import './css/ctv.css';
import Layout from "./containers/Layout";
import { Provider } from "react-redux";
import store from "./store";
import config from './constants/config';
import SpatialNavigation from 'react-js-spatial-navigation';
import { isSmartTv } from './helperfunctions/common';

class App extends Component {
  componentWillMount(){
  }

  render() {
    if (!config.is_simulator){
      if (config.title){
      document.title = config.title
      }
      let favicon = document.getElementById("favicon");
      if (config.favicon){
        favicon.href = config.favicon;
      }else{favicon.href = "favicon.ico";}
    }
    return (
      <Provider store={store}>
        {
          isSmartTv()
          ? (<SpatialNavigation>
            <Layout/>
          </SpatialNavigation>)
          : (<Layout/>)
        }
      </Provider>
    );
  }
}

export default App;
