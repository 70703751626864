import React from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'

import { localRememberSpotBatch } from '../actions/rememberSpotActions'
import { closeModal, modalProgress,openModal } from '../actions/modalActions'
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, DEEPLINK, INLINE_MENU_IDLE_TIMEOUT } from "../constants/common"
import { updateVideoCount } from "../actions/adsActions"
import { environment } from "../constants/initializer"
import isAvailable from '../helperfunctions/is_available'
import InlineMenu from "./InlineMenu";
import * as analytics  from '../constants/analytics';
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { checkIfSafari, getImageUrlBaseOnSize, onVideoError, getTabIndex } from '../helperfunctions/common';
import { backButtonObj, getParentFeed } from '../helperfunctions'
import { isSmartTv } from '../helperfunctions/common';
import { showAdsAsPerFrequency, runAdInVideo, constructSpotxParams } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";

import 'videojs-contrib-ads';
import 'videojs-ima';
var videojs = require('video.js');

let hideMenuTimeout = null;
let current_player,current_src,current_type;

function videoType(url){
  url = url.split('.')
  if (url[url.length - 1].split('?')[0] == "m3u8"){
    return "application/x-mpegURL"
  } else {
    return "video/mp4"
  }
}

function NextVideoThumbnail(){
  var spotxIframe = getSpotxIframe();
  if(!spotxIframe){
    if(document.getElementById("thumb-video_html5_api")){
      var video = document.getElementById("thumb-video_html5_api");
    } else {
      var video = document.getElementById("thumb-video");
    }
    if(video && (Math.ceil(video.duration - video.currentTime) < 11)){
      if (document.getElementById("next-video")) {
        document.getElementById("next-video").style.display = "inline"
        // upnext and title does not appear together, when upNext ui is coming
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "none";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "none";
      }
      if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}    
      if (document.getElementsByClassName("nextVideoSeconds")[0]) document.getElementsByClassName("nextVideoSeconds")[0].innerHTML = Math.ceil(video.duration - video.currentTime)
    } else {
      if (document.getElementById("next-video") && document.getElementById("next-video").style.display === "inline" ){
        document.getElementById("next-video").style.display = "none";
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "block";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "block";
        if (document.getElementById("video-inlineMenu")) {
          document.getElementById("video-inlineMenu").style.display = "inherit";
          hideMenuTimeout = setTimeout(() => {
            if (document.getElementById("video-inlineMenu")){
              document.getElementById("video-inlineMenu").style.display = "none" ;
            }
          }, INLINE_MENU_IDLE_TIMEOUT)
        }    
      }
    }
  }
}

function get_parent_section(section_id,contents){
  var sections = this.props.feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  if(isNaN(pathLocation[0])){return "settings"}
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        sections = sections[j].contents
      }
    }
  }
  if(content.regularLayout === "detail" || pathLocation.length < 3){
    pathLocation = pathLocation.slice(0,-1)
  } else {
    pathLocation = pathLocation.slice(0,-2)
  }
  pathLocation = pathLocation.join("/")
  return pathLocation
}

function showVideoAd(state, props){
  var adToShow = props.ads.spotxid.length > 0;
  const feedAd = isAdForFeed(props.modal, state.section);
  adToShow = adToShow && feedAd && window.SpotX
  var showAd = false
  if(adToShow){
    showAd = showAdsAsPerFrequency(props.ads, state.video_count);
  }

  if(showAd){
    analytics.setEvent('Ads', 'Video Ad Play', 'SpotX')
  }
  // return false;
  return showAd
}

function isAdForFeed(modal, section) {
  if(section.mazContentType === "saved") return false; // saved section in mazId feed
  if(modal.head === "saved_items") return false; // saved section
  return (modal.show_ad || section.showAd);
}

function PlayVideo(url, type, next_section, props, state, showAd, _this){
  if(showAd && !isSmartTv()){
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var adContainer = document.getElementsByClassName('videoFull')[0];
    const cust_params = constructSpotxParams(props.feeds, state.section);
    if(document.getElementById("thumb-video_html5_api")){
      adContainer = document.getElementById("thumb-video");
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video_html5_api"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    } else {
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    }
  } else {
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    const vTime = props.remember[state.section.identifier];
    let videoParams = { loadingSpinner: false };
    if(props.runningOnMobile) {
      videoParams.controls = true;
    }
    const player = videojs('thumb-video', videoParams);
    current_player = player;
    current_src = url;
    current_type = type;
    player.on('error', function() {
      onVideoError(type);
    });
    player.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(props.runningOnMobile) {
        _this.setNextVideo(state.next_index, true)
      }
    });
    player.ready(() => {
      player.src({src: url, type: type})
      const feedAd = isAdForFeed(props.modal, state.section);
      runAdInVideo(player, props, state, _this, feedAd, "thumb-video_html5_api", state.section);
      player.play()
      var newVideo = document.getElementById("thumb-video_html5_api")
      newVideo.muted = false
      if(!props.runningOnMobile) {
        newVideo.controls = true
      }
      newVideo.controlsList = "nodownload"
      newVideo.addEventListener("loadedmetadata", function() {
        if(document.getElementById("loader")) {
          document.getElementById("loader").style.display = "none";
        }
        if (vTime && (vTime["p"] != vTime["t"])) {
          this.currentTime = vTime["p"];
        }
      }, {once: true})
      if (next_section) {
        newVideo.addEventListener("timeupdate", NextVideoThumbnail, false)
      } else {
        newVideo.removeEventListener("timeupdate", NextVideoThumbnail, false)
      }
    })
  }
}

function NextVideo(all_contents, index){
  var content;
  var nextIndex;
  for(var i=index+1; i<all_contents.length; i++){
    if(all_contents[i].type === "vid") {
      content = all_contents[i]
      nextIndex = i
      break;
    }
  }
  return [content, nextIndex]
}

function ChangeRoute(identifier){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,-1)
  currentLocation.push(identifier)
  currentLocation = currentLocation.join("/")
  return currentLocation
}

function FindVideo(identifier, all_contents){
  var videoIndex = 0;
  for(var i=0; i<all_contents.length; i++){
    if(identifier === all_contents[i].identifier){
      videoIndex = i;
      break;
    }
  }
  return videoIndex;
}

//Function to toggle fullscreen on dbl click (Safari only, Not implemented yet), Just call this function on video double click
function toggleFullscreen(video) {
  if (checkIfSafari()) {
    if (document.webkitIsFullScreen) {
      document.webkitExitFullscreen();
    } else {
      video.webkitRequestFullscreen && video.webkitRequestFullscreen();
    }
  }
}

function onVideoPauseCheck() {
  const videoObj = document.getElementsByTagName('video')[0];
  if (videoObj && !videoObj.controls) {
    videoObj.controls = true;
    if(videoObj.play) videoObj.play();
    videoObj.removeEventListener('pause', onVideoPauseCheck)
  }
}
class OverlayVideo extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      all_contents: "",
      index: 0,
      type: "",
      section: '',
      next_section: '',
      next_index: 0,
      next_video: false,
      currentView: '',
      rememberSpotBatch: {},
      lineage: "",
      video_count: 0,
      video_title: this.props.modal.content.title,
      purchased: false,
      playing_Ad: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      showTitle: false,
      showMetering: false
    }
    this.maintainTitle = this.maintainTitle.bind(this)
    this.updateState = this.updateState.bind(this)
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
  }

  updateState(){
    if (this.props.modal.deepLink !== DEEPLINK && !isSmartTv()) {
      const sectionItem = document.getElementsByClassName("item-" + this.state.section.identifier)[0]
      sectionItem && sectionItem.classList.add("slideHover")
    }
    document.body.classList.remove("noNav")
    var video = document.getElementById("thumb-video");

    var spotxIframe = getSpotxIframe();

    if(spotxIframe) {
      removeSpotxIframe();
    } else {
      this.saveVideoTimeInRemeberSpot(video);
      if(video) videojs(video).dispose();
    }
  }

  saveVideoTimeInRemeberSpot = (videoEle) => {
    var rememberSpotBatch = {...this.state.rememberSpotBatch}
      rememberSpotBatch[this.state.section.identifier] = {
        p: videojs(videoEle).currentTime(),
        t: videojs(videoEle).duration(),
        saved: false,
        type: this.state.section.type
      }
      const {t, p} = rememberSpotBatch[this.state.section.identifier];
      if (!isNaN(t) && !isNaN(p)) {
        this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
          this.props.user.user_id)
      }
  }

  onLinkCLick(e) {
    this.updateState()
    this.props.closeModal()
    this.props.updateVideoCount(this.state.video_count + 1)

    if (this.props.modal.deepLink === DEEPLINK) {
      const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
      this.props.modal.callbackParent(this.state.section.identifier);
    } else {
      window.history.replaceState("view", {}, this.props.modal.pathname)
    }

    e && e.preventDefault()
  }

  componentWillMount(){
    document.body.classList.add("noNav")
    var videoIndex = FindVideo(this.props.section.identifier, this.props.all_contents);
    let section = this.props.section;
    if(this.props.modal.head === "search"){
      section = this.addParentFeedDataForSearch(section);
    }
    if (section.mazContentType && section.lineage) {
      section = this.getSectionParentFromLineage(section)
    }
    this.setState({all_contents: this.props.all_contents})
    var type = videoType(section.contentUrl)
    var nextVideoData = NextVideo(this.props.all_contents, videoIndex)
    this.setState({
      type: type,
      section: section,
      index: videoIndex,
      next_section: nextVideoData[0],
      next_index: nextVideoData[1]
    })
  }

  handleRemoteClick = (e) => {
    if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
      return;
    }

    const player = document.getElementById("thumb-video_html5_api");
    let current_time;
    
    switch(parseInt(e.keyCode)) {
      case 13:
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;
      
      case 415: //media play
        if(player && player.paused) {
          player.play();
        }
      break;

      case 19: //media pause
        if(player && !player.paused) {
          player.pause();
        }
      break;

      case 10252: //media playpause
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;

      case 37: // left
        current_time = player.currentTime;
        player.currentTime = current_time - 5;
      break;
      
      case 39: // right
        current_time = player.currentTime;
        player.currentTime = current_time + 5;
      break;

      case 417: //forward
        current_time = player.currentTime;
        player.currentTime = current_time + 5;
      break;

      case 412: // backward
        current_time = player.currentTime;
        player.currentTime = current_time - 5;
      break;

      case 413: // stop video
        if(player && !player.paused) {
          player.pause();
          player.currentTime = 0;
        }
      break;
    }
  }

  componentDidMount(){
    this.setVideoAnalytics();
    window.history.pushState("new video", {}, this.props.modal.firstpath)
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: true})
    const video = document.getElementById("thumb-video");
    if(video) {
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true})
      }
    }
    document.body.addEventListener("mousemove", this.cancelHideMenuTimeout, true);

    if(document.getElementById("thumb-video")) {
      document.getElementById("thumb-video").focus();
    }
    if(isSmartTv()) {
      if(document.getElementById('video-inlineMenu')) {
        document.getElementById('video-inlineMenu').focus();
      }

      if(document.getElementById('thumb-video_html5_api')) {
        document.getElementById('thumb-video_html5_api').focus();
      }
      document.addEventListener('keyup', this.handleRemoteClick);
    }
  }

  onAdReady() {
    const adCont = document.getElementById("thumb-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  onAdStart(ev) {
    this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
  }

  updateSrcInSmartTv(_type) {
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }

    if (podInfo.podPosition == podInfo.podLength) { 
      current_player.src({ src: current_src, type: current_type });
      current_player.ready(() => current_player.play());
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);
    }
    this.setState({playing_Ad: false})
    this.maintainTitle();
  }
  
  onAllAdComplete() {    
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false});
    if(this.state.videoInQueue >= 0) {
      this.setNextVideo(this.state.videoInQueue);
    }
  }

  cancelHideMenuTimeout() {
    if (hideMenuTimeout) {
      clearTimeout(hideMenuTimeout);
      hideMenuTimeout = null;
    }
  }

  getSectionParentFromLineage = (section) => {
    const url = `${section.lineage}/${section.identifier}`;
    let parent = getParentFeed(this.props.feeds, url)
    const {locked, access, special, displayTitle, registerWall, identifier, showVideoAd} = parent;
    return {
      ...section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      showAd: showVideoAd
    }
  }


  maintainTitle(){
    let displayTitle = this.props.modal.content.displayTitle;
    if (["saved_items", "search"].includes(this.props.modal.head)){
      displayTitle = this.props.feeds.displayTitle;
    }
    let metering_type = null;
    const METERING_TYPE = getMeteringTypes();
    if ((!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) && 
    this.state.section.locked)) {
      if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) {
        metering_type = METERING_TYPE.VIEWS
      } else if (this.state.section.access && this.state.section.access.timed !== undefined) {
        metering_type = METERING_TYPE.TIME
      }
    }

    this.handleMetering(false);
    if (displayTitle && displayTitle.type === "duration"){
      this.handleTitle(true)
      var dTime = displayTitle.value*1000
      setTimeout(() => {
        this.handleTitle(false);
        this.handleMetering(true);
        if (metering_type === METERING_TYPE.VIEWS) {
          setTimeout(() => {
            this.handleMetering(false);
          }, dTime)
        }
      }, dTime)
    } else if(displayTitle && displayTitle.type == "full"){
      this.handleTitle(true)
      if(metering_type) {
        // if metering hide title after 5 seconds
        setTimeout(() => {
          this.handleTitle(false);
          this.handleMetering(true);
          if (metering_type === METERING_TYPE.VIEWS) {
            // if view metering hide metering after 5 seconds
            setTimeout(() => {
              this.handleMetering(false);
              this.handleTitle(true);
            }, 5000)
          }
        }, 5000)
      }
    } else {
      this.handleTitle(false)
      this.handleMetering(true);
      if (metering_type === METERING_TYPE.VIEWS) {
        setTimeout(() => {
          this.handleMetering(false);
        }, 5000)
      }
    }
  }

  handleMetering(show) {
   this.setState({showMetering: show})
  }

  handleTitle(show){
    this.setState({showTitle: show})
  }


  setVideoAnalytics(videoAutoplay, section=null, videoTitle=null, path=null){
    if (section === null) {
      section = this.state.section
    }
    if (videoTitle === null) {
      videoTitle = this.state.video_title
    }
    if (path === null) {
      path = this.props.modal.firstpath
    }
    if(videoAutoplay){
      if(path.includes("search") || path.includes("saved_items")){
        if(this.props.modal.head === "search"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Autoplay (search - locked)', videoTitle)   
          }else{
            analytics.setEvent('Video', 'Video Autoplay (search - unlocked)', videoTitle)   
          }
        }
        else if(this.props.modal.head === "saved_items"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Autoplay (saved - locked)', videoTitle)   
          }else{
            analytics.setEvent('Video', 'Video Autoplay (saved - unlocked)', videoTitle)   
          }
        }
      }
      else{
        analytics.setEventForContent(this.props.feeds, path, 'Video', 'Video Autoplay', videoTitle)
      }
    }else{
      if(path.includes("search") || path.includes("saved_items")){
        if(this.props.modal.head === "search"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Play (search - locked)', videoTitle)   
          }else{
            analytics.setEvent('Video', 'Video Play (search - unlocked)', videoTitle)   
          }
        }
        else if(this.props.modal.head === "saved_items"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Play (saved - locked)', videoTitle)   
          }else{
            analytics.setEvent('Video', 'Video Play (saved - unlocked)', videoTitle)   
          }
        }
      }
      else{
        analytics.setEventForContent(this.props.feeds, path, 'Video', 'Video Play', videoTitle)
      }

    }
  }

  componentDidUpdate(prevProps, prevState){
    //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: false})
    const video = document.getElementById("thumb-video");
    if(prevState.index !== this.state.index && video) {
      // checking if video changed and video tag hasn't been disposed
      //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
      //this.setVideoAnalytics();
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true, showTitle: false, showMetering: false})
      }
    }
  }

  playOriginalVideo(){
    this.setState({playing_Ad: false})
    this.maintainTitle()
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var rememberSpotId = this.props.remember[this.state.section.identifier]
    var next_section = this.state.section
    var url = httpsUrl(this.state.section.contentUrl)
    let videoParams = { loadingSpinner: false };
    if(this.props.runningOnMobile) {
      videoParams.controls = true;
      videoParams.muted = false;
    }
    this.video = videojs("thumb-video", videoParams);
    const _video = this.video;
    const _videoType = videoType(this.state.section.contentUrl);
    _video.on('error', function() {
      onVideoError(_videoType);
    });
    _video.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(this.props.runningOnMobile) {
        this.setNextVideo(this.state.next_index, true)
      }
    });
    this.video.ready(() => {
      this.video.src({src: url, type: videoType(this.state.section.contentUrl)});
      this.video.play()
      var newVideo = document.getElementById("thumb-video_html5_api")
      newVideo.muted = false
      if(!this.props.runningOnMobile) {
        newVideo.controls = true
        // Below line is for firefox bug (https://github.com/MagAppZine/MagAppZine-MagControl/issues/7107)
        // Video is getting paused and controls off, because of spotx ADOS control the video player.
        newVideo.addEventListener('pause', onVideoPauseCheck)
      }
      newVideo.addEventListener("loadedmetadata", () => {
        if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
        if(rememberSpotId &&
          (rememberSpotId["p"] !== rememberSpotId["t"])){
          newVideo.currentTime = rememberSpotId["p"]
        }
      }, {once: true})
      if(next_section){
        newVideo.addEventListener("timeupdate", NextVideoThumbnail, false)
      } else {
        newVideo.removeEventListener("timeupdate", NextVideoThumbnail, false)
      }
    });
  }

  componentWillUnmount(){
    document.body.removeEventListener("mousemove", this.cancelHideMenuTimeout, true);
    var video = document.getElementById("thumb-video");
    if (video)
      videojs(video).dispose();

    if(isSmartTv()) document.removeEventListener('keyup', this.handleRemoteClick);
  }

  addParentFeedDataForSearch(section) {
    const {locked, access, special, displayTitle, registerWall, identifier} = section.parentMenu;
    let newSection = {
      ...section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      inlineMenu: this.props.feeds.inlineMenu || "" // inline menu in search as set at app level
    }
    let moreData = getParentFeed(this.props.feeds, (section.lineage + "/" + section.identifier));
    newSection.showAd = moreData.showVideoAd;
    return newSection;
  }

  setNextVideo(index, prevVideoEnded){
    if(this.state.adWrapperExist && prevVideoEnded && isSmartTv()) return;

    document.getElementById("loader").style.display = "block";
    if(prevVideoEnded && this.state.adWrapperExist) {
      //ad will play, don't run next video for now
      this.setState({videoInQueue: index});
      return;
    }
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      setTimeout(() => {
        removeSpotxIframe();
        if(document.getElementById("thumb-video")) {
          this.playOriginalVideo()
        };
      }, 100)
    } else {
      if (index >= 0) {
        this.video = document.getElementById("thumb-video");
        var rememberSpotBatch = {...this.state.rememberSpotBatch}
        rememberSpotBatch[this.state.section.identifier] = {
          p: videojs(this.video).currentTime(),
          t: videojs(this.video).duration(),
          saved: false,
          type: this.state.section.type
        }
        // dispose previous one before playing next
        var video = document.getElementById("thumb-video");
        if (video)
          videojs(video).dispose();
        this.setState({rememberSpotBatch: rememberSpotBatch, adWrapperExist: false, videoInQueue: undefined})
        this.setState({all_contents: this.props.all_contents})
        let newSection = this.props.all_contents[index];
        if(this.props.modal.head === "search"){
          newSection = this.addParentFeedDataForSearch(newSection);
        }
        if (newSection.mazContentType && newSection.lineage) {
          newSection = this.getSectionParentFromLineage(newSection)
        }
        var type = videoType(newSection.contentUrl)
        var nextVideoData = NextVideo(this.props.all_contents, index)
        this.setState({
          type: type,
          section: newSection,
          index: index,
          lineage: newSection.lineage,
          next_section: nextVideoData[0],
          next_index: nextVideoData[1],
          next_video: true,
          video_title: newSection.title,
          video_count: this.state.video_count + 1
        })
        if(prevVideoEnded){
          this.setVideoAnalytics(true, newSection, newSection.title, ChangeRoute(newSection.identifier));
        }else{
          this.setVideoAnalytics(false, newSection, newSection.title, ChangeRoute(newSection.identifier));
        }
        this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
          this.props.user.user_id)
      } else {
        this.onLinkCLick()
      }
    }
  }

  onFreeContentOver() {
    const video = document.getElementById("thumb-video");
    var spotxIframe = getSpotxIframe();
    if (isSmartTv()) {
      this.saveVideoTimeInRemeberSpot(video);
    }
    if(video) videojs(video).dispose();
    if(spotxIframe) {
      removeSpotxIframe();
    }
    this.handleMetering(false)
    this.handleTitle(false)
    if (document.getElementById("next-video")) {(document.getElementById("next-video").style.display = "none")}
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}    
  }


  renderInlineMenu() {
    return (
      <InlineMenu
        all_contents={this.props.all_contents}
        feeds={this.props.feeds}
        next={(index) => this.setNextVideo(index)}
        playing={this.state.index}
        inlineMenu={this.state.section.inlineMenu || ""}
      />
    )
  }

  render(){
    const runningOnMobile = this.props.runningOnMobile;
    if(this.props.section.identifier != this.state.section.identifier){
      window.history.replaceState("new video", {}, ChangeRoute(this.state.section.identifier))
    }

    const tv_style = {};
    if(isSmartTv()) {
      tv_style.display = "none";
    }

    return (
      <div className="videoFull" style={{background: "#000000"}} id="overlay-video">
        <div key={this.state.section.identifier}>
          {/* different key for each video to dispose previous because of ads wrapper, else next click won't work*/}
          <div id="loader" className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
          <video
            className={"video-js vjs-default-skin video-content-" + this.state.section.identifier} 
            id="thumb-video" 
            preload="10" 
            onEnded={(index) => this.setNextVideo(this.state.next_index, true)} // 2nd argument for post roll ad handling
            disablePictureInPicture
          /> 
          <span className="icon-hollow_arrow">
            <a id="overlay_video_back_btn" href="" onClick={(event) => this.onLinkCLick(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...tv_style}}>
              {runningOnMobile && <img src={backMobile} alt="Back"/>}
            </a>
          </span>
          {
            ((!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) &&
              this.state.section.locked)) &&
              <Metering
                content_id={this.state.section.identifier}
                parent_id={this.state.section.parent_id}
                title={this.state.section.title}
                contentAccess={this.state.section.access}
                feeds={this.props.feeds}
                onFreeContentOver={() => this.onFreeContentOver()}
                playing_Ad={this.state.playing_Ad}
                showMetering={this.state.showMetering}
                content={this.state.section}
                modalHead={this.props.modal.deepLink === DEEPLINK ? DEEPLINK : this.props.modal.head}
              />
          }
          {!runningOnMobile && !this.state.playing_Ad && this.state.showTitle && this.state.video_title && <div className="prevThumbnail video-title ellipsis" >
            <p className=""> <b>{this.state.video_title}</b></p>
          </div>}

          {/* To check if it is last video do not show up next. (this.state.next_index will be undefined for last video) */}
          {!runningOnMobile && !this.state.playing_Ad && this.state.next_index && <div className="prevThumbnail focusable" tabIndex={getTabIndex()} id="next-video" style={{display: "none", backgroundColor: (this.props.feeds.primaryColor && this.props.feeds.primaryColor.replace('#FF','#'))}} onClick={(index) => this.setNextVideo(this.state.next_index)}>
            <p>Up Next in <span className="nextVideoSeconds"></span> Seconds</p>
            <img src={this.state.next_section && this.state.next_section.cover && httpsUrl(this.state.next_section.cover.url) && getImageUrlBaseOnSize(httpsUrl(this.state.next_section.cover.url), 384)} 
              style={{visibility:"hidden"}}
              onLoad={(e) => {e.target.style.visibility='visible'}}
            />
            <p className="prevText">{this.state.next_section && this.state.next_section.title}</p>
          </div>}
        </div>
        {!runningOnMobile && !this.state.playing_Ad && this.renderInlineMenu()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  user: state.user.user,
  ads: state.ads,
  items_purchased: state.user.items_purchased,
  subscription: state.user.subscription,
  modal: state.modal.modal,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model
})

const mapDispatchToProps = {
  rememberSpot: localRememberSpotBatch,
  closeModal: closeModal,
  openModal: openModal,
  modalProgress: modalProgress,
  updateVideoCount: updateVideoCount,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverlayVideo));
