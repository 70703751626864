import React from 'react'
import ErrorDisplay from "./ErrorDisplay"
import { connect } from "react-redux"
import { userActions } from "../actions/userActions"
import ForgotPassword from "../components/ForgotPassword"
import { openModal } from '../actions/modalActions';
import Loader from '../components/Loader';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Focusable } from 'react-js-spatial-navigation';
import TvFocusable from '../components/TvFocusable';
import { getTabIndex, showVirtualKeyboard } from '../helperfunctions/common';
import { getGdprParamsForApi } from '../helperfunctions/feedHelper'
import configuration from '../constants/config'
import { PLATFORM_WEB } from '../constants/common'

function valid_email(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() === "" || re.test(email) === false) {
        return false
    } else {
        return true
    }
}

function valid_password(password) {
    if (password.trim() === "") {
        return false
    } else {
        return true
    }
}

class UserAccess extends React.Component {
    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.dismissAction = this.dismissAction.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.state = { error: false, email: props.email || '', password: props.password || '', errorHeading: '', errorMsg: '', forgotPassword: false, input_focus: 'email', layoutName: "default" };
    }

    componentDidMount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.add('overflow-scroll-auto');
        }
    }

    componentWillUnmount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-scroll-auto');
        }
    }

    formSubmit(e) {
        e.preventDefault();
        const email = this.state.email;
        const password = this.state.password;
        const button_clicked = e.target.value;
        if (valid_email(email) && valid_password(password)) {
            const data = {
                email: email,
                password: password,
                app_id: this.props.app_id,
                button_clicked: button_clicked
            }
            if (this.props.userConcent) {
                // User have given concent, don't open data-privacy page
                this.props.userActions(email, password, this.props.app_id, button_clicked, getGdprParamsForApi(this.props.feeds));
            } else {
                this.props.onAPIDataReady(data)
            }
        } else {
            if (!valid_email(email)) {
                this.setState({ error: true, errorHeading: "Invalid email", errorMsg: "Please input a valid email address." })
            } else if (!valid_password(password)) {
                this.setState({ error: true, errorHeading: "Invalid password", errorMsg: "Password can't be blank." })
            }
        }
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value })
    }
    dismissAction(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ error: false, forgotPassword: false })
    }
    forgotPassword(e) {
        e.preventDefault();
        const email = this.state.email;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.trim() === "" || re.test(email) === false) {
            this.setState({ error: true, errorHeading: "Invalid email", errorMsg: "Please input a valid email address." })
        }
        else {
            this.setState({ forgotPassword: true })
        }
    }
    componentWillReceiveProps(nextProps) {
        if ((nextProps.user_error || (nextProps.user.error && !nextProps.logged_in)) && this.state.email.trim() !== "" && this.state.password.trim() !== "") {
            let errorMsg = ""
            if (nextProps.user == "Your account is locked.") {
                errorMsg = "You've reached the maximum login attempts, your account is temporarily locked"
            } else {
                errorMsg = nextProps.user.error
            }
            this.setState({ error: true, errorHeading: "Invalid Details", errorMsg: errorMsg, email: "", password: "" })
        }
    }

    setInputFocus = (type) => {
        // console.log('Set input focus', this.keyboard, this.keyboard.utilities.getCaretPosition());
        this.keyboard.clearInput();
        this.setState({ input_focus: type });
        if (type === 'email') {
            // this.keyboard.setInput(this.state.email);
            this.keyboard.replaceInput({ default: this.state.email, input2: this.state.email });
        } else {
            // this.keyboard.setInput(this.state.password);
            this.keyboard.replaceInput({ default: this.state.password, input2: this.state.password });
        }
        this.keyboard.setCaretPosition(null);
    }

    keyboardKeyPressed = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            const layoutName = this.state.layoutName;

            this.setState({
                layoutName: layoutName === "default" ? "shift" : "default"
            });
        }
    }

    onKeyboardChange = (input) => {
        // console.log("INPUT", input, this.keyboard.utilities.getCaretPosition());
        if (this.state.input_focus === 'email') {
            this.setState({ email: input });
        } else {
            this.setState({ password: input });
        }
        // this.keyboard.replaceInput(e.target.value);
    }

    handleInputSelect = (e, type) => {
        if (e.keyCode != '13') return;
        document.getElementsByClassName("hg-button")[0].focus();
        this.setInputFocus(type);
    }

    render() {
        this.layout = {
            'default': [
                '1 2 3 4 5 6 7 8 9 0',
                'q w e r t y u i o p',
                'a s d f g h j k l -',
                '{shift} z x c v b n m _',
                '.com @ {bksp} {space}'
            ],
            'shift': [
                '~ ! @ # $ % ^ & * ( ) _ +',
                'Q W E R T Y U I O P { } |',
                'A S D F G H J K L : "',
                '{shift} Z X C V B N M < > ?',
                '.com @ {bksp} {space}'
            ]
        };

        let screen = "firstSave"
        if (this.props.screen === "registerWall") {
            screen = "registrationWall"
        }
        let mainData = this.props.feeds.loginUIMetadata[screen];
        const createText = mainData && mainData.strings && mainData.strings.register;
        const loginText = mainData && mainData.strings && mainData.strings.login;
        if ((document.getElementsByClassName("user-access")[0] !== undefined) && (document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0] !== undefined))
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "block";
        if (this.props.isUserLoading || this.props.emailLoginLoading)
            return (<Loader />)
        if (this.state.error) {
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        } else if (this.state.forgotPassword) {
            return <ForgotPassword forgotPassword={this.forgotPassword} dismiss={this.dismissAction} email={this.state.email} app_id={this.props.app_id} />
        } else {
            return (
                <div className="logInScreen">
                    {/* <p style={{color: this.props.feeds.loginUIMetadata.headerTextColor}}>{this.props.feeds.loginUIMetadata.txtFirstSave}</p> */}
                    <p className="user-access-heading" style={{ color: this.props.feeds.loginUIMetadata.colors.headerText || "#FFD626FF" }}>
                        {this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}</p>

                    <form id="account-form">
                        <input onKeyDown={e => this.handleInputSelect(e, 'email')} className="focusable" tabIndex={getTabIndex()} type="email" placeholder="Email" onChange={this.handleEmailChange} value={this.state.email} /><br />
                        <span className="btnForgot">
                            <input onKeyDown={e => this.handleInputSelect(e, 'pswd')} className="focusable" tabIndex={getTabIndex()} type="password" placeholder="Password" onChange={this.handlePasswordChange} value={this.state.password} />
                            {configuration.is_simulator && (<a className="focusable" tabIndex={getTabIndex()} href="#" onClick={this.forgotPassword}>Forgot Password ?</a>)}
                        </span><br />
                        <span className="btnBox">
                            {(this.props.feeds.loginUIMetadata.fb && !this.props.modalLogin) && <a href="#" onClick={this.props.selectBack} className="bck-btn">Back</a>}
                            <button className="continue-btn focusable" tabIndex={getTabIndex()} onClick={this.formSubmit} type="submit" value="Create Account" style={{ color: this.props.feeds.loginUIMetadata.buttonTextColor, backgroundColor: this.props.feeds.loginUIMetadata.buttonColor }}>{createText || "Create Account"}</button>
                            <button className="login-btn focusable" tabIndex={getTabIndex()} onClick={this.formSubmit} type="submit" value="Login">{loginText || "Login"}</button>
                            {configuration.platform === PLATFORM_WEB && (<button className="focusable" tabIndex={getTabIndex()} onClick={this.forgotPassword}>Forgot Password ?</button>)}
                        </span>

                        {
                            showVirtualKeyboard() ?
                                <div style={{ width: '70%', height: 'auto', margin: '0px auto', marginTop: '1em' }}>
                                    <Keyboard
                                        keyboardRef={r => (this.keyboard = r)}
                                        layout={this.layout}
                                        layoutName={this.state.layoutName}
                                        enableKeyNavigation={true}
                                        useMouseEvents={true}
                                        buttonTheme={[
                                            {
                                                class: 'hg-black focusable',
                                                buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
                                            }
                                        ]}
                                        buttonAttributes={[
                                            {
                                                attribute: 'tabindex',
                                                value: '-1',
                                                buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
                                            }
                                        ]}
                                        onKeyPress={btn => this.keyboardKeyPressed(btn)}
                                        onChange={this.onKeyboardChange}
                                    />
                                </div> : null
                        }
                    </form>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    user_error: state.user.error,
    subscription: state.subscription,
    isUserLoading: state.user.isLoading,
    emailLoginLoading: state.user.emailLoginLoading
})

const mapDispatchToProps = {
    userActions: userActions,
    openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess)
