import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import Background from '../components/Background'
import Logo from '../components/Logo'
import { openModal } from "../actions/modalActions"
import { simulatedSubPurchase, simulatedItemPurchase, getUserSubscriptionDetails } from "../actions/userActions";
import { startPurchase, cancelSubscription, renewSubscription } from "../actions/subscriptionAction";
import configuration from '../constants/config';
import Loader from "../components/Loader";
import { getParentFeed } from "../helperfunctions"
import * as analytics from '../constants/analytics';
import { Focusable } from 'react-js-spatial-navigation';
import TvFocusable from '../components/TvFocusable';
import { PLATFORM_HTML_SIMULATOR, UNLOCK_NOTE_SIMULATOR, UNLOCK_NOTE_WEB } from '../constants/common';
import { checkCorrectPlatform, getTabIndex, isSmartTv } from '../helperfunctions/common';

function FindDefaultSub(subscriptions) {
  var defaultSub = undefined;
  for (var i = 0; i < subscriptions.length; i++) {
    if (subscriptions[i].isDefault) {
      defaultSub = subscriptions[i]
      break;
    }
  }
  return defaultSub
}

function SubDurationLabel(price) {
  return {
    31: `Monthly Subscription - $${price} / Month`,
    93: `3 Months Subscription - $${price} / 3 Month`,
    186: `6 Months Subscription - $${price} / 6 Month`,
    366: `Annual Subscription - $${price} / Year`
  };
}

function freeTrailLabel() {
  return {
    7: "with 7 days Free Trial",
    31: "with 1 month Free Trial",
    62: "with 2 months Free Trial",
    93: "with 3 months Free Trial"
  }
}

class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentView: '',
      openConfirmModal: false,
      iframe_render: false
    }
    this.iframe_url = "";

    this.purchaseSubscription = this.purchaseSubscription.bind(this);
    this.purchaseItem = this.purchaseItem.bind(this);
    this.formerClassName = null;
  }
  componentWillMount() {
    this.setState({ logo: this.props.feeds.logo })
  }

  componentDidMount() {
    analytics.setPageViewFixedSections('Subscribe')

    if (this.props.logged_in && !this.props.isOpenedFromModal && !(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      this.getSubscriptionDetails();
    }
  }

  componentWillUnmount() {
    if(this.formerClassName) {
      document.body.className = this.formerClassName;
      this.formerClassName = null;
    }
  }

  getSubscriptionDetails() {
    const params = this.getApiParams();
    this.props.getUserSubscriptionDetails(params)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.allSubscription.isSubscriptionCancelled && this.props.allSubscription.isSubscriptionCancelled) {
      this.props.openModal({ type: "subscribe", message: "Your subscription has been cancelled." })
      this.getSubscriptionDetails();
    }

    this.showErrorIfRenewFails(prevProps);
    if(document.getElementsByClassName('sub-item-cancel-button').length) {
      if(this.state.openConfirmModal) {
        document.getElementsByClassName('sub-item-cancel-button')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('sub-item-cancel-button')[0].style.display = 'block';
      }
    }
  }

  showErrorIfRenewFails = (prevProps) => {
    if (prevProps.allSubscription.isRenewPending && !this.props.allSubscription.isRenewPending && this.props.allSubscription.errorMsg) {
      this.props.openModal({ type: "subscribe", message: "Not able to renew subscription." })
    }
    else if (prevProps.allSubscription.isRenewPending && !this.props.allSubscription.isRenewPending && !this.props.allSubscription.errorMsg) {
      this.props.openModal({ type: "subscribe", message: "Your subscription has been renewed." });
      this.getSubscriptionDetails();
    }
  }

  purchaseSubscription(ev, subscriptionItem) {
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      this.props.startPurchase({
        subscription: true,
        price: subscriptionItem.iap.price,
        isFreeTrialCancelled: this.checkIfTrialAlreadyPurchased(subscriptionItem),
        // duration: SubDurationLabel()[subscriptionItem.duration].slice(0, -1),
        item: subscriptionItem.identifier,
        itemDetails: subscriptionItem
      });
      this.props.openModal({ type: "payment", goBack: isSmartTv() ? true : false });
    } else {
      this.props.simulatedSubPurchase()
      this.props.openModal({
        type: "subscribe",
        heading: "This is a simulated purchase. All content will be unlocked.",
        message: "You can cancel this purchase from settings."
      }
      )
      if (window.location.pathname.includes('subscription')) {
        this.props.history.replace(`/apps/${configuration.app_id}/settings`)
      }
    }
    ev.preventDefault()
  }

  purchaseItem(ev, item_id, itemDetails) {
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      this.props.startPurchase({
        iap: true,
        item: item_id,
        price: itemDetails.access.iap.price,
        title: itemDetails.title,
        itemDetails: itemDetails
      });
      this.props.openModal({ type: "payment", goBack: isSmartTv() ? true : false });
    }
    else {
      this.props.simulatedItemPurchase(item_id)
      this.props.openModal({
        type: "subscribe",
        heading: "This is a simulated purchase. This content will be unlocked.",
        message: ""
      }
      )
    }
    ev.preventDefault()
  }

  clickEnter = (e) => {
    // console.log('Click enter', e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();
  }

  /**
   * Function to make subscribe button anchor element html.
   * @param {function} onClickFn Function to call, when button pressed
   * @param {String} linkText Text to show inside button
   * @param {String | Number} key unique key
   */
  subscribeButtonHtml = (onClickFn, linkText, key) => {
    return (
      // <Focusable onClickEnter={e => this.clickEnter(e)}>
      // <a href="#" 
      //     onClick={onClickFn}
      //     className="btn-trial"
      //     key={key}
      //     style={{
      //       color: this.props.feeds.loginUIMetadata.newSub.colors.purLabelColor,
      //       backgroundColor: this.props.feeds.loginUIMetadata.newSub.colors.purButtonColor
      //     }}>
      //       {linkText}
      // </a>
      // </Focusable>
      <a href="#"
        onClick={onClickFn}
        className="btn-trial focusable"
        tabIndex={getTabIndex()}
        key={key}
        style={{
          color: this.props.feeds.loginUIMetadata.newSub.colors.purLabelColor,
          backgroundColor: this.props.feeds.loginUIMetadata.newSub.colors.purButtonColor
        }}>
        {linkText}
      </a>
    )
  }

  /**
   * Function to make IAP button.
   */
  iapButtonHtml = (itemDetails) => {
    return (
      <span key="ft" className="item-button">
        {this.subscribeButtonHtml(
          ((event) => this.purchaseItem(event, this.props.parent_id, itemDetails)),
          `Purchase for $${itemDetails.access.iap.price}`,
          "ft"
        )}
        <br />
      </span>
    )
  }

  getApiParams = () => {
    return {
      auth_token: this.props.user.auth_token,
      user_id: this.props.user.user_id,
      combo_app_id: configuration.app_id
    }
  }

  cancelSubscription = () => {
    const params = this.getApiParams();
    this.props.cancelSubscription(params);
    this.closeConfirmModal();
    return false;
  }

  closeConfirmModal = () => {
    this.setState({ openConfirmModal: false })
  }

  openConfirmAlertBox = () => {
    this.setState({ openConfirmModal: true })
  }

  onRenewSubscription = (sub) => {
    const apiParams = {
      ...this.getApiParams(),
      type: "renew",
      subscription_id: sub.item_id
    }
    this.props.renewSubscription(apiParams);
  }

  showSubscriptionDetails = (sub) => {
    return (
      <div className="sub-item" key={sub.item_id}>
        <div className="sub-item-details">
          <h4>Subscription</h4>
          <span className="sub-item-msg">You have an active subscription</span>
          {!sub.cancel_date && sub.trail_end_date && (<div><b>Trial end date: </b>{new Date(sub.trail_end_date).toDateString()}</div>)}
          {!sub.cancel_date && (<div><b>Auto renew date: </b>{new Date(sub.end_date).toDateString()}</div>)}
          {sub.cancel_date && (<div><b>Cancel date: </b>{new Date(sub.cancel_date).toDateString()}</div>)}
        </div>
        <div className="sub-item-cancel-button">
          {sub.cancel_date ?
            (<div>
              <p>You have already cancelled your subscription, It will end on the Cancel date.</p>
              <span key="ft">
                {this.subscribeButtonHtml((e) => {
                  e.preventDefault();
                  this.onRenewSubscription(sub);
                }, 'Renew Subscription', "ft")}
                <br />
              </span>
            </div>
            )
            :
            (<span key="ft">
              {this.subscribeButtonHtml((e) => {
                e.preventDefault();
                this.openConfirmAlertBox();
              }, 'Cancel Subscription', "ft")}
              <br />
            </span>)
          }
        </div>
      </div>
    )
  }

  purchasesDetails = () => {
    return this.props.purchasedSubs.map(sub => {
      if (sub.type === "subscription") {
        if (new Date() > new Date(sub.end_date)) {
          return null;
        }
        return this.showSubscriptionDetails(sub)
      }
    })
  }

  termsAndPrivacyPolicyDiv = () => {
    // Do not show if opened through other than subscription page url.
    if (!this.props.isOpenedFromModal) {
      if (isSmartTv()) {
        return (
          <div className="termsOfUse">
            <ul className="mob_info">
              <li><a onClick={() => this.updateIframeInfo('terms',this.props.feeds.loginUIMetadata.urls.terms)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>Terms of Use</a></li>
              <li className="termsVertical">|</li>
              <li><a onClick={() => this.updateIframeInfo('terms',this.props.feeds.loginUIMetadata.urls.policy)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>Privacy Policy</a></li></ul>
          </div>
        )
      } else {
        return (
          <div className="termsOfUse">
              <ul className="mob_info">
              <li><a href={this.props.feeds.loginUIMetadata.urls.terms} target = "_blank" className="text-white" >Terms of Use</a></li>
              <li className="termsVertical">|</li>
              <li><a href={this.props.feeds.loginUIMetadata.urls.policy} target = "_blank" className="text-white">Privacy Policy</a></li></ul>
          </div>
        )
      }
    }
    return null;
  }

  diclaimerDiv = () => {
    // Do not show if opened through other than subscription page url.
    if (!this.props.isOpenedFromModal)
      return (
        <div className="disclaimer">
          Payment will be charged to the Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period for the same price as the first period. Any unused portion of a free period, if offered, will be forfeited when the user purchases a subscription. You may manage your subscriptions and auto-renew may be turned off by going to Subscription in Settings after purchase and cancel the subscription. No cancellation of the current subscription is allowed during an active subscription period. “Free Trial” and “FREE” represent a period of free access that will be automatically followed by an auto-renewing subscription at the standard subscription price unless auto-renew is turned off at least 24 hours before the end of the current period.
        </div>
      )
    return null;
  }

  confirmCancelModal = () => {
    if (this.props.sub_platform != "web_as_output") {
      return (
        <div className="confirm-modal" onClick={this.closeConfirmModal}>
          <div onClick={(e) => {
            e.stopPropagation();
          }}>
            <p>Subscription cancellation not possible from here.</p>
            <div className="button-div">
              <button onClick={this.closeConfirmModal}>Cancel</button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="confirm-modal" onClick={this.closeConfirmModal}>
        <div onClick={(e) => {
          e.stopPropagation();
        }}>
          <h3>Cancel Subscription</h3>
          <p>Note: Your Subscription will be discontinued after the cancel date. If there is no free trial, the amount will not be refunded.</p>
          <div className="button-div">
            <button  className="focusable" tabIndex={getTabIndex()} onClick={this.cancelSubscription}>Confirm</button>
            <button className="focusable" tabIndex={getTabIndex()} onClick={this.closeConfirmModal}>Cancel</button>
          </div>
        </div>
      </div>
    )
  }

  restorePurchaseButton = () => {
    return (
      <span className="restore-purchase" style={{ display: "block" }}>
        {
          this.subscribeButtonHtml((e) => this.restorePurchase(e),
            this.props.feeds.loginUIMetadata.newSub.strings.restore,
            "restore")
        }
        <br />
      </span>
    )
  }

  restorePurchase = (e) => {
    e.preventDefault();
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])))
      this.props.openModal({ type: 'login' });
  }

  getSectionUrl = () => {
    const url = window.location.pathname;
    const itemId = this.props.item.identifier;
    const parentId = this.props.item.parent_id;
    let newUrl = url;
    if (!newUrl.includes(parentId)) {
      newUrl = `${newUrl}/${parentId}/${itemId}`;
    }
    if (!newUrl.includes(itemId)) {
      newUrl = `${newUrl}/${itemId}`;
    }
    return newUrl.split('/').slice(3).join('/');
  }

  getParentContent = () => {
    if (this.props.item.type == 'menu' || !this.props.item.parent_id)
      return this.props.item;
    const itemDetails = this.props.item;
    const url = this.getSectionUrl();
    return getParentFeed(this.props.feeds, url);
  }

  checkIfTrialAlreadyPurchased = (sub) => {
    const purchasedSubs = this.props.purchasedSubs;
    let ifTrialAlreadyPurchased = false;
    purchasedSubs.forEach(subDetail => {
      if (subDetail.item_id === sub.identifier) {
        ifTrialAlreadyPurchased = true;
        return null;
      }
    })
    return ifTrialAlreadyPurchased;
  }

  checkSubscriptionIsOn() {
    let goBack = true;
    if (this.props.item && this.props.item.type === 'menu') {
      goBack = false
    }
    if (!this.props.subscriptions) {
      this.props.openModal({
        type: "error",
        message: "Unable to do purchase.",
        goBack: goBack
      })
      return false;
    }
    return true;
  }

  updateIframeInfo = (type, url, open_iframe = true) => {
    this.iframe_url = url;
    if (open_iframe) {
      document.addEventListener('keyup', this.handleScrollingWithKeyboard);
    } else {
      document.removeEventListener('keyup', this.handleScrollingWithKeyboard);
    }

    this.setState({ iframe_render: open_iframe });
  }

  handleScrollingWithKeyboard = (e) => {
    if (!document.getElementById('myframe')) return;

    const locales = document.getElementById('myframe').contentWindow;

    // console.log("Scroll Event fired");
    let _scroll_y = locales.scrollY;

    if (e.keyCode == '38') { // up
      locales.scrollTo(0, _scroll_y - 20);
    } else if (e.keyCode == '40') { // down
      locales.scrollTo(0, _scroll_y + 20);
    } else if (e.keyCode == '10009' || e.keyCode == '461') {
      this.updateIframeInfo("", "", false);
    }
  }

  onBack = () => {
    this.setState({iframe_render: false});
  }

  renderIframe = () => {
    return (
      <>
        <button id="iframe1_back_button_ctv" onClick={this.onBack} style={{display: "none"}}>back</button>
        <iframe style={{ height: '-webkit-fill-available' }} id="myframe" src={this.iframe_url} width="100%" allow>
        </iframe>
      </>
    );
  }

  render() {
    // let newItem = getParentFeed(this.props.feeds);
    // let defaultSub = FindDefaultSub(this.props.subscriptions)
    if (!this.checkSubscriptionIsOn()) {
      return null
    }

    if(this.state.iframe_render) {
      return this.renderIframe();
    }

    const itemDetail = this.props.item && this.getParentContent();
    let sub_divs = [];
    let item_pricing = "";
    let coverImgUrl = "";
    let iapFeedName = "";
    if (itemDetail && itemDetail.access && itemDetail.access.iap) {
      item_pricing = this.iapButtonHtml(itemDetail);
      coverImgUrl = itemDetail.cover && itemDetail.cover.url;
      iapFeedName = itemDetail.title;
    } else if (!itemDetail) {
      this.formerClassName = document.body.className;
      document.body.className = "subscribe";
    }
    if (this.props.isUserLoading || this.props.allSubscription.isLoading || this.props.allSubscription.isRenewPending) {
      return <Loader />
    }
    if (itemDetail && itemDetail.special) {
      return (
        <div className="subscribeScreen-wrapper">
          <Background background={this.props.feeds.subscriptionUIMetadata.background} />
          <Logo logo={coverImgUrl || this.props.feeds.subscriptionUIMetadata.logo || this.props.feeds.logo} />
          {iapFeedName && (<div className="iapTitle" style={{ color: this.props.feeds.loginUIMetadata.newSub.colors.headerText }}>
            {iapFeedName}
          </div>)
          }
          <div className="subscribeScreen">
            {item_pricing}
          </div>
        </div>
      )
    }
    const self = this;
    sub_divs = sub_divs.concat(this.props.subscriptions.map(function (sub, i) {
      var label = SubDurationLabel(sub.iap.price)[sub.duration];
      return (
        <div className="subscription-option-div">
          <span style={{ display: "block" }} key={i}>
            {this.subscribeButtonHtml((e) => this.purchaseSubscription(e, sub), label, i)}
            <br />
          </span>
          {sub.freeTrial && !self.checkIfTrialAlreadyPurchased(sub) && (<span className="trial-text">{freeTrailLabel()[sub.freeTrial]}</span>)}
        </div>
      )
    }, this));

    if (this.props.isSubscribed && (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      return (
        <div className="subscribeScreen-wrapper">
          <Background background={this.props.feeds.subscriptionUIMetadata.background} />
          <div className="subscribeScreen">
            You have an active subscription.
            </div>
        </div>
      )
    }

    if (this.props.isSubscribed) {
      return (
        <div className="subscribeScreen-wrapper">
          <Background background={this.props.feeds.subscriptionUIMetadata.background} />
          <div className="subscribeScreen">
            {this.purchasesDetails()}
          </div>
          {
            this.state.openConfirmModal && this.confirmCancelModal()
          }
        </div>
      )
    } else {
      return (
        <div className="subscribeScreen-wrapper">
          <div className="subscription-cntnt">
            <Background background={this.props.feeds.subscriptionUIMetadata.background} />
            <Logo logo={coverImgUrl || this.props.feeds.loginUIMetadata.newSub.headerImage || this.props.feeds.logo} />
            {iapFeedName && (<div className="iapTitle" style={{ color: this.props.feeds.loginUIMetadata.newSub.colors.headerText }}>
              {iapFeedName}
            </div>)
            }
            <div className="subscribeScreen">
              {item_pricing}
              <div>
                <p className="subscription-header-text" style={{ color: this.props.feeds.loginUIMetadata.newSub.colors.headerText }}>
                  {this.props.feeds.loginUIMetadata.newSub.strings.header}
                </p>
                {this.props.feeds.subscriptionUIMetadata.membershipSubTitle &&
                  <p className="offer" style={{ color: this.props.feeds.loginUIMetadata.newSub.colors.headerText }}>
                    {this.props.feeds.loginUIMetadata.newSub.strings.header}
                  </p>
                }
                {sub_divs}
              </div>
              <div className="sub-unlock-note">{configuration.is_simulator ? UNLOCK_NOTE_SIMULATOR : UNLOCK_NOTE_WEB}</div>
              {
                //Commenting Restore purchase button
                // !this.props.logged_in && configuration.is_simulator &&
                // this.restorePurchaseButton()
              }
            </div>
            {this.diclaimerDiv()}
          </div>
          {this.termsAndPrivacyPolicyDiv()}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  app_id: state.user.app_id,
  logged_in: state.user.logged_in,
  user_error: state.user.error,
  isSubscribed: state.user.subscription,
  sub_platform: state.user.sub_platform,
  isUserLoading: state.user.isLoading,
  purchasedSubs: state.user.all_subscriptions,
  allSubscription: state.subscription,
})

const mapDispatchToProps = {
  openModal: openModal,
  simulatedSubPurchase: simulatedSubPurchase,
  simulatedItemPurchase: simulatedItemPurchase,
  startPurchase: startPurchase,
  cancelSubscription,
  getUserSubscriptionDetails,
  renewSubscription
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscribe))
