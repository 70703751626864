import axios from "axios";
import { GET_USER_SUBSCRIPTION_RESOLVE, GET_USER_SUBSCRIPTION_REJECT, GET_USER_SUBSCRIPTION_PENDING,
  USER_METERING_RESOLVE, USER_METERING_REJECT, USER_METERING_PENDING, USER_ENGAGED, USER_CONCENT,
  GET_WEBSITE_LOGIN_PENDING, GET_WEBSITE_LOGIN_REJCTED, GET_WEBSITE_LOGIN_RESOLVED, POLLING_START, POLLING_STOP,
  GET_FB_LOGIN_CODE_PENDING, GET_FB_LOGIN_CODE_REJCTED, GET_FB_LOGIN_CODE_RESOLVED, FB_POLLING_START, FB_POLLING_STOP,
  GET_FB_USER_DATA_PENDING, GET_FB_USER_DATA_REJECTED, GET_FB_USER_DATA_RESOLVED, GET_USER_IP_PENDING, GET_USER_IP_RESOLVED, GET_USER_IP_REJECTED,
  GET_UUID_PENDING, GET_UUID_RESOLVED, GET_UUID_REJECTED, GET_USER_LOCATION_PENDING, GET_USER_LOCATION_RESOLVED, GET_USER_LOCATION_REJECTED,
  GET_DEVICE_MODEL_PENDING, GET_DEVICE_MODEL_RESOLVED, GET_DEVICE_MODEL_REJECTED, LOGIN_MAZ_TV, LOGOUT_MAZ_TV
} from "../constants/actionTypes";
import { getMazCoreEnvUrl } from '../constants/initializer';
import { isUserSubscribed } from '../helperfunctions/is_available';
import * as APIS from "../constants/api";
import config from "../constants/config";
import { openModal } from "../actions/modalActions"
import * as analytics  from '../constants/analytics';
import { getMeteringTypes,PLATFORM_SAMSUNG,PLATFORM_LG, PLATFORM_WEB } from '../constants/common';
import { checkAllRememberSpot } from "./rememberSpotActions";
import Cookies from 'universal-cookie';
import { getSavedItems, getUSerFeedHistoryOrProgress } from "./itemActions";
import { isSmartTv, checkCorrectPlatform } from "../helperfunctions/common";

function onSuccessfulLogin(dispatch, response, app_id, user_action) {
  const apiParams = {
    auth_token: response.data.auth_token,
    user_id: response.data.user_id,
    combo_app_id: config.app_id
  }
  if (response.data.success) {
    let cookies;

    try {
      if(checkCorrectPlatform([PLATFORM_LG])) {
        localStorage.setItem('AppUser', JSON.stringify(response.data));
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.saveData('AppUser', JSON.stringify(response.data), null, () => {});
      } else {
        cookies = new Cookies();
        cookies.set('AppUser', response.data, { path: '/' });  
      }
    } catch(err) {
      cookies = null;
    }

    dispatch(getUserSubscriptionDetails(apiParams))
    dispatch(getUserMeteringDetails(apiParams))
    dispatch({type: "RECEIVE_USER", payload: response.data, app_id: app_id, logged_in: response.data.success, error: false})
    dispatch(checkAllRememberSpot(apiParams))
    dispatch(getSavedItems(apiParams.auth_token, "100", "0", apiParams.user_id))
    dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'history'}));
    dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'progress'}));
   
  } else {
    throw {response};
  }
}


function setRegistrationAnalytics(type, user_action){
  var eventLabel = 'Email';
  if(type === 'facebook'){
    eventLabel = 'Facebook';
  }
  if(user_action && user_action === "Login"){
    analytics.setEvent('Registration', 'Login', eventLabel)
  }else{
    analytics.setEvent('Registration', 'Register', eventLabel)
  }
}

export function userActions(email, password, app_id, user_action, gdprParams={}) {
    var url='';
    const reqParams = {
      app_user: {
        ...gdprParams,
        email: email,
        password: password,
        app_type: 'ComboApp',
        app_id
    },
    platform: config.platform
  }

    url = (user_action === "Login") ? APIS.USER_SIGN_API : APIS.USER_SIGNUP_API
    return function(dispatch) {
        dispatch({type: 'RECEIVE_USER_EMAIL_PENDING'})
        axios.post(url, reqParams)
            .then((response) => {
                onSuccessfulLogin(dispatch, response, app_id, user_action);
                setRegistrationAnalytics('email', user_action)
            })
            .catch((error) => {
                dispatch({type: "RECEIVE_USER_ERROR", payload: (error.response && error.response.data), app_id: app_id, logged_in: false, error: true})
        })
    }
}

export function fbUserActions(email, fb_oauth_token, fb_display_name, app_id, gdprParams={}) {
    const apiParams = {
      app_user: {
        ...gdprParams,
        email,
        fb_oauth_token,
        app_id,
        display_name: fb_display_name,
        app_type: 'ComboApp'
      },
      platform: config.platform
    }
    
    const url = APIS.FB_LOGIN_SIGNUP;
    return function(dispatch) {
        dispatch({type: 'RECEIVE_USER_PENDING'})
        axios.post(url, apiParams)
            .then((response) => {
                onSuccessfulLogin(dispatch, response, app_id);
                setRegistrationAnalytics('facebook')
            })
            .catch(error => {
              if (!error.status) {
                const err = {
                  error: 'There was an issue with your request. Please try again.',
                  success: false
                }
                dispatch({type: "RECEIVE_USER_ERROR", payload: err, app_id: app_id, logged_in: false, error: true})
              }
              else {
                dispatch({type: "RECEIVE_USER_ERROR", payload: (error.response && error.response.data), app_id: app_id, logged_in: false, error: true})
              }
            })
    }
}

export function forgotPassword(email, app_id) {
    const fp_req_data = {app_user:{email:email,app_id:app_id,app_type:"ComboApp"}}
    const url = APIS.USER_FORGOT_PASSWORD_API;
    return function(dispatch) {
        axios.post(url, fp_req_data)
            .then((response) => {
                dispatch({type: "FORGOT_PASSWORD", payload: response.data, app_id: app_id, logged_in: false, error: !response.data.success})
            })
    }
}

export function logoutUserActions(app_id) {
    analytics.setEvent('Registration', 'Log Out', '')
    return function(dispatch) {
      dispatch({type: "LOGOUT_USER", app_id: app_id, logged_in: false, error: false})
      dispatch({type: "UPDATE_REMEMBER_SPOT_FLAG"})
      let localData = JSON.parse(localStorage.getItem('metering_details'))
      if(localData) {
        localStorage.removeItem("metering_details");
      }
    }
}

export function setUserCookie(user, app_id){
    return function(dispatch) {
        dispatch({type: "COOKIE_USER", payload: user, app_id: app_id, logged_in: true, error: false})
    }
}

export function simulatedSubPurchase(){
  return function(dispatch) {
    dispatch({type: "SUBSCRIPTION_PURCHASE"})
  }
}

export function simulatedItemPurchase(item_id){
  return function(dispatch) {
    dispatch({type: "ITEM_PURCHASE", item_id: item_id})
  }
}


export function removeSimulatedSubPurchase(){
  return function(dispatch) {
    dispatch({type: "REMOVE_SUBSCRIPTION_PURCHASE"})
  }
}

export function meteringDetails(options){
  return function(dispatch) {
    dispatch({type: "METERING_CONSUMED", section_id: options.section_id, seconds: options.seconds,item_id: options.item_id, mtype:options.mtype})
  }
}

export function getMeteringDisplay(total_seconds,type="non_cta"){
  if (total_seconds < 0) return `00:00`;
  let seconds = total_seconds % 60
  let minutes = (total_seconds-seconds)/60
  seconds = seconds > 9 ? "" + seconds: "0" + seconds
  if (type == "cta" && seconds==0){
    return `${minutes}`
  }
  return `${minutes}:${seconds}`
}

export function getUserSubscriptionDetails(apiParams) {
  return function(dispatch) {
    dispatch({type: GET_USER_SUBSCRIPTION_PENDING})
    axios.get(APIS.GET_USER_SUBSCRIPTION_API, {
      params: apiParams,
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(data => {
      const allSubscription = data.data.purchases;
      const subs = allSubscription.map(sub => sub.item_id.toString());
      const [isSubscribed, sub_platform] = isUserSubscribed(allSubscription);

      //Send analytics event for authenticated access
      if(isSubscribed)
        analytics.setEvent('Subscriptions', 'Authenticated Access', sub_platform)

      dispatch({
        type: GET_USER_SUBSCRIPTION_RESOLVE,
        payload: {
          items_purchased: subs,
          subscription: isSubscribed,
          all_subscriptions: allSubscription,
          sub_platform: sub_platform,

        }
      })
    })
    .catch(data => {
      analytics.setEvent('Subscriptions', 'Authenticated Access Failure', '');
      dispatch({
        type: GET_USER_SUBSCRIPTION_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to get subscription."
          }
        }
      })
    })
  }
}

export function getUserMeteringDetails(param) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch({type: USER_METERING_PENDING})
    axios.get(`${APIS.USER_INFO_API}${param.user_id}/meters`, {
      params: {
        auth_token: param.auth_token,
        platform: config.platform,
        n: 100
      },
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(data => {
      let payload = {};
      const METERING_TYPE = getMeteringTypes();
      const {type, time, views, meterStartDate} = data.data.meters;
      let localData = JSON.parse(localStorage.getItem('metering_details'))
      if (type == METERING_TYPE.TIME) {
        // local time metering saved in case of page refresh or tab close

        let details = {}
        // change array of objects to an object
        time.forEach(element => {
          let key = Object.keys(element)[0];
          let value = element[key];
          if(localData && localData[key] !== undefined && localData[key] < value) {
            value = localData[key];
          }
          details[key] = value
        });
        payload.metering_details = details;
      } else if (type == METERING_TYPE.VIEWS) {
        payload = getViewMeteringData(state.feeds, meterStartDate, views)
      } else if (!type && localData) {
        payload.metering_details = localData;
      }

      if(localData) {
        localStorage.removeItem("metering_details");
      }
      dispatch({
        type: USER_METERING_RESOLVE,
        payload: payload
      })
    })
    .catch(data => {
      dispatch({
        type: USER_METERING_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to get metering details."
          }
        }
      })
    })
  }
}

export function saveUserMeteringDetails(param) {
  return function(dispatch) {

    dispatch({type: USER_METERING_PENDING})
    axios.post(`${APIS.USER_INFO_API}${param.user_id}/meters`, {
      auth_token: param.auth_token,
      platform: config.platform,
      meters: param.meters
    })
    .then(data => {
      dispatch({
        type: USER_METERING_RESOLVE,
        payload: {}
      })
    })
    .catch(data => {
      dispatch({
        type: USER_METERING_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to save metering details."
          }
        }
      })
    })
  }
}

export function getViewMeteringData(feeds, meterStartDate, views) {
  // Check whether views has been reset or not, in case of view metering
  let payload = {};
  if(meterStartDate) payload.meterStartDate = meterStartDate;
  if(views) payload.metering_views_seen = views;
  if(feeds && feeds.feeds && feeds.feeds.ctaMetadata && feeds.feeds.ctaMetadata.metering &&
    meterStartDate && views) {
    const resetAfter = feeds.feeds.ctaMetadata.metering.resetAfter;
    if (resetAfter > 0) {
      // change date format from dd/mm/yy to mm/dd/yy
      let formattedDate = meterStartDate.split("/");
      formattedDate = `${formattedDate[1]}/${formattedDate[0]}/${formattedDate[2]}`;
      const diffDays = (new Date() - new Date(formattedDate)) / (86400 * 1000);
      if (diffDays > resetAfter) {
        payload["metering_views_seen"] = [];
      }
    }
  }
  return payload;
}

export function updateMeteringDetailsForFeed() {
  return function(dispatch, getState){
    let currentState = getState();
    const { feeds, user: {metering_views_seen, meterStartDate}} = currentState;
    let payload = getViewMeteringData(feeds, meterStartDate, metering_views_seen)
    dispatch({type: USER_METERING_RESOLVE, payload: payload})
  }
}

export function userEngaged() {
  return function(dispatch) {
    dispatch({type: USER_ENGAGED})
  }
}

export function getUserConcent() {
  return function(dispatch) {
    dispatch({type: USER_CONCENT})
  }
}

export function generateWebsiteLoginToken(app_id, gdprParams={}) {
  return function(dispatch) {
    dispatch({type: GET_WEBSITE_LOGIN_PENDING})
    axios.post(`${APIS.WEBSITE_LOGIN}`, {
      app_user: {
        ...gdprParams,
        app_id,
      },
      platform: config.platform,
    })
    .then(data => {
      dispatch({
        type: GET_WEBSITE_LOGIN_RESOLVED,
        payload: data.data
      })
    })
    .catch(data => {
      dispatch({
        type: GET_WEBSITE_LOGIN_REJCTED,
        payload: data.data
      })
    })
  }
}

function setRegistrationAnalyticsForWebsiteLogin(isLogin) {
  if(isLogin){
    analytics.setEvent('Registration', 'Login', 'Email')
  }else{
    analytics.setEvent('Registration', 'Register', 'Email')
  }
}

export function pollingSession(sessionUrl) {
  const api = APIS.FB_LOGIN_SIGNUP+sessionUrl;
  return function(dispatch) {
    dispatch({type: POLLING_START});
    axios.get(api)
    .then(data => {
      if (data.data.polling_success) {
        dispatch({type: POLLING_STOP});
        onSuccessfulLogin(dispatch, data, config.app_id, null)
        setRegistrationAnalyticsForWebsiteLogin(data.data.already_exists);

      }
    })
    .catch(err => {
      if ((err.response && err.response.status === 401)) {
        dispatch({type: POLLING_STOP, payload: {isPollingError: true}});
      }
    })
  }
}

export function getFbLoginCode() {
  return function(dispatch) {
    dispatch({type: GET_FB_LOGIN_CODE_PENDING})
    axios.post(`${APIS.FB_GET_CODE}`, {
      access_token: `${config.fb_app_id}|${config.fb_client_token}`,
      scope: 'public_profile,email',
    })
    .then(data => {
      dispatch({
        type: GET_FB_LOGIN_CODE_RESOLVED,
        payload: data.data
      })
    })
    .catch(data => {
      dispatch({
        type: GET_FB_LOGIN_CODE_REJCTED,
        payload: data.data
      })
    })
  }
}

export function fbLoginPolling(fbLoginCode) {
  return function(dispatch) {
    dispatch({type: FB_POLLING_START});
    axios.post(`${APIS.FB_POLLING_URL}`, {
      access_token: `${config.fb_app_id}|${config.fb_client_token}`,
      code: fbLoginCode,
    })
    .then(data => {
      if (data.data.access_token) {
        dispatch({type: FB_POLLING_STOP});
        dispatch(getFBUserNameEmail(data.data.access_token));
        // onSuccessfulLogin(dispatch, data, config.app_id, null)
        // setRegistrationAnalyticsForWebsiteLogin(data.data.already_exists);
      }
    })
    .catch(err => {
      if (err.response.status === 401) {
        dispatch({type: FB_POLLING_STOP, payload: {isPollingError: true}});
      }
    })
  }
}

export function getFBUserNameEmail(accessCode) {
  return function(dispatch) {
    dispatch({type: GET_FB_USER_DATA_PENDING});
    axios.get(`${APIS.FB_GET_USER_DATA}?fields=name,email&access_token=${accessCode}`)
    .then(data => {
      dispatch({type: GET_FB_USER_DATA_RESOLVED, payload: { ...data.data, token: accessCode}});
    })
    .catch(err => {
      dispatch({type: GET_FB_USER_DATA_REJECTED, payload: err});
    })
  }
}

export function getUserIp() {
  return function(dispatch) {
    dispatch({ type: GET_USER_IP_PENDING })
    axios.get(APIS.USER_IP, { mode: 'cors' })
    .then(data => {
      dispatch({type: GET_USER_IP_RESOLVED, payload: data.data.ip })
    }).catch(err => {
      dispatch({ type: GET_USER_IP_REJECTED, payload: null })
    })
  }
}

export function getUserDeviceID() {
  return function(dispatch) {
    dispatch({ type: GET_UUID_PENDING });

    if(checkCorrectPlatform([PLATFORM_WEB])) {
      dispatch({ type: GET_UUID_RESOLVED, payload: null });
    } else if(checkCorrectPlatform([PLATFORM_LG])) {
      let proms = new Promise((resolve, reject) => {
        if(!window.webOSDev) {
          reject(null);
          return;
        }
        window.webOSDev.LGUDID({
          onSuccess: function (res) {
            // user device from server
            if(res && res.id) {
              resolve(res.id);
            } else {
              resolve(null);
            }
          },
          onFailure: function (res) {
            reject(null);
          }
        });
      });
  
      proms.then(data => {
        dispatch({ type: GET_UUID_RESOLVED, payload: data })
      }).catch(err => {
        dispatch({ type: GET_UUID_REJECTED, payload: err })
      })
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      if(!window.tizen) {
        dispatch({ type: GET_UUID_REJECTED, payload: null })
        return;
      }
      let did = window.tizen.systeminfo.getCapability("http://tizen.org/system/tizenid") || window.webapis.productinfo.getDuid();
      dispatch({ type: GET_UUID_RESOLVED, payload: did });
    }
  }
}

export function getuserLocation() {
  return function(dispatch) {
    dispatch({ type: GET_USER_LOCATION_PENDING });

    axios.get(APIS.USER_GEO, { mode: 'cors' })
    .then(data => {
      console.log("location",data);
      const coords = data.geo || null;
      dispatch({ type: GET_USER_LOCATION_RESOLVED, payload: coords })
    }).catch(err => {
      dispatch({ type: GET_USER_LOCATION_REJECTED, payload: null })
    });
    // let gps_prom = new Promise((resolve, reject) => {
    //   if(navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((pos) => {
    //       resolve(pos);
    //     },(err) => {
    //       reject(err);
    //     })
    //   } else {
    //     reject(null);
    //   }
    // });

    // gps_prom.then(data => {
    //   console.log("Location",data);
    //   const coords = data.coords;
    //   dispatch({ type: GET_USER_LOCATION_RESOLVED, payload: coords });
    // }).catch(err => {
    //   console.log("Location erro ",err);
    //   dispatch({ type: GET_USER_LOCATION_REJECTED, payload: null });
    // })
  }
}

export function getDeviceModel() {
  return function(dispatch) {
    dispatch({ type: GET_DEVICE_MODEL_PENDING });

    if(checkCorrectPlatform([PLATFORM_WEB])) {
      dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: null });
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      if(!window.tizen) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
        return;
      }

      let proms = new Promise((resolve, reject) => {
        window.tizen.systeminfo.getPropertyValue('BUILD',(res) => {
          resolve(res);
        },err => {
          reject(err);
        })
      });

      proms.then(data => {
        let model = `${data.manufacturer} ${data.model} ${data.buildVersion}`;
        dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: model });
      }).catch(err => {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null });
      });
    } else if(checkCorrectPlatform([PLATFORM_LG])) {
      if(!window.webOS) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
        return;
      }

      let proms = new Promise((resolve, reject) => {
        window.webOS.service.request("luna://com.webos.service.tv.systemproperty", {
          method: "getSystemInfo",
          parameters: { 
            "keys": ["modelName", "firmwareVersion", "UHD", "sdkVersion"]
          },
          onComplete: function (inResponse) {
            let isSucceeded = inResponse.returnValue;
            if(isSucceeded) {
              resolve(inResponse);
            } else {
              reject(null);
            }
          }
        })
      });

      proms.then(data => {
        let model = `LG ${data.modelName} ${data.firmwareVersion}`;
        dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: model });
      }).catch(err => {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
      });    
    }
  }
}

export function loginThroughMazTv(authToken, userId) {
  return function(dispatch) {
    const app_id = config.app_id;
    let responseObj = {
      data: {
        auth_token: authToken,
        user_id: userId,
        success: true
      }
    }
    dispatch({type: LOGIN_MAZ_TV});
    onSuccessfulLogin(dispatch, responseObj, app_id, null);
  }
}

export function logoutMazTv() {
  return function(dispatch) {
    dispatch({type: LOGOUT_MAZ_TV});
    try {
      window.parent.postMessage({
        action: 'logout',
        request_from: 'web-tv'
      }, "*");
    } catch (e) {
      console.log("failed to send logout message.")
    }
  }
}