import React, {Component} from "react";
import { truncateString } from './../helperfunctions';

class PreviewDescription extends Component {
  render(){
    const description  = this.props.description
    const fontStyle = this.props.fontStyle;
    return(
      <div
        id = "previewDescription">
        <div
          className="previewTitle"
          style={fontStyle.primaryFont}
        >
          {description.title}
        </div>
        {description.subtitle && <div className= "previewSubtitle"  style={fontStyle.secondaryFont}>
            {description.subtitle}
          </div>
        }
        <div
          id ="previewSummary"
          style={fontStyle.secondaryFont}
        >
          {description.summary}
        </div>
      </div>
    )
  }
}

export default PreviewDescription;