import React from 'react';
import { withRouter } from 'react-router-dom';
import Cta from "../components/Cta"
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId } from "../constants/common"
import { closeModal,openModal } from '../actions/modalActions'
import { environment } from "../constants/initializer"
import { connect } from 'react-redux';
import isAvailable from '../helperfunctions/is_available'
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { backButtonObj, checkIfSectionLevelContent } from '../helperfunctions'
import * as analytics  from '../constants/analytics';
import playButton from "../images/icons/play-button.png";
import { runAdInVideo, constructSpotxParams } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";

import 'videojs-contrib-ads';
import 'videojs-ima';
import { onVideoError, isSmartTv, getTabIndex } from '../helperfunctions/common';
var videojs = require('video.js');
let current_player,current_src,current_type;

function videoType(url){
  url = url.split('.')
  if (url[url.length - 1].split('?')[0] == "m3u8"){
    return "application/x-mpegURL"
  } else {
    return "video/mp4"
  }
}

function showVideoAd(props){
  var adToShow = props.ads.spotxid.length > 0
  adToShow = adToShow && window.SpotX && props.section.showVideoAd
  var showAd = false
  if(adToShow){ 
    // always show ads(if enabled) in live irrespective of frequency
    showAd = true;
  }
  return showAd
}

function playAd(props) {
  if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
  let videoSlot = document.getElementById('live-video');
  let adContainer = document.getElementsByClassName('live-video-ad')[0];
  if (document.getElementById('thumb-video_html5_api')) {
    videoSlot = document.getElementById("thumb-video_html5_api");
    adContainer = document.getElementById("live-video");
  }
  const cust_params = constructSpotxParams(props.feeds, props.section);
  const directAdOS = new window.SpotX.DirectAdOS({
    channel_id: getSpotXId(props.ads),
    slot: adContainer,
    video_slot: videoSlot,
    hide_skin: false,
    autoplay: true,
    custom: {...cust_params}
  });
  directAdOS.loadAd();
}

function playVideo(videourl, props, state, _this) {
  const player = videojs('live-video', {muted: false, controls: false});
  if(!player) return;
  
  const url = httpsUrl(videourl)
  const type = videoType(videourl)
  current_player = player;
  current_src = url;
  current_type = type;
  player.on('error', function() {
    onVideoError(type);
  });
  player.ready(() => {
    player.src({src: url, type: type, hls: {withCredentials: true}})
    runAdInVideo(player, props, state, _this, props.section.showVideoAd, "live-video_html5_api", props.section);
    player.play()
    var newVideo = document.getElementById("live-video_html5_api")
    newVideo.muted = false;
    if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
  })
}

class LiveSetup extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      metering_type: null,
      freeContentOver: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      playing_Ad: false,
      showPlayButton: !this.props.userEngaged
    }
    this.backButton = this.backButton.bind(this)
    this.METERING_TYPE = getMeteringTypes();
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
  }
  componentDidMount(){
    analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video','Live Play');
    if(!this.state.showPlayButton) {
      this.startVideo()
    } else {
      if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
    }
  }

  startVideo() {
    let showAd = false
    if(this.props.ads.spotxid !== undefined){
      showAd = showVideoAd(this.props)
    }
    if(showAd && !isSmartTv()){
      playAd(this.props);
    } else {
      playVideo(this.props.section.contentUrl, this.props, this.state, this);
    }
    this.setState({
      playing_Ad: showAd,
      showPlayButton: false
    })
  }

  shouldComponentUpdate(nextProps, nextState){
    if(!isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) && nextProps.subscription){
      if(document.getElementById('fake-video-overlay')) document.getElementById('fake-video-overlay').style.display = 'none';
      return false;
    } else {
      return true;
    }
  }

  componentWillUnmount(){
    const video = document.getElementById("live-video");
    if (video)
      videojs(video).dispose();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.freeContentOver !== this.state.freeContentOver && this.state.freeContentOver) {
      const video = document.getElementById("live-video");
      const spotxIframe = getSpotxIframe();
      if (video) videojs(video).dispose();
      if (spotxIframe) removeSpotxIframe();
    }
  }

  onAdReady() {
    const adCont = document.getElementById("live-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  onAdStart(ev) {
    this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
  }

  updateSrcInSmartTv(_type) {
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }

    if (podInfo.podPosition == podInfo.podLength) { 
      // console.log("played manually",current_src);
      current_player.reset();
      current_player.src({ src: current_src, type: current_type, hls: {withCredentials: true} });
      current_player.ready(() => current_player.play())
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      // this.updateSrcInSmartTv();      
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);
    }
    this.setState({playing_Ad: false})
    var newVideo = document.getElementById("live-video_html5_api")
    // live doesn't start playing after ad, so force it.
    if(newVideo && newVideo.paused && !isSmartTv()) {
      newVideo.play();
      newVideo.muted = false;
    }
  }

  onAllAdComplete() {
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false})
  }

  backButton(e){
    this.props.closeModal();
    const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
    this.props.history.replace(backUrlObj.backPath);
    e.preventDefault()
  }

  onFreeContentOver() {
    this.setState({freeContentOver: true})
  }

  // This will get called when ad is over.
  setNextVideo = () => {
    if(isSmartTv()) return;
    document.getElementById("loader").style.display = "block";
    const self = this;
    const spotxIframe = getSpotxIframe();
    if (spotxIframe) {
      this.setState({
        playing_Ad: false,
      })
      setTimeout(() => {
        removeSpotxIframe();
        playVideo(self.props.section.contentUrl, this.props, this.state, this);
      }, 100)
    }
  }

  render(){
    let navbar_present = document.getElementsByClassName("nav-view")[0] || false;
    const isSectionLevel = checkIfSectionLevelContent();
    const runningOnMobile = this.props.runningOnMobile;
    let ctv_style = {}
    if(isSmartTv()) {
      ctv_style.display = 'none';
    }
    return(
      <div className="videoFull" style={{top: 0, left: 0, position: "absolute"}}>
        <div id="loader" className="loader" style={{zIndex: 101}}><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
        <div className="live-video-ad" style={{height: '100%'}}>
          <video className={"video-js vjs-default-skin"} id="live-video" preload="10"  onEnded={() => this.setNextVideo()} disablePictureInPicture />
          {!navbar_present && !isSectionLevel && 
            <span className="icon-hollow_arrow">
              <a id="overlay_video_back_btn" href="" onClick={(event) => this.backButton(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...ctv_style}}>
                {runningOnMobile && <img src={backMobile} alt="Back"/>}
              </a>
            </span>
          }
        {this.state.showPlayButton && <div className="video-play-extra-button">
          <img className="focusable" tabIndex={getTabIndex()} alt="Play" src={playButton} onClick={() => this.startVideo()}/>
        </div>}
        </div>
        {!this.state.showPlayButton && this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) && 
          <Metering
            content_id={this.props.section.identifier}
            parent_id={this.props.section.identifier} 
            contentAccess={this.props.section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={this.state.playing_Ad}
            showMetering={true}
            content={this.props.section}
            isLive={true}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
  ads: state.ads,
  userEngaged: state.user.userEngaged,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model
})
const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveSetup));
