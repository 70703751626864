import React from 'react';
import Swiper from 'react-id-swiper';

import MyQueue from './MyQueue'
import Settings from './Settings'
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom'
import Section from './Section'
import Search from './Search'
import { Redirect } from 'react-router';
import PaymentMsg from "../components/PaymentMsg";
import configuration from '../constants/config';
import TvFocusable from '../components/TvFocusable';
import { backButtonObj, checkIfSectionLevelContent } from '../helperfunctions';
import WebNavBar from '../components/WebNavBar';
import * as analytics  from '../constants/analytics';
import { checkCorrectPlatform, checkLoginUIMetaData, isSmartTv } from "../helperfunctions/common";
import { PLATFORM_LG, PLATFORM_SAMSUNG } from '../constants/common';
import backMobile from "../images/icons/back-mobile.png";


class NavBar extends React.Component{
  constructor(props) {
    super(props);
    this.resizeTimeout = null;
    this.hideNavTimeout = null;
    this.onBackButtonEvent = this.onBackButtonEvent.bind(this)
    this.state = { currentView: '' , mountdone: false}
  }

  componentWillUnmount() {
    document.removeEventListener('keyup',this.handleRemoteClick);
  }

  handleRemoteClick = (e) => {
    // console.log("click in navbar",e.keyCode);
    if(e.keyCode == '10009' || e.keyCode == '461') {
      if(document.getElementById("overlay_video_back_btn")) {
        document.getElementById("overlay_video_back_btn").click();
      } else if(document.getElementById("main_back_id")) {
        document.getElementById("main_back_id").click();
      }
      e.preventDefault();

      // this.props.history.goBack();
      // console.log("sjdnfd",this.back_link)
      // // window.location.href = "file://" + this.back_link;

      // let { pathLocation } = this.backButton(this.section_id);
      // let parent_section_id = pathLocation;
      // window.scrollTo(0, 0);
      // this.navigateToRoute(parent_section_id);
      // e.preventDefault();
    }
  }

  navigateToRoute(route) {
    if (this.state.currentView !== route)
      this.setState({ currentView: route })
  }

  handleHeaderLinks(className, add) {
    const headerLinks = document.getElementsByClassName(className);
    for(let element of headerLinks) {
      if (element) {
        if(add) {
          element.classList.add("disable-header-link");
        } else if(element.classList) {
          element.classList.remove("disable-header-link");
        }
      }
    };
  }

  onBackButtonEvent(route){
    this.setState({currentView: route})
  }

  componentWillReceiveProps(){
    window.onpopstate = this.onBackButtonEvent(window.location.pathname)
    this.onViewChange();
  }

  componentDidMount(){
    if(configuration.is_simulator) {
      this.hideNavbar();
      this.onBodyMouseMoveHideShowNavbar();
    }
    this.onViewChange();
    // document.addEventListener('keyup',this.handleRemoteClick);
  }

  onViewChange() {
    this.handleHeaderLinks("headerLinks", true)//disable
    setTimeout(() => {
      this.handleHeaderLinks("headerLinks")//enable all
      this.handleHeaderLinks("headerLinks active", true)// disable active one
    }, 1200)
  }

  onBodyMouseMoveHideShowNavbar = () => {
    document.body.onmousemove = () => {
      if (document.getElementById("nav-bar")) {
        if (this.hideNavTimeout) {
          clearTimeout(this.hideNavTimeout);
        }
        this.showNavbar();
        this.hideNavbar();
      }
    }
  }

  hideNavbar = () => {
    return;
    this.hideNavTimeout = setTimeout(function() {
      if (document.getElementById("nav-bar")) {
        document.getElementById("nav-bar").style.top = "-140px" ;
        document.getElementById("nav-bar").style.transitionDuration = ".7s" ;
      }
    }, 3000)
  }

  showNavbar = () => {
    if (document.getElementById("nav-bar")) {
      document.getElementById("nav-bar").style.top = "0";
      document.getElementById("nav-bar").style.transitionDuration = ".4s" ;
    }
  }

  backButton(section_id){
    if (window.location.pathname.includes('/saved_items')) {
      return {pathLocation: window.location.pathname.split('/saved_items')[0]}
    }
    return backButtonObj(this.props.feeds, section_id, this.props.runningOnMobile)
  }

  //If section id have saved_items return to save section
  getSectionId = (sectionId) => {
    if (sectionId && sectionId.includes('/saved_items')) {
      this.props.history.replace(`/apps/${configuration.app_id}/${sectionId.split('/saved_items')[0]}`)
      return sectionId.split('/saved_items')[0];
    }
    return sectionId
  }

  renderBackButton(section_id) {
    let {pathLocation, style} = this.backButton(section_id);
    let parent_section_id = pathLocation;
    window.scrollTo(0, 0)
    const tv_style = {};
    if(isSmartTv()) {
      tv_style.display = "none";
    }

    if(this.props.runningOnMobile) {
      return (
        <span className="icon-hollow_arrow backBtnSpan backBtnSpan-mobile">
          <Link className="backBtn-mobile" style={{zIndex: 100}} to={"/apps/" + this.props.app_id + "/" + parent_section_id} onClick={() => this.navigateToRoute(parent_section_id)}>
            <img src={backMobile} alt="Back"/>
          </Link>
        </span>
      )
    }
    return (
      <span className="icon-hollow_arrow backBtnSpan" style = {{...style, ...tv_style}}>
          <Link id="main_back_id" className="backBtn" style={{ zIndex: 100 }} to={"/apps/" + this.props.app_id + "/" + parent_section_id} onClick={() => this.navigateToRoute(parent_section_id)} />
      </span>
    )
  }

  clickEnter = (e) => {
    // console.log('Click enter',e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();    
  }

  renderNavBar(section_id) {
    var extra_sections = ["search","settings"];
    var all_extra_sections = extra_sections + ["save", "subscribe"];
    const { feeds, app_id } = this.props;
    if(configuration.is_simulator) {
      const params = {
        slidesPerView: 'auto',
        mousewheelControl: true,
        keyboardControl: true,
        spaceBetween: 0,
        slidesPerGroup: 1
      }
      //similator
      if (!section_id || !isNaN(section_id) || all_extra_sections.includes(section_id)) {
        // if sectionid is null or is number or a string from all_extra section
        let navStyle = {backgroundColor: "#7a7a7a"};
        if(feeds.ottTabBarColor) {
          navStyle.backgroundColor = feeds.ottTabBarColor.replace("#FF", "#");
        }
        if (checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG])) {
          // navStyle.zIndex = 1;
        }
        return (
          <div id="nav-bar" className="slider-box nav-view" style={{...navStyle}}>
            <Swiper {...params}>
              {feeds && feeds.sections && feeds.sections.map(function(section, i) {
                // only when feeds.sections exist
                return (
                  <div key={i} className="swiper-slide">
                    <TvFocusable
                      clickEnter = { this.clickEnter }
                    >
                      <Link id = {`${section.title.toLowerCase().replace(" ","_")}`} to={"/apps/" + app_id + "/" + section.identifier} className={"headerLinks navbar-" + section.identifier} onClick={() => this.navigateToRoute(section.identifier)}>
                        {section.title}
                      </Link>
                    </TvFocusable>
                  </div>
                )
              }, this)}
              {extra_sections.map(function(section, i) {
                if(section === "search"){
                  return (
                    <div key={i} className="swiper-slide">
                      <TvFocusable
                        clickEnter = { this.clickEnter }
                      >
                        <Link to={"/apps/" + app_id + "/search"} className="headerLinks navbar-search" onClick={() => this.navigateToRoute("search")}>
                          Search
                        </Link>
                      </TvFocusable>
                    </div>
                  );
                } else if(section === "settings"){
                  return (
                    <div key={i} className="swiper-slide">
                      <TvFocusable
                        clickEnter = { this.clickEnter }
                      >
                        <Link to={"/apps/" + app_id + "/settings"} className="headerLinks navbar-settings" onClick={() => this.navigateToRoute("settings")}>
                          Settings
                        </Link>
                      </TvFocusable>
                    </div>
                  );
                }
              }, this)}
            </Swiper>
          </div>
        )
      } else {
        return this.renderBackButton(section_id);
      }
    } else {
      // for Web
      return (
        <>
          <WebNavBar 
            feeds={feeds}
            navigateToRoute={(route) => this.navigateToRoute(route)}
            app_id={app_id}
            currentView={this.state.currentView}
            runningOnMobile={this.props.runningOnMobile}
          />
          {section_id && !(!isNaN(section_id) || all_extra_sections.includes(section_id)) && this.renderBackButton(section_id)}
        </>
      )
    }
  }

  checkIfPymentConfirmationPage = () => {
    if (window.location.pathname.includes('/payment/confirmation'))
      return true;
    return false;
  }

  render(){
    const sectionClassName = checkIfSectionLevelContent() ? 'section-content': 'non-section-content';
    // Check if got redirected from error page on S3
    if (window.location.search.includes("?paths=")) {
      const redirectTo = window.location.search.replace("?paths=", "");
      return <Redirect to={redirectTo} />
    }

    if(this.props.feeds.sections){
      let section_id = window.location.pathname.replace("/apps/" + this.props.app_id, "");
      section_id = section_id.replace(/^\/+|"+$/g, '');

      if(isSmartTv() && !window.location.pathname.includes('apps') && !window.location.pathname.includes('payment') && !window.location.pathname.includes('saved_items')) {
        section_id = "";
      } else if(isSmartTv() && window.location.pathname.includes('.html')) {
        section_id = "";
      }

      if (section_id === "") {
        section_id = this.props.feeds.sections.filter(function(value) {return value.isDefault})
        if (section_id.length > 0 ){
          section_id=section_id[0].identifier
        }else{
          section_id = this.props.feeds.sections[0].identifier
        }

        return <Redirect to={"/apps/" + this.props.app_id+  "/" + section_id} />;
      }

      return (
        <div id="nav-with-modal-id" className={"with-modal " + ((section_id === "subscription" || this.checkIfPymentConfirmationPage()) ? "subscription-section " : " ") + sectionClassName}>
          {this.renderNavBar(section_id)}
          <Switch>
            <Route exact path={"/payment/confirmation"}>
              <PaymentMsg feeds={this.props.feeds}/>
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/search"}>
              <Search app_id={this.props.app_id} background={this.props.background} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/settings"}>
              <Settings background={this.props.background} feeds={this.props.feeds} logo={this.props.feeds.logo} app_id={this.props.app_id} runningOnMobile={this.props.runningOnMobile} parentCallback={(route) => this.navigateToRoute(route)} has_subs={(this.props.feeds.subscriptions!= undefined)}/>
            </Route>
            <Route exact path={"/apps/" + this.props.app_id}>
              <Section feeds={this.props.feeds} app_id={this.props.app_id} parentCallback={(route) => this.navigateToRoute(route)} runningOnMobile={this.props.runningOnMobile}/>
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/:section_id*"} render={(props) => <Section key={this.getSectionId(props.match.params.section_id)} feeds={this.props.feeds} section_id={this.getSectionId(props.match.params.section_id)} app_id={this.props.app_id} parentCallback={(route) => this.navigateToRoute(route)} runningOnMobile={this.props.runningOnMobile}/>} />
            <Redirect to="/" />
          </Switch>
        </div>
      )
    } else {
      return (
        <div id="nav-with-modal-id" className={"with-modal " + sectionClassName}>
          {this.renderNavBar(null)}
          <Switch>
            <Route exact path={"/payment/confirmation"}>
              <PaymentMsg feeds={this.props.feeds}/>
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/search"}>
              <Search app_id={this.props.app_id} background={this.props.background} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/settings"}>
              <Settings background={this.props.background} logo={this.props.feeds.logo} app_id={this.props.app_id} parentCallback={(route) => this.navigateToRoute(route)} runningOnMobile={this.props.runningOnMobile}/>
            </Route>
            <Route exact path={"/apps/" + this.props.app_id}>
              
              <MyQueue feeds={checkLoginUIMetaData(this.props.feeds)} app_id={this.props.app_id} background={this.props.feeds.savedUIMetadata.background} item_id={0} runningOnMobile={this.props.runningOnMobile}/>
            </Route>
            <Redirect to="/" />
          </Switch>
        </div>
      )
    }
  }
}

export default withRouter(NavBar);
