import cloneDeep from "lodash/cloneDeep";
import { GET_USER_SUBSCRIPTION_RESOLVE, GET_USER_SUBSCRIPTION_REJECT, GET_USER_SUBSCRIPTION_PENDING,
  USER_METERING_RESOLVE, USER_METERING_REJECT, USER_METERING_PENDING, USER_ENGAGED, USER_CONCENT,
  GET_WEBSITE_LOGIN_RESOLVED, GET_WEBSITE_LOGIN_REJCTED, GET_WEBSITE_LOGIN_PENDING, POLLING_START, POLLING_STOP,
  GET_FB_LOGIN_CODE_PENDING, GET_FB_LOGIN_CODE_RESOLVED, GET_FB_LOGIN_CODE_REJCTED, FB_POLLING_START, FB_POLLING_STOP, 
  GET_FB_USER_DATA_PENDING, GET_FB_USER_DATA_RESOLVED, GET_FB_USER_DATA_REJECTED, GET_USER_IP_PENDING, GET_USER_IP_RESOLVED, 
  GET_USER_IP_REJECTED, GET_UUID_PENDING, GET_UUID_RESOLVED, GET_UUID_REJECTED, GET_USER_LOCATION_PENDING, GET_USER_LOCATION_RESOLVED, GET_USER_LOCATION_REJECTED,
  GET_DEVICE_MODEL_PENDING, GET_DEVICE_MODEL_RESOLVED, GET_DEVICE_MODEL_REJECTED, LOGIN_MAZ_TV, LOGOUT_MAZ_TV
} from "../constants/actionTypes";
  import { getMeteringTypes } from '../constants/common';

export default function reducer(
    state={ user: {text: 1},
      app_id: 0,
      logged_in: false,
      error: false,
      subscription: false,
      sub_platform: null,
      metering_details: {},
      metering_views_seen:[],
      items_purchased:[],
      all_subscriptions: [],
      isLoading: false,
      userEngaged: false,
      userConcent: false,
      meteringLoading: false,
      fbLoginLoading: false,
      emailLoginLoading: false,
      getWebsiteLoginLoading: false,
      websiteLoginData: false,
      isPolling: false,
      isPollingError: false,
      fbCodeData: null,
      isFbPolling: false,
      fbUserData: null,
      isFbUserDataLoading: false,
      mazTVLogin: false
    }, action
  ) {

  switch (action.type) {
    case "RECEIVE_USER_PENDING": {
      return {
        ...state,
        user: {
          ...state.user,
          error: ""
        },
        error: false,
        fbLoginLoading: true
      }
    }
    case "RECEIVE_USER_EMAIL_PENDING": {
      return {
        ...state,
        user: {
          ...state.user,
          error: ""
        },
        error: false,
        emailLoginLoading: true
      }
    }
    case "RECEIVE_USER": {
      return {
        ...state, user: action.payload, app_id: action.app_id, logged_in: action.logged_in, error: action.error, fbLoginLoading: false, emailLoginLoading: false
      }
    }
    case "RECEIVE_USER_ERROR": {
      return {
          ...state, user: action.payload, app_id: action.app_id, logged_in: action.logged_in, error: action.error, fbLoginLoading: false, emailLoginLoading: false
      }
    }
    case "LOGOUT_USER": {
        return {
              ...state, user: {}, app_id: action.app_id, logged_in: action.logged_in, error: action.error,
              subscription: false,
              items_purchased: [],
              all_subscriptions: [],
              metering_details: {},
              userConcent: false,
              metering_views_seen:[],
        }
    }
    case "FORGOT_PASSWORD": {
      return {
          ...state, user: action.payload, app_id: action.app_id, logged_in: action.logged_in, error: action.error,
      }
    }
    case "COOKIE_USER": {
      return {
          ...state, user: action.payload, app_id: action.app_id, logged_in: action.logged_in, error: action.error,
      }
    }
    case "SUBSCRIPTION_PURCHASE": {
      return {
          ...state, subscription: true,
      }
    }
    case "ITEM_PURCHASE": {
      let items_purchased = cloneDeep(state.items_purchased)
      if (!items_purchased.includes(action.item_id)){
        items_purchased.push(action.item_id)
        return {
            ...state, items_purchased: items_purchased,
          }
        }
      return {
        ...state
      }
    }
    case "REMOVE_SUBSCRIPTION_PURCHASE": {
      return {
          ...state, subscription: false, metering_details: {}, metering_views_seen:[],
      }
    }
    case "METERING_CONSUMED": {
      const METERING_TYPE = getMeteringTypes();
      if (action.mtype == METERING_TYPE.TIME) {
        let metering_details = cloneDeep(state.metering_details)
        metering_details[action.section_id] = action.seconds

        return {
          ...state, metering_details: metering_details,
        }
      } else {
        let metering_views_seen = cloneDeep(state.metering_views_seen)
        if (!metering_views_seen.includes(action.item_id)){metering_views_seen.push(action.item_id)}
        return {
          ...state, metering_views_seen: metering_views_seen,
        }
      }
    }

    case GET_USER_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        isLoading: true
      }
    }
    case GET_USER_SUBSCRIPTION_RESOLVE: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    }
    case GET_USER_SUBSCRIPTION_REJECT: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    }
    case USER_METERING_PENDING: {
      return {
        ...state,
        meteringLoading: true
      }
    }
    case USER_METERING_REJECT: {
      return {
        ...state,
        ...action.payload,
        meteringLoading: false
      }
    }
    case USER_METERING_RESOLVE: {
      return {
        ...state,
        meteringLoading: false,
        ...action.payload
      }
    }
    case USER_ENGAGED: {
      return {
        ...state,
        userEngaged: true
      }
    }
    case USER_CONCENT: {
      return {
        ...state,
        userConcent: true
      }
    }
    case GET_WEBSITE_LOGIN_PENDING: {
      return {
        ...state,
        getWebsiteLoginLoading: true
      }
    }
    case GET_WEBSITE_LOGIN_RESOLVED: {
      return {
        ...state,
        getWebsiteLoginLoading: false,
        websiteLoginData: action.payload
      }
    }
    case GET_WEBSITE_LOGIN_REJCTED: {
      return {
        ...state,
        getWebsiteLoginLoading: false,
        websiteLoginData: action.payload
      }
    }
    case POLLING_START: {
      return {
        ...state,
        isPolling: true,
        isPollingError: false
      }
    }
    case POLLING_STOP: {
      return {
        ...state,
        ...action.payload,
        isPolling: false,
      }
    }
    case GET_FB_LOGIN_CODE_PENDING: {
      return {
        ...state,
        fbCodeData: false
      }
    }
    case GET_FB_LOGIN_CODE_RESOLVED: {
      return {
        ...state,
        fbCodeData: action.payload
      }
    }
    case GET_FB_LOGIN_CODE_REJCTED: {
      return {
        ...state,
        fbCodeData: action.payload
      }
    }
    case FB_POLLING_START: {
      return {
        ...state,
        isFbPolling: true,
      }
    }
    case FB_POLLING_STOP: {
      return {
        ...state,
        isFbPolling: false,
      }
    }
    case GET_FB_USER_DATA_PENDING: {
      return {
        ...state,
        isFbUserDataLoading: true,
        fbUserData: null
      }
    }
    case GET_FB_USER_DATA_RESOLVED: {
      return {
        ...state,
        isFbUserDataLoading: false,
        fbUserData: action.payload
      }
    }
    case GET_FB_USER_DATA_REJECTED: {
      return {
        ...state,
        isFbUserDataLoading: false,
        fbUserData: action.payload
      }
    }
    case GET_USER_IP_PENDING: {
      return {
        ...state,
        ip: null
      }
    }
    case GET_USER_IP_RESOLVED: {
      return {
        ...state,
        ip: action.payload
      }
    }
    case GET_USER_IP_REJECTED: {
      return {
        ...state,
        ip: action.payload
      }
    }
    case GET_UUID_PENDING: {
      return {
        ...state
      }
    }
    case GET_UUID_RESOLVED: {
      return {
        ...state,
        device_uuid: action.payload
      }
    }
    case GET_UUID_REJECTED: {
      return {
        ...state,
        device_uuid: action.payload
      }
    }
    case GET_USER_LOCATION_PENDING: {
      return {
        ...state
      }
    }
    case GET_USER_LOCATION_RESOLVED: {
      return {
        ...state,
        user_coords: action.payload
      }
    }
    case GET_USER_LOCATION_REJECTED: {
      return {
        ...state,
        user_coords: action.payload
      }
    }
    case GET_DEVICE_MODEL_PENDING: {
      return {
        ...state
      }
    }
    case GET_DEVICE_MODEL_RESOLVED: {
      return {
        ...state,
        device_model: action.payload
      }
    }
    case GET_DEVICE_MODEL_REJECTED: {
      return {
        ...state,
        device_model: action.payload
      }
    }
    case LOGIN_MAZ_TV: {
      return {
        ...state,
        mazTVLogin: true
      }
    }
    case LOGOUT_MAZ_TV: {
      return {
        ...state,
        mazTVLogin: false
      }
    }

  }

  return state
}
