import React from "react";
import { connect } from "react-redux";
import { isMobile } from 'mobile-device-detect';
import WebFont from 'webfontloader';

import { getFeeds } from "../actions/feedsActions";
import NavBar from "./NavBar";
import PoweredByScreen from "../components/PoweredByScreen";
import SponsorScreen from "../components/SponsorScreen";
import Modal from "../components/Modal";
import { setUserCookie, getUserSubscriptionDetails, getUserMeteringDetails, userEngaged, getUserIp, getUserDeviceID, getDeviceModel, loginThroughMazTv } from "../actions/userActions";
import { getAllRememberSpot } from "../actions/rememberSpotActions";
import { getSavedItems, getUSerFeedHistoryOrProgress } from "../actions/itemActions";
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { generateFontStyleTagAndFontNames,
  getPrimaryAndSecondaryFontsFromFeed, 
  checkFeedIntegrity, checkIfSectionLevelContent} from '../helperfunctions';
import configuration from '../constants/config'
import FeedError from "../components/FeedError";
import { isSmartTv } from '../helperfunctions/common';
import { checkCorrectPlatform } from "../helperfunctions/common";
import { PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_WEB } from "../constants/common";

const cookies = new Cookies();
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      launchScreen: true,
    }
    this.userClickEvent = this.userClickEvent.bind(this)
  }

  onSponsorTimeout(){
    this.setState({launchScreen: false})
  }

  componentDidMount() {
    document.body.addEventListener("click", this.userClickEvent, true);
    this.props.getUserIp();
    this.props.getUserDeviceID();
    this.props.getDeviceModel();
    const onMessageReceive = this.onMessageReceive;
    window.addEventListener("message", onMessageReceive);
  }

  onMessageReceive = (event) => {
    const _self = this;
    const data = event.data;
    if (data.request_from === "maz-tv" && data.action === "login") {
      const {user_id, auth_token, app_id} = data;
      if (user_id && auth_token && app_id && app_id == configuration.app_id) {
        _self.props.loginThroughMazTv(auth_token, user_id)
      }
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.userClickEvent, true);
    window.removeEventListener("message", this.onMessageReceive);
  }

  componentWillMount() {
    // Polling is happening inside getFeeds Action.
    this.props.getFeeds(configuration.app_id);
    let user_cookie;

    try {
      if(checkCorrectPlatform([PLATFORM_LG])) {
        user_cookie = localStorage.getItem('AppUser');
        if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        user_cookie = window.tizen.keymanager.getData({ 'name': 'AppUser' });
        if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
      } else {
        user_cookie = cookies.get('AppUser')
      }
    } catch(err) {
      user_cookie = null;
    }

    if(user_cookie){
      this.getUserSubscription(user_cookie.auth_token, user_cookie.user_id);
      this.getUserMetering(user_cookie.auth_token, user_cookie.user_id);
      this.getUserMazFeedHistoryAndProgress(user_cookie.auth_token, user_cookie.user_id);
      this.props.setUserCookie(user_cookie, configuration.app_id)
      this.props.getSavedItems(user_cookie.auth_token, "100", "0", user_cookie.user_id)
      if(!this.props.rem_fetched_after_login)
        this.props.getAllRememberSpot(user_cookie.auth_token, user_cookie.user_id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // When feeds load for the first time. Get primary and secondary font and update the state.
    if (prevProps.feeds.text === 1 && this.props.feeds && this.props.feeds.fonts && this.props.feeds.fonts.length) {
      const fonts = getPrimaryAndSecondaryFontsFromFeed(this.props.feeds);
      this.loadFonts(fonts);
    }
  }

  //Get user MAZ feed details. (history and progress)
  getUserMazFeedHistoryAndProgress = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId, 
      combo_app_id: configuration.app_id
    }
    this.props.getUSerFeedHistoryOrProgress({...params, filterType: 'history'});
    this.props.getUSerFeedHistoryOrProgress({...params, filterType: 'progress'});
  }

  //Get user subscription when page refreshes (first time get is in userAcess.js)
  getUserSubscription = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId, 
      combo_app_id: configuration.app_id
    }
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])))
      this.props.getUserSubscriptionDetails(params);
  }
  //Get user metering when page refreshes (first time get is in userAcess.js)
  getUserMetering = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId
    }
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])))
      this.props.getUserMeteringDetails(params);
  }
  /**
   * Adding Primary and Secondary Fonts (If present) that are coming from feed into HTML head and load it using webfont.
   */
  loadFonts = (fonts) => {
      const styleTag = generateFontStyleTagAndFontNames(fonts);
      document.getElementsByTagName('head')[0].appendChild(styleTag.style);
      WebFont.load({
        custom: {
          families: [...styleTag.fontNames]
        }
      })
  }

  userClickEvent() {
    this.props.userEngaged()
  }

  getParentClassNameUsingPlatform() {
    if (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) {
      return "simulator-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_WEB])) {
      if (isMobile) {
        return "web-route-container web-route-container-mobile"
      }
      return "web-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_LG]))
      return "simulator-route-container ctv-container lg-container";
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]))
      return "simulator-route-container ctv-container samsung-container";
  }

  render() {
    if(this.props.feeds.text != 1 && this.props.feeds.sponsor && this.state.launchScreen){
      return (
        <SponsorScreen sponsor={this.props.feeds.sponsor} onTimeout={() => this.onSponsorTimeout()} runningOnMobile={isMobile}/>
      );
    }
    else if (this.props.feeds.text != 1 && !checkFeedIntegrity(this.props.feeds)) {
      return (<FeedError />)
    }
    else if(this.props.feeds.text != 1) {
      return(
        <Router>
          <div className={this.getParentClassNameUsingPlatform()}>
              <Modal feeds={this.props.feeds} app_id={this.props.app_id} runningOnMobile={isMobile}/> 
              <NavBar feeds={this.props.feeds} app_id={this.props.app_id} background={this.props.background} runningOnMobile={isMobile}/>
          </div>
        </Router>
      )}
    else
      return (
        <PoweredByScreen runningOnMobile={isMobile}/>
      )
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds.feeds,
  app_id: state.feeds.app_id,
  background: state.feeds.background,
  rem_fetched_after_login: state.remember.fetched_after_login
})

const mapDispatchToProps = {
  getFeeds: getFeeds,
  setUserCookie: setUserCookie,
  getAllRememberSpot: getAllRememberSpot,
  getSavedItems: getSavedItems,
  userEngaged: userEngaged,
  getUserSubscriptionDetails,
  getUserMeteringDetails,
  getUSerFeedHistoryOrProgress,
  getUserIp,
  getUserDeviceID,
  getDeviceModel,
  loginThroughMazTv
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
