import configJson from "./configuration.json"
import { detectPlatform } from "../helperfunctions/common"
let config = configJson;
const configData = window.configData || {};
const platform = ['Samsung', 'LG']
if (!platform.includes(detectPlatform()) && configData && Object.keys(configData).length) {
  config = configData;
}
let configuration = {...config}
let app_id = document.getElementById("root").getAttribute("data-appId")
if(app_id){
  configuration.app_id = app_id
}
let env = document.getElementById("root").getAttribute("data-env")
if(env){
  configuration.env = env
}
let launch_screen = document.getElementById("root").getAttribute("data-launchscreen")

if (launch_screen){
  configuration.launch_screen = launch_screen
}

let is_simulator = document.getElementById("root").getAttribute("data-is_simulator")

// If it is simulator then setting platform and making other config as null
if (is_simulator === "true"){
  configuration.is_simulator = true;
  configuration.platform = 'html5';
  configuration.fb_app_id = null;
  configuration.fb_client_token = null;
}

if (configuration.env === "staging1") {
  configuration.env = "staging";
}

export default configuration
