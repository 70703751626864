import LZString from 'lz-string';
import axios from "axios";
import { getEnvironmentUrl } from '../constants/initializer'
import { updateMeteringDetailsForFeed } from "./userActions";
import { haveSavedMAZFeed } from "./itemActions"

import config from '../constants/config'
import { FEED_POLL_INTERVAL } from '../constants/common'
import { isSmartTv } from '../helperfunctions/common';

// console.log("HEllo",config.env)
var crypto = require('crypto'),
            algorithm = 'aes-256-cbc',
            key = (config.app_id + "-" + config.env.repeat(5)).substring(0,32),
            iv = "0000000000000000";


function decrypt(text) {
    var cipher = crypto.createDecipheriv(algorithm,key,iv)
    var crypted = cipher.update(text, "base64", "utf-8")
    crypted += cipher.final("utf-8");
    return crypted;
}

function checkSavedFeedInSection(section) {
  let isSavedFeed = false;
  if (!section)
    isSavedFeed = false;
  if (section.mazIDSectionType === 'saved')
    isSavedFeed = true;
  if (section.type === 'menu' && section.contents && section.contents.length) {
    for(let i=0; i<section.contents.length; i++) {
      isSavedFeed = checkSavedFeedInSection(section.contents[i])
      if (isSavedFeed) { return isSavedFeed }
    }
  }
  return isSavedFeed;
}

const checkIfSavedMazIdFeedSections = (sections) => {
  return new Promise((resolve, reject) => {
    for (let i=0; i<sections.length; i++) {
      if (checkSavedFeedInSection(sections[i])) {
        return resolve(true);
      }
    }
    return resolve(false);
  })
}

function storeFeedInLocalStorage(response_data) {
  const app_id = config.app_id;
  try {
    let compressed = LZString.compressToUTF16(JSON.stringify(response_data))
    if (!isSmartTv()) {
      localStorage.setItem(`${app_id}-MY_FEED`, compressed);
    }
  } catch(e) {
    console.log("error while comprssing")
  }
}

function oneFeedPolling(dispatch, firstResponse) {
  let updatedResponse = firstResponse;
  let intervalTime = FEED_POLL_INTERVAL.staging;
  if (config.env === 'production') {
    intervalTime = FEED_POLL_INTERVAL.production;
  }
  const app_id = config.app_id;
  setInterval(function() {
    axios.get(getEnvironmentUrl() + "/apps/get_wao_feed/" + app_id + `?simulator=${config.is_simulator}`)
    .then((response) => {
      let response_data = JSON.parse(decrypt(response.data));
      if(updatedResponse && Object.keys(updatedResponse).length > 1) {
        //Comparing old response with new one.
        if(updatedResponse.modified && response_data.modified &&
          new Date(response_data.modified) > new Date(updatedResponse.modified)
        ) {
          updateReduxState(dispatch, response_data, app_id);
          updatedResponse = response_data;
          storeFeedInLocalStorage(response_data);
        }
      }
    })
  }, intervalTime)
}

export function getFeeds(app_id) {
  return function(dispatch) {
    // fetch from localStorage
    let my_feed
    if (!isSmartTv()) {
      my_feed = localStorage.getItem(`${app_id}-MY_FEED`);
    }
    try {
      if(my_feed) {
        my_feed = JSON.parse(LZString.decompressFromUTF16(my_feed));
        if(my_feed && Object.keys(my_feed).length > 1) {
          updateReduxState(dispatch, my_feed, app_id);
        }
      }
    } catch(e) {
      my_feed = null;
    }

    // Fetch from s3
    axios.get(getEnvironmentUrl() + "/apps/get_wao_feed/" + app_id + `?simulator=${config.is_simulator}`)
     .then((response) => {
        let response_data = JSON.parse(decrypt(response.data));
        //Started polling here after getting response from 1st Api call.
        oneFeedPolling(dispatch, response_data);
        if(my_feed && Object.keys(my_feed).length > 1) {
          // in local storage
          if(my_feed.modified && response_data.modified &&
            new Date(response_data.modified) > new Date(my_feed.modified)
          ) {
            updateReduxState(dispatch, response_data, app_id);
          }
        } else {
          updateReduxState(dispatch, response_data, app_id);
        }
        storeFeedInLocalStorage(response_data);
      })
    }
}

function updateReduxState(dispatch, feed, app_id) {
  dispatch({type: "ADS_SETUP_DATA", payload: feed, app_id: app_id})
  dispatch({type: "RECEIVE_FEEDS", payload: feed, app_id: app_id})
  dispatch(updateMeteringDetailsForFeed());
  if (feed.sections && feed.sections.length) {
    checkIfSavedMazIdFeedSections(feed.sections)
    .then(data => data && dispatch(haveSavedMAZFeed()))
  } 
}
