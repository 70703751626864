import configuration from './config'
export function getEnvironmentUrl() {
    var env = environment()
    if (env === "production"){
        return "https://dashboard.mazsystems.com"
    } else if (env === "staging"){
        return "https://staging1dash.mazsystems.com"
    } else if (env === "staging2"){
        return "https://staging2.mazsystems.com"
    } else if (env === "staging3"){
        return "http://maz.maz-staging3.apps.speedyrails.net"
    } else if (env === "development") {
        return "http://localhost:3000"
    }
}

export function getMazCoreEnvUrl(){
    var env = environment()
    if (env === "production"){
        return "https://api.mazdigital.com"
    } else if (env === "staging"){
        return "https://corestaging1.mazdigital.com"
    } else if (env === "staging2"){
        return "https://staging2core.mazsystems.com"
    } else if (env === "staging3"){
        return "http://maz.maz-staging3.apps.speedyrails.net"
    } else if (env === "development") {
        return "http://localhost:5000"
    }
}

export function getApiToken(type){
    var env = environment()
    if (type === "search"){
      if (env === "production"){
        return "2e18e904-d9cd-4911-b30c-1817b1e0b04b"
      } else {
        return "4d43463a-fb2f-44e8-b14e-812875bfeefb"
      }
    }
}

export function environment(){
  return configuration.env
  if (process.env && process.env.REACT_APP_ENV){
    return process.env.REACT_APP_ENV
  }
  else if (document.getElementById("root") && document.getElementById("root").getAttribute("data-env")) {
    return document.getElementById("root").getAttribute("data-env")
  }
    return "production"
}

export const siteUrl = (app_id) =>
    getEnvironmentUrl() + "/apps/" + app_id
