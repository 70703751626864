export const previewContent = (props) => {
	const item = props.item
	return ({description: description_content(item), video_content: video_content(props), image_content: image_content(props), preview_feed: preview_feed(props)})
}


const description_content = (item) =>{
	return({title: item.title, subtitle: item.subtitle, summary: item.summary})
}

const video_content = (props) => {
	let video_item = props.item
	const previewType = props.previewType;
	//If fake, then put the first video for preview
	if (video_item.type === "fake") {
		video_item = video_item.contents[0];
	}

	if(previewType === "previewVideo"){
		return {
			id: ("previewVideo"+props.section.identifier), 
			cover_url: props.previewInfo && props.previewInfo.cover && props.previewInfo.cover.url, 
			contentUrl: props.previewInfo && props.previewInfo.contentUrl,
			muted: !props.section.previewAudio
		}
	}else if(previewType !== "image" && video_item.cover && video_item.type === "vid" && (!props.item.locked || !props.locked)){
		return {id: video_item.identifier, cover_url: video_item.cover.url, contentUrl: video_item.contentUrl, muted: props.muted }
	}else if(previewType !== "image" && video_item.type === "vid" && (!props.item.locked || !props.locked)){
		return {id: video_item.identifier, contentUrl: video_item.contentUrl, muted: props.muted }
	} else if (previewType !== "image" && video_item.type === 'live' && (!props.item.locked || !props.locked)) {
		return {id: video_item.identifier, contentUrl: video_item.contentUrl, muted: props.muted, cover_url: video_item.cover && video_item.cover.url }
	}else {
		return null
	}
}

const image_content = (props) => {
	let image_item = props.item

	if(image_item && image_item.cover){
		return {id: image_item.identifier, image_url: image_item.cover.url}
	}else if(image_item && image_item.contents && image_item.contents[0] && image_item.contents[0].cover){
		image_item = image_item.contents[0]
		const cover = image_item.cover
		let cover_url = ""
		if(cover){
			cover_url = cover.url
		}
		return {id: image_item.identifier, image_url: cover_url}
	}
}

const preview_feed = (props) => {
	if(props.previewType === "previewFeed" && props.previewInfo){
		return {id: ("previewVideo"+props.section.identifier), video_list:  video_list(props.previewInfo), muted: !props.section.previewAudio}
	}else{
		return null
	}

}

const video_list = (previewInfo) => {
	if(previewInfo.contents){
		return previewInfo.contents.map(video_data)
	}
	if(previewInfo.contentUrl){
		return [previewInfo].map(video_data)
	}
}

const video_data = (content) => {
	return ({	sources:[{
							src: content.contentUrl
						}]
					})
}
