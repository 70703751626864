import React from 'react'
import ThumbnailVideo from './ThumbnailVideo'
import ThumbnailImage from './ThumbnailImage'

class Thumbnail extends React.Component {
  render(){
    if(this.props.content.type === "vid"){
      return(
        <ThumbnailVideo feeds={this.props.feeds} locked={this.props.locked} show_ad={this.props.show_ad} primary={this.props.primary} secondary={this.props.secondary} content={this.props.content} head={this.props.head} detail={this.props.detail} all_contents={this.props.all_contents} layout = {this.props.layout} feeds = {this.props.feeds}/>
      )
    } else if(this.props.content.type === "image"){
      return(
        <ThumbnailImage feeds={this.props.feeds} locked={this.props.locked} primary={this.props.primary} secondary={this.props.secondary} content={this.props.content} head={this.props.head} detail={this.props.detail} all_contents={this.props.all_contents} layout = {this.props.layout} />
      )
    } else {
      return(null);
    }
  }
}

export default Thumbnail;
