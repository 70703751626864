import React from 'react';
import Swiper from 'react-id-swiper';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { GRID_THEME } from '../constants/common';
import { getFontStyleFromFeed, getSwiperParamsByTheme } from "../helperfunctions";
import { appendVirtualSwiperParams, checkIfEmptyMazIdFeedsInContents, focusVideoItemWhichWerePlayed, isSmartTv, setLeftStyleToSlideItems } from '../helperfunctions/common';

class GridContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      },
    }
    this.swiper = null;
    this.swiperParams = null;
    this.generateSwiperParam();
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // if (this.swiper) {this.swiper.slideTo(this.props.modal_progress.index)};
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item);
    }
    !isSmartTv() && setLeftStyleToSlideItems(this.props.content.identifier, this.state.virtualData.offset);
  }

  onRenderExternal = (virtualData) => {
    this.setState({
      virtualData
    })
  }

  generateSwiperParam = () => {
    const _self = this;
    let params = getSwiperParamsByTheme(_self, this.props.content, GRID_THEME);
    if (!isSmartTv()) {
      this.swiperParams = appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal)
    } else {
      this.swiperParams = params;
    }
  }

  render() {
    const _self = this;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    // let params = getSwiperParamsByTheme(_self, this.props.content, GRID_THEME);
    // _self.generateSwiperParam()
    if (this.props.content && this.props.content.thumbOrientation === "portrait") {
      if(this.props.nosaveicon){
        var gridClassName = "nothumbBox slider-box grid-view portrait-view content-" + this.props.content.identifier
      } else {
        var gridClassName = "slider-box grid-view portrait-view content-" + this.props.content.identifier
      }
    } else {
      if(this.props.nosaveicon){
        var gridClassName = "nothumbBox slider-box grid-view content-" + this.props.content.identifier
      } else {
        var gridClassName = "slider-box grid-view content-" + this.props.content.identifier
      }
    }

   
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    return (
      <div id="standard-grid" className={gridClassName}>
        {(this.props.title !== "saved_items" && !this.props.content.locked) && <h2 style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>}
        {this.props.content.locked && <Cta layout={"standard"}time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        <Swiper {...this.swiperParams}>
          {
            isSmartTv()
            ? (this.props.contents) 
            : (this.state.virtualData.slides)
          }
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(GridContent);
