import React, {Component} from "react";
import { getImageUrlBaseOnSize } from '../helperfunctions/common';

class PreviewImage extends Component {

  render(){
    if(!this.props.image_content){
      return null
    }
    const cover_url = this.props.image_content.image_url
    return (
      <div id = "previewImage" >
        <img id = "image-preview" src = {cover_url && getImageUrlBaseOnSize(cover_url, 900)}></img>
          <div id = "previewShadow"
          >
          </div>
      </div>
    );
  }
}

export default PreviewImage; 