import axios from "axios";
import * as actionTypes from '../constants/actionTypes';
import { getEnvironmentUrl, getApiToken } from '../constants/initializer'

export function getSearchResult(app_id, query){
    var url = getEnvironmentUrl() + '/api/v3/search/'+ app_id +'?q=' + query + '&api_token='+ getApiToken("search") +'&tv=true'
    return function(dispatch) {
        dispatch({type: actionTypes.TEXT_SEARCH, payload: {string: query}})
        axios.get(url)
            .then((response) => {
                dispatch({type: actionTypes.RECEIVE_SEARCH_ITEMS, payload: response.data, app_id: app_id})
            })
    }
}

export function clearSearch() {
    return function(dispatch) {
        dispatch({type: actionTypes.CLEAR_SEARCH})
    }
}
