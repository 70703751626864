import React from 'react'
import {Link} from 'react-router-dom'
import { connect } from "react-redux"

import Thumbnail from './Thumbnail'
import { deepLinkUrl, httpsUrl, VIDEO_GRID_THEME_THEME } from '../constants/common'
import StandardTitle from "./StandardTitle";
import { openModal, setPreviewVideo } from "../actions/modalActions";
import Preview from '../components/Preview';
import { checkIfSectionLevelContent, getFontStyleFromFeed } from "../helperfunctions";
import { checkIfToDoNothing, getTabIndex, isSmartTv } from '../helperfunctions/common'
import { Focusable, JsSpatialNavigation } from 'react-js-spatial-navigation';
import TvFocusable from '../components/TvFocusable';
import GridContent from '../components/GridContent';
import JumboContent from '../components/JumboContent';
import DetailContent from '../components/DetailContent';
import SpotLightContent from '../components/SpotLightContent';
import VideoGridContent from './VideoGridContent';
import { clickParentDivWhenFocusedSpotlightContent, clickParentIfFullscreen } from '../helperfunctions/ctvHelper'
import { isSmartTV } from 'mobile-device-detect'
import { elementScrollIntoView } from "seamless-scroll-polyfill";

function ChangeRoute(section, head){
  if(head === undefined || (window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === head.identifier)){
    var currentLocation = window.location.pathname + "/" + section.identifier
  } else {
    var currentLocation = window.location.pathname + "/" + head.identifier + "/" + section.identifier
  }
  return currentLocation;
}

function GetLayout(layout){
  if(layout == "standard"){
    return "grid"
  } else {
    return layout
  }
}

function checkSingleMenu(contents){
  var singleMenu = true
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" || contents[i].type === "live"){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

function checkMediaPresent(contents){
  var mediaPresent = false
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "vid" || contents[i].type === "image"){
      mediaPresent = true;
      break;
    }
  }
  return mediaPresent;
}

class Content extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      hover: false,
      sourceClass: ''
    }
  }

  onMouseEventHandler(hover, sourceClass, content=null) {
    if(hover){
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      });

      this.props.setPreviewVideo(content)
      if (isSmartTv()) {
        clickParentDivWhenFocusedSpotlightContent();
        clickParentIfFullscreen();
      }
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      })
    }
  }

  onLinkCLick(route, overlay, actionUrl, e){
    if (actionUrl && checkIfToDoNothing(actionUrl, this.props.feeds)) {
      e && e.preventDefault();
      return null;
    }
    if (actionUrl && actionUrl.includes("/registerwall/")) {
      e && e.preventDefault();
      this.props.openModal({type: "login", screen: "registerWall"})
    } else if(actionUrl && actionUrl.includes("/register/")) {
      e && e.preventDefault();
      this.props.openModal({type: "login", screen: "firstSave"})
    } else {
      if(overlay){
        this.props.openModal({type: "error", message: "You must unlock this content in order to access it."})
      }
      this.setState({ currentView: route })
      this.props.callbackParent(route);
    }
  }

  componentDidMount(){
    document.body.classList.remove("noNav")
    // if(!isSmartTv()) return;

    // if(this.props.layout === 'detail' && document.querySelectorAll(".swiper-wrapper").length) {
    //   let start = document.querySelectorAll(".swiper-wrapper").length == 1 ? 0 : 1;
    //   if(!document.querySelectorAll(".swiper-wrapper")[start]) return;

    //   document.querySelectorAll(".swiper-wrapper")[start].querySelectorAll('.focusable').forEach(el => {
    //     if(!el) return;

    //     el.addEventListener('sn:willfocus',function() {
    //       JsSpatialNavigation.pause();
    //       JsSpatialNavigation.focus(this);
    //       // this.parentElement.scrollIntoView({behavior: 'smooth',block: 'start'});
    //       elementScrollIntoView(this.parentElement, {behavior: 'smooth',block: 'center'});
    //       JsSpatialNavigation.resume();
    //     });
    //   });
    // } else {
    //   document.querySelectorAll(".swiper-wrapper").forEach(el => {
    //     if(!el) return;
    //     let tot_els = el.querySelectorAll('.focusable');
    //     if(tot_els[tot_els.length - 1].tagName === 'IMG') {
    //       tot_els[tot_els.length - 2].setAttribute('data-sn-right','');
    //     } else {
    //       tot_els[tot_els.length - 1].setAttribute('data-sn-right','');
    //     }
    //     if(tot_els[0]) tot_els[0].setAttribute('data-sn-left','')

    //     tot_els.forEach(el => {
    //       el.addEventListener('sn:willfocus',function(data) {
    //         let block = 'center';
    //         // if(data.detail.direction === 'down') block = 'start';
    //         // else if(data.detail.direction === 'up') block = 'end';  
    //         JsSpatialNavigation.pause();
    //         JsSpatialNavigation.focus(this);
    //         // this.scrollIntoView({behavior: 'smooth', block, inline: 'center'});
    //         elementScrollIntoView(this, {behavior: 'smooth', block, inline: 'center'});
    //         JsSpatialNavigation.resume();
    //       });
    //     })
    //   });
    // }
  }

  clickEnter = (e) => {
    // console.log('Click enter',e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();    
  }

  render() {
    const contents = []
    var primary = this.props.feeds.primaryColor || "#FFFFFF"
    var secondary = this.props.feeds.secondaryColor || "#FFFFFF"
    var nosaveicon = false;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);

    if(this.props.content.mazContentType === "saved"  && this.props.layout === VIDEO_GRID_THEME_THEME) {
      //No saved section from MAZ ID feed for mobile
      return null;
    }

    if(this.props.content.contents && checkSingleMenu(this.props.content.contents)) {
      this.props.content.contents.map(function (content, i) {
        content["locked"] = this.props.content.locked
        content["access"]=this.props.content.access
        content["special"]=this.props.content.special
        content["displayTitle"]=this.props.content.displayTitle
        content["registerWall"]=this.props.content.registerWall
        content["parent_id"]=this.props.content.identifier
        content["inlineMenu"]=this.props.content.inlineMenu || ""
        if (content.contentUrl && (content.type === "image" || content.type === "vid")) {
          contents.push(
            <div key={content.identifier} className={"detail-swiper item-" + content.identifier}>
              <Thumbnail locked={this.props.content.locked} show_ad={this.props.content.showVideoAd} access={this.props.content.access}
                         all_contents={this.props.content.contents} primary={primary}
                         secondary={secondary} content={content}
                         head={this.props.content.identifier} detail={this.props.layout === "detail"} layout = {this.props.layout} feeds={this.props.feeds}/>
              <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={this.props.content.locked} access={this.props.content.access}
                             content={content} type={"content"} secondary={secondary}
                             feeds={this.props.feeds}
                             layout = {this.props.layout}
                            />
            </div>
          )
        }
      }, this)
    } else if(this.props.content.contents === undefined && (this.props.content.type === "vid" || this.props.content.type === "image" || this.props.content.type === "menu")){
      let color, imgBackColor;
      if(this.state.hover && (this.state.sourceClass === "image-video-" + this.props.content.identifier)) {
        //If thumbnail image is present then on hover, background must be transparent (BUG - https://github.com/MagAppZine/MagAppZine-MagControl/issues/7190#issuecomment-699616347)
        color = this.props.content.cover ? 'transparent' : secondary.replace('#FF','#');
        imgBackColor = secondary.replace('#FF','#');
      } else {
        color = this.props.content.cover ? 'transparent' : primary.replace('#FF','#')
        imgBackColor = primary.replace('#FF','#');
      }
      const divBackgroundStyle = {backgroundColor: color};
      const imgBackStyle = {backgroundColor: imgBackColor}
      if(this.props.content.type === "menu") {
        contents.push(
          <div key={this.props.content.identifier} className="detail-swiper">
            {/* <TvFocusable 
              onFocus = {() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content )}
              onUnfocus = {() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}
              clickEnter = {this.clickEnter}
            > */}
              <Link to={ChangeRoute(this.props.content, undefined)}
                onClick={(route, overlay) => this.onLinkCLick(this.props.content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                onFocus={() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content )}
                onBlur={() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}
              >
                <div style={divBackgroundStyle} className="img-video"
                    data-title={this.props.content.title} data-subtitle={this.props.content.subtitle} data-summary={this.props.content.summary}
                    onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content )}
                    onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}>
                  {this.props.content.cover && <img style={imgBackStyle} className="img-thumb" src={(this.props.content.cover && httpsUrl(this.props.content.cover.url))}/>}
                </div>
              </Link>
            {/* </TvFocusable> */}
            {this.props.layout !== "spotlight"  &&<div className="titleHolder">
              <p style={fontStyle.primaryFont}>{this.props.content.title}</p>
            </div>}
          </div>
        )
      } else {
        contents.push(
          <div key={this.props.content.identifier} className="detail-swiper">
            {/* <TvFocusable 
              onFocus = {() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content)}
              onUnfocus = {() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}
              clickEnter = {this.clickEnter}
            > */}
              <Link to={ChangeRoute(this.props.content, undefined)}
                onClick={(route, overlay) => this.onLinkCLick(this.props.content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                onFocus = {() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content)}
                onBlur = {() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}  
              >
                <div style={divBackgroundStyle} className="img-video"
                    data-title={this.props.content.title} data-subtitle={this.props.content.subtitle} data-summary={this.props.content.summary}
                    onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content)}
                    onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}>
                  {this.props.content.cover && <img style={imgBackStyle} className="img-thumb" src={(this.props.content.cover && httpsUrl(this.props.content.cover.url))}/>}
                </div>
              </Link>
            {/* </TvFocusable> */}
            <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={this.props.content.locked} access={this.props.content.access}
                           content={this.props.content} type={"content"} secondary={secondary}
                           feeds={this.props.feeds}
                           layout = {this.props.layout}
                          />
          </div>
        )
      }
    } else if(this.props.content.contents !== undefined) {
      var mediaPresent = checkMediaPresent(this.props.content.contents)
      this.props.content.contents.map((content, i) => {
        const divSecodaryBack = {backgroundColor: content.cover ? 'transparent' :secondary.replace('#FF', '#')}
        const divPrimaryBack = {backgroundColor: content.cover ? 'transparent' :primary.replace('#FF', '#')}
        const imgPrimaryBack = {backgroundColor: primary.replace('#FF', '#')}
        const imgSecodaryBack = {backgroundColor: secondary.replace('#FF', '#')}
        if(content.mazIDSectionType === "saved" && this.props.layout === VIDEO_GRID_THEME_THEME) {
          //No saved section from MAZ ID feed for mobile
        } else if(content.deeplinkUrl) {
          var deeplink = deepLinkUrl(content.deeplinkUrl, this.props.app_id, this.props.feeds)
          if (this.state.hover && (this.state.sourceClass === "image-video-" + content.identifier)) {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                {/* <TvFocusable 
                  onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onUnfocus = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                  clickEnter = {this.clickEnter}
                > */}
                  <Link to={deeplink[0]}
                    onClick={(e) => this.onLinkCLick(content.identifier, deeplink[1], content.deeplinkUrl, e)}
                    className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                    onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onBlur = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                  >
                          {/*If thumbnail image is present then on hover, background must be transparent (BUG - https://github.com/MagAppZine/MagAppZine-MagControl/issues/7190#issuecomment-699616347) */}
                    <div style={divSecodaryBack} 
                          className="img-video"
                        data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                        onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                        onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                      {content.cover && content.cover.url && <img style={imgSecodaryBack} className="img-thumb" src={content.cover.url}/>}
                    </div>
                  </Link>
                {/* </TvFocusable> */}
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if (!mediaPresent) {
              nosaveicon = true
            }
          } else {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                {/* <TvFocusable 
                  clickEnter = { this.clickEnter }
                  onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onUnfocus = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                > */}
                  <Link to={deeplink[0]}
                    onClick={(route, overlay) => this.onLinkCLick(content.identifier, deeplink[1])}
                    className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                    onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onBlur = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}  
                  >
                    <div style={divPrimaryBack} className="img-video"
                        data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                        onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                        onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                      {content.cover && content.cover.url && <img style={imgPrimaryBack} className="img-thumb" src={content.cover.url}/>}
                    </div>
                  </Link>
                {/* </TvFocusable>                 */}
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if (!mediaPresent) {
              nosaveicon = true
            }
          }
        } else if(content.type === "vid" || content.type === "image"){
          contents.push(
            <div key={content.identifier} className={"detail-swiper item-" + content.identifier}>
              <Thumbnail feeds={this.props.feeds} locked={content.locked} show_ad={content.showVideoAd} access={content.access}
                        all_contents={[content]} primary={primary}
                        secondary={secondary} content={content}
                        head={content.identifier} detail={this.props.layout === "detail"} layout = {this.props.layout} feeds={this.props.feeds}/>
              <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={content.locked} access={content.access}
                            content={content} type={"content"} secondary={secondary}
                            feeds={this.props.feeds}
                            layout = {this.props.layout}
                            items={this.props.items}/>
            </div>
          )
        } else {
          if(this.state.hover && (this.state.sourceClass === "image-video-" + content.identifier)){
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                {/* <TvFocusable 
                  clickEnter = { this.clickEnter }
                  onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onUnfocus={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}                
                > */}
                  <Link to={ChangeRoute(content, this.props.content)}
                      onClick={(route, overlay) => this.onLinkCLick(content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                      onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                      onBlur={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                  >
                    <div style={divSecodaryBack} className="img-video"
                      data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                      onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                      onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                      {content.cover && content.cover.url && <img style={imgSecodaryBack} className="img-thumb" src={content.cover.url}/>}
                    </div>
                  </Link>
                {/* </TvFocusable> */}
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if(!mediaPresent){
              nosaveicon = true
            }
          } else {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                {/* <TvFocusable 
                  clickEnter = { this.clickEnter }
                  onFocus={() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onUnfocus={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}                
                > */}
                  <Link to={ChangeRoute(content, this.props.content)}
                    onClick={(route, overlay) => this.onLinkCLick(content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                    onFocus={() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onBlur={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}                                          
                  >
                    <div style={divPrimaryBack} className="img-video"
                      data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                      onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                      onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                      {content.cover && content.cover.url && <img style={imgPrimaryBack} className="img-thumb" src={content.cover.url}/>}
                    </div>
                  </Link>
                {/* </TvFocusable> */}
                {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                  <p style={fontStyle.primaryFont}>{content.title}</p>
                </div>}
              </div>
            )
            if(!mediaPresent){
              nosaveicon = true
            }
          }
        }
      }, this)
    }

    if(this.props.layout === "detail"){
      return(
        <DetailContent secondary={secondary} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} detail_content={this.props.content.contents} />
      )
    } else if(this.props.layout === "jumbo"){
      return(
        <JumboContent content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} />
      )
    } else if(this.props.layout === "spotlight" || this.props.layout === "fullscreen"){
      return(
        <SpotLightContent index = {this.props.index} section = {this.props.section} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} />
      )
    } else if(this.props.layout === VIDEO_GRID_THEME_THEME){
      return(
        <VideoGridContent nosaveicon={nosaveicon} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} />
      )
    }
    else{
      return(
        <GridContent nosaveicon={nosaveicon} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} />
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: openModal,
  setPreviewVideo, setPreviewVideo
}

export default connect(null, mapDispatchToProps)(Content);
