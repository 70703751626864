import React from 'react'
import Swiper from 'react-id-swiper'
import { connect } from "react-redux"
import Cta from "../components/Cta"
import { setLockedContent } from "../actions/modalActions"
import {getSwiperParamsByTheme, swiperParam} from '../helperfunctions';
import isAvailable from '../helperfunctions/is_available';
import { getFontStyleFromFeed } from "../helperfunctions";
import { SPOTLIGHT_THEME } from '../constants/common'
import { appendVirtualSwiperParams, checkIfEmptyMazIdFeedsInContents, focusVideoItemWhichWerePlayed, isSmartTv, setLeftStyleToSlideItems } from '../helperfunctions/common';

class SpotlightContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      }
    }
    this.swiper = null
    this.swiperParams = null;
    this.generateSwiperParam();
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // if (this.swiper) {this.swiper.slideTo(this.props.modal_progress.index)};
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item);
    }
    !isSmartTv() && setLeftStyleToSlideItems(this.props.content.identifier, this.state.virtualData.offset);
  }

  onRenderExternal = (virtualData) => {
    this.setState({
      virtualData
    })
  }

  generateSwiperParam = () => {
    const {params, gridClassName} = getSwiperParamsByTheme(this, this.props, SPOTLIGHT_THEME)
    if (!isSmartTv()) {
      this.swiperParams = appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal)
    } else {
      this.swiperParams = params;
    }
  }

  onDivClick = (e) => {
    e.preventDefault();
    if (isSmartTv()) {
      this.props.dispatch(setLockedContent(this.props.content.locked && !isAvailable(this.props.content.identifier,this.props.content.special,this.props.content.registerWall)))
    }
  }

  render() {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    const {params, gridClassName} = getSwiperParamsByTheme(this, this.props, SPOTLIGHT_THEME)
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    return (
      <div className={gridClassName}
      onMouseEnter = {() => {this.props.dispatch(setLockedContent(this.props.content.locked && !isAvailable(this.props.content.identifier,this.props.content.special,this.props.content.registerWall)))}}
      onClick={this.onDivClick}
      >
        {(this.props.title !== "saved_items" && !this.props.content.locked) && <h2 style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>}
        {this.props.content.locked && <Cta layout={"spotlight"} time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        <Swiper {...this.swiperParams}>
          {
            isSmartTv()
            ? (this.props.contents) 
            : (this.state.virtualData.slides)
          }  
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
  subscription: state.user.subscription,
})

export default connect(mapStateToProps, null)(SpotlightContent);
