import axios from "axios";
import { getMazCoreEnvUrl } from '../constants/initializer';

export const GET_USER_SUBSCRIPTION_API = `${getMazCoreEnvUrl()}/payment_connect/get_purchases`;
export const CREATE_PURCHASE_SESSION_ID_API = `${getMazCoreEnvUrl()}/payment_connect/create_session`;
export const CANCEL_SUBSCRIPTION_API = `${getMazCoreEnvUrl()}/payment_connect/cancel_subscription`;
export const RENEW_SUBSCRIPTION_API = `${getMazCoreEnvUrl()}/payment_connect/update_subscription`;

export const USER_INFO_API = `${getMazCoreEnvUrl()}/user_sync/api/v1/app_users/`;

export const USER_SIGN_API = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/sign_in`;
export const USER_SIGNUP_API = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/`;
export const USER_FORGOT_PASSWORD_API = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/password`;
export const FB_LOGIN_SIGNUP = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users`
export const WEBSITE_LOGIN = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/create_token`;

export const FB_GET_CODE = `https://graph.facebook.com/v8.0/device/login`;
export const FB_POLLING_URL = `https://graph.facebook.com/v8.0/device/login_status`;
export const FB_GET_USER_DATA = `https://graph.facebook.com/v8.0/me`;

export const USER_IP = 'https://ipv4.jsonip.com/' // third party service
export const USER_GEO = ''; // get user location