/**
 * This function will check if focus in one of the fullscreen or spotlight theme. If yes then we are clicking parent div, which are calling the action to 'setLockedContent' in spotlightContent.js file.
 */
export function clickParentDivWhenFocusedSpotlightContent() {
    const spotFullSlider = document.querySelector('#fullscreen-grid,#spotlight-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".spotlight.slider-box");
        nearestContainer && nearestContainer.click();      
    }
}

/**
 * Function will check if focus in fullscreen section. If yes then click the parent div, which call the action to mute, unmute video for background videos.
 */
export function clickParentIfFullscreen() {
    const spotFullSlider = document.querySelector('#fullscreen-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".fullscreen-parent-row");
        nearestContainer && nearestContainer.click();      
    }
}

export function checkIfFocusInsideBanner() {
    const bannerParent = document.querySelector('.banner');
    if (bannerParent && bannerParent.contains(document.activeElement))
        return true;
    return false
}