import configuration from '../constants/config';
import { isSmartTv } from './common';
export function getParentFeed(feeds, sectionUrl) {
    let sections = feeds.sections
    let content;
    let pathLocation = sectionUrl.split("/")
    for(let i=0; i<pathLocation.length - 1; i++){
      for(let j=0; j<sections.length; j++){
        if(sections[j].identifier === pathLocation[i]){
          content = sections[j]
          if(sections[j].contents) {
            sections = sections[j].contents;
          }
        }
      }
    }
    return content
}
/**
 * This function return an object which tell when you press back, where the user should go.
 */
export function backButtonObj(feeds, section_id, runningOnMobile){
  let sections = feeds.sections
  let content;
  let pathLocation = section_id.split("/");
  let temp = 1;
  let parentIndex = 1;
  const appId = configuration.app_id;
  if(isNaN(pathLocation[0])){return {pathLocation: "settings", backPath: `/apps/${appId}/settings`, style: {}}}
  for(let i=0; i<pathLocation.length - 1; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
        if (temp === 1)  {
          if (pathLocation.length-1 != i)
            parentIndex = pathLocation.length - i - 1;
          if (content.regularLayout === "detail" && !runningOnMobile) {
            temp = 2;
          } else {
            temp = 3;
          }
        }
        temp--;
      }
    }
  }

  pathLocation = pathLocation.slice(0,-parentIndex).join("/")
  if (!content) {
    return {pathLocation: `apps/${appId}`, backPath: `/apps/${appId}`, style: {}}
  }
  if(content.regularLayout === "spotlight"){
    return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {position: "fixed"}}
  }
  return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {}}
}

export function checkIfSectionLevelContent() {
  const urlArr = window.location.pathname.split('/').slice(3);
  if (urlArr.length === 1)
    return true;
  return false;
}


/**
 * Function to check whether proper sections is present, and proper contents in all section. (true for proper feed)
 * @param {*} feed One feed
 */
export function checkFeedIntegrity(feed) {
  const sections = feed.sections;
  if (!sections || !sections.length)
    return false;
  return true;
}


// The function return a pathname for an item
export function getThumbnailType(content, head){
  var contentUrl = window.location.pathname.split("/")
  var lastElement = contentUrl[contentUrl.length - 1]
  if (isSmartTv() && window.location.pathname.includes(content.identifier)) {
    contentUrl = window.location.pathname;
  } else {
    if(head === lastElement || head === content.identifier){
      contentUrl = window.location.pathname + "/" + content.identifier
    } else {
      contentUrl = window.location.pathname + "/" + head + "/" + content.identifier
    }
  }
  return contentUrl
}

export function findDefaultSection(oneFeed) {
  window.feeds = oneFeed
  var feed = 0;
  var contents = oneFeed.sections;
  var defaultSection = contents[0];
  var defaultSectionArray = [defaultSection]
  var pathLocation = [defaultSection.identifier]
  var defaultChanged = 0
  while(feed == 0){
    for(var i=0; i < contents.length; i++){
      if(contents[i].isDefault){
        if(pathLocation.length == 1 && defaultChanged == 0) {
          pathLocation = pathLocation.slice(0,-1)
          defaultSectionArray = defaultSectionArray.slice(0,-1)
          defaultChanged = 1
        }
        defaultSection = contents[i]
        defaultSectionArray.push(defaultSection)
        pathLocation.push(defaultSection.identifier)
        feed = 0
        if(defaultSection.type !== "live" && defaultSection.type !== "cvideo" && defaultSection.type !== "image" && defaultSection.contents){
          contents = defaultSection.contents
        } else {
          feed = 1
          break
        }
        break;
      } else {
        feed = 1
      }
    }
  }
  if(pathLocation.length > 1 && defaultSectionArray[defaultSectionArray.length - 2].regularLayout !== "detail"){
    pathLocation = pathLocation.slice(0,-1)
    return [defaultSectionArray[defaultSectionArray.length - 2], pathLocation]
  } else {
    return [defaultSection, pathLocation];
  }
}

export function getGdprParamsForApi(feeds) {
  const gdprObj = {
    gdpr: {}
  }
  if (!feeds || !feeds.gdpr || !feeds.gdpr.privacy)
    return {};
  if (feeds.gdpr.privacy.partner) {
    gdprObj.gdpr.partner = true;
  }
  if (feeds.gdpr.privacy.third_party) {
    gdprObj.gdpr.third_party = true;
  }
  return gdprObj;
}

export function checkIfSectionIdExist(section_id, feeds) {
  if (section_id.includes('subscription')) {
    return {
      isSectionId: true,
    }
  }
  let sections = feeds.sections
  let content={};
  let pathLocation = section_id.split("/");
  let availablePath = [];
  const appId = configuration.app_id;
  if(isNaN(pathLocation[0])){
    return {
      isSectionId: false,
      availablePath: ""
    }
  }
  for(let i=0; i<pathLocation.length; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        availablePath.push(sections[j].identifier);
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
      }
    }
    if (pathLocation[i] !== content.identifier) {
      return {
        isSectionId: false,
        availablePath: availablePath.length ? availablePath[0] : ""
      }
    }
  }
  return {
    isSectionId: true,
  };
} 