import React, {Component} from "react";
import { getImageUrlBaseOnSize } from '../helperfunctions/common';
import { connect } from 'react-redux'

var videojs = require('video.js');

class PreviewVideo extends Component {

  componentDidMount() {
    this.playPreview();
  }

  playPreview(){
    if (this.props.video_content && this.props.video_content.contentUrl) {
      const videoParams = {
        preload: 'auto',
        muted: this.props.video_content.muted
      }
      this.player = videojs("spotlight-preview",{errorDisplay: false, loadingSpinner: false, ...videoParams});
      this.player.src(this.props.video_content.contentUrl);
      /**
       * Playing video when it is ready, If preview not play then mute the video and try again.
       */
      videojs("spotlight-preview") && this.player.ready(() => {
        if (!this.props.video_content.locked) {
          const promise = this.player.play();
          promise && promise
          .catch((err) => {
            try {
              if (this.player && this.player.muted && this.player.play && videojs("spotlight-preview")) {
                this.player.muted(true);
                this.player.play()
              }
            }
            catch(err) {
            }
          })
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      try {
        this.player.dispose()
      }
      catch(err) {
      }
    }
  }

  render(){
    if (!this.props.video_content){
      return null
    };

    const {cover_url, contentUrl, muted} = this.props.video_content
    return (
      <div id = "previewVideo">
        <video
          id="spotlight-preview"
          className="object-cover h-full w-full"
          poster = {cover_url && getImageUrlBaseOnSize(cover_url, 900)}
        >
          <source src={contentUrl}/>
        </video>
        <div
          id="previewShadow"
        >
        </div>
      </div>
    );
  }
}

export default PreviewVideo; 